import axios from "axios";
import { FAM_API, HEADERS } from "../constants";

export const getTravelTime = async ({ payload }) => {
  try {
    const { destination, origin } = payload;

    const query = new URLSearchParams({
      destination: JSON.stringify({
        lat: destination.lat,
        lng: destination.lng
      }),
      origin: JSON.stringify({
        lat: origin.lat,
        lng: origin.lng
      }),
    }).toString();

    const res = await axios.get(`${FAM_API}/stadium-travel-time?${query}`, {
      headers: HEADERS,
    });

    const time = res.data.data.duration;
    return time;
  } catch (error) {
    return null;
  }
};
