import axios from "axios";
import { FAM_API } from "../../constants";
import { showError, showSuccess } from "../../components/ToastMessage";

export const loginCall = async (data) => {
	try {
		const response = await axios.post(`${FAM_API}/login`, data);
		if (response.data.success) {
			showSuccess("User login Successfully.");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			const errorMessage = error.response.data.error;
			showError(errorMessage);
			throw errorMessage;
		} else {
			console.log(error);
			showError("There is some error with server");
			throw error;
		}
	}
};

export const signupCall = async (data) => {
	try {
		const response = await axios.post(`${FAM_API}/register`, data);
		if (response.data.success) {
			showSuccess("User registered Successfully.");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			const errorMessage = error.response.data.error;
			showError(errorMessage);
			throw errorMessage;
		} else {
			console.log(error);
			showError("There is some error with server");
			throw error;
		}
	}
};

export const forgotPasswordCall = async (data) => {
	try {
		const response = await axios.post(`${FAM_API}/forgot-password`, data);
		if (response.data.success) {
			showSuccess("Please check your inbox.");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			const errorMessage = error.response.data.error;
			showError(errorMessage);
			throw errorMessage;
		} else {
			console.log(error);
			showError("There is some error with server");
			throw error;
		}
	}
};

export const resetPasswordCall = async (data, token) => {
	try {
		const response = await axios.post(`${FAM_API}/reset-password`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (response.data.success) {
			showSuccess("Your password has been changed successfully.");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			const errorMessage = error.response.data.error;
			showError(errorMessage);
			throw errorMessage;
		} else {
			console.log(error);
			showError("There is some error with server");
			throw error;
		}
	}
};

export const verifyGoogleApiCall = async (data) => {
	try {
		const response = await axios.post(`${FAM_API}/social-login`, data);
		if (response.data.success) {
			showSuccess("User loggedIn successfully.");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			const errorMessage = error.response.data.error;
			showError(errorMessage);
			throw errorMessage;
		} else {
			console.log(error);
			showError("There is some error with server");
			throw error;
		}
	}
};

export const verifyOtpApiCall = async (data) => {
	try {
		const response = await axios.post(`${FAM_API}/verify-otp`, data);
		if (response.data.success) {
			showSuccess("Otp Code is verified.");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			const errorMessage = error.response.data.error;
			showError(errorMessage);
			throw errorMessage;
		} else {
			console.log(error);
			showError("There is some error with server");
			throw error;
		}
	}
};

export const contactUsCall = async (data) => {
	try {
		const response = await axios.post(`${FAM_API}/contact-us`, data);
		if (response.data.success) {
			showSuccess("Information sent Successfully.");
		}
		return response.data;
	} catch (error) {
		if (error.response && error.response.data && error.response.data.error) {
			const errorMessage = error.response.data.error;
			showError(errorMessage);
			throw errorMessage;
		} else {
			console.log(error);
			showError("There is some error with server");
			throw error;
		}
	}
};
