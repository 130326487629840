import React from "react";
import "./footer.scss";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Footer = () => {
  const isMobileScreen =
    /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
      navigator.userAgent
    );

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  // URLs for Play Store and App Store
  const playStoreUrl = "https://play.google.com/store/apps/details?id=com.footballaroundme.fame&pli=1";
  const appStoreUrl = "https://apps.apple.com/us/app/football-around-me/id1534363768";

  return (
    <div className="footer">
      <div className="left-sec">
        Copyright©2020 All Rights Reserved by Football Around Me.{" "}
      </div>
      <div className="footer-link">
        <Link to="/privacy-policy">Privacy Policy</Link>
        {isMobileScreen && (
          <a
            href={isAndroid ? playStoreUrl : isIOS ? appStoreUrl : "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open/download App
          </a>
        )}
        <Link to="/terms-condition">Terms and Condition</Link>
      </div>
      <div className="social-icons">
        <div className="icon icon.disabled">
          <FaFacebookF />
        </div>
        <div className="icon icon.disabled">
          <FaInstagram />
        </div>
        <div className="icon icon.disabled">
          <FaTwitter />
        </div>
        <div className="icon icon.disabled">
          <FaLinkedinIn />
        </div>
      </div>
    </div>
  );
};

export default Footer;
