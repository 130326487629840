import axios from "axios";
import { HEADERS, FAM_API } from "../constants";

export const getFixtureDetails = async (fixture_id) => {
  try {
    const res = await axios.get(`${FAM_API}/fixture-details/${fixture_id}`, {
      headers: HEADERS,
    });
    const details = res.data;
    console.log("Details", details);
    return details;
  } catch (error) {
    return null;
  }
};
