import React from "react";
import PropTypes from "prop-types";

import Bwin from "../../assets/images/bwin.png";
import BetFair from "../../assets/images/betfair.jpg";
import Bet365 from "../../assets/images/bet365.png";
import MarathonBet from "../../assets/images/marathonbet.jpg";
import WilliamHill from "../../assets/images/williamhill.jpg";
import "./oddsRow.scss";
import Mixpanel from "../../utils/mixPanel";
const platform = typeof window !== "undefined" ? "web" : "mobile";
const OddsRow = ({ bookmaker }) => {
  const { name, bets } = bookmaker;
  let bookmakerName = name.replace(/\s/g, "").toLowerCase();
  let img =
    bookmakerName === "bwin"
      ? Bwin
      : bookmakerName === "betfair"
      ? BetFair
      : bookmakerName === "bet365"
      ? Bet365
      : bookmakerName === "marathonbet"
      ? MarathonBet
      : WilliamHill;
  let betsObj = {};
  const abc = bets[0]?.values?.map((bet) => {
    betsObj = { ...betsObj, [bet.value]: bet.odd };
  });
  return (
    <div className="odds-tableRow">
      <div className="odds-tableCol1">
        <span className="odds-tableTxt">{betsObj.Home}</span>
      </div>
      <div className="odds-tableCol1">
        <span className="odds-tableTxt">{betsObj.Draw}</span>
      </div>
      <div className="odds-tableCol1">
        <span className="odds-tableTxt">{betsObj.Away}</span>
      </div>

      <div className="odds-tableCol2">
        <a
          className="odds-betButton"
          href={`https://${bookmakerName}.com`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            Mixpanel.track(`Pressed on betting website`, {
              platform: platform,
              application_type: platform === "web" ? "web app" : "mobile app",
              link: `https://${bookmakerName}.com`,
            })
          }
        >
          <img
            src={img}
            alt="btn logo"
            className="odds-betButton-img"
            onError={(e) => (e.target.src = img)}
          />
        </a>
      </div>
    </div>
  );
};

OddsRow.propTypes = {
  bookmaker: PropTypes.object,
};

export default OddsRow;
