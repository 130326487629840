import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { trimString } from "../../helpers/trimString";
import "./h2hRow.scss";

const H2hRow = ({ fixture, home_team, away_team }) => {
  const {
  teams,
    score: { fulltime },
  } = fixture;

  const getBorderColor = (fulltime, side) => {
    if (side === "left") {
      if (fulltime.home < fulltime.away) return "red";
      else return "green";
    } else {
      if (fulltime.home > fulltime.away) return "red";
      else return "green";
    }
  };

  const homeTeamLogo =
    teams.home?.id === home_team?.team_id ? home_team?.logo : teams.home?.logo;

  const awayTeamLogo =
    teams.away?.id === away_team?.team_id ? away_team?.logo : teams.away?.logo;

  return (
    <div className="h2h-row">
      <div className="h2h-teamComparison">
        <div className="h2h-team h2h-homeTeam">
          <span className="h2h-teamName">
            {trimString(teams.home?.name, 19)}
          </span>
          <img className="h2h-teamLogo" alt="logo" src={homeTeamLogo}  onError={(e) => e.target.src =homeTeamLogo} />
        </div>
        <div className="h2h-vsContainer">
          <div
            className="h2h-score"
            style={{
              borderLeft: `3px solid ${getBorderColor(fulltime, "left")}`,
              borderRight: `3px solid ${getBorderColor(fulltime, "right")}`,
            }}
          >
            <span className="h2h-vsText">{fulltime.home} - {fulltime.away}</span>
          </div>
        </div>
        <div className="h2h-team h2h-awayTeam">
          <img className="h2h-teamLogo" alt="logo" src={awayTeamLogo}  onError={(e) => e.target.src = awayTeamLogo} />
          <span className="h2h-teamName">
            {trimString(teams.away?.name, 19)}
          </span>
        </div>
      </div>
      <span className="h2h-date">
        {" "}
        {moment(fixture?.fixture?.date).format("dddd DD MMM YYYY")}
      </span>
    </div>
  );
};

H2hRow.propTypes = {
  fixture: PropTypes.object,
  home_team: PropTypes.object,
  away_team: PropTypes.object,
};

export default H2hRow;
