import { createSelector } from "reselect";

export const sortingValues = (state) => state.sortingValues;

export const selectOrder = createSelector(
  sortingValues,
  (sortingValues) => sortingValues.order
);

export const selectSortByDate = createSelector(
  sortingValues,
  (sortingValues) => sortingValues.sortByDate
);

export const selectSortByDistance = createSelector(
  sortingValues,
  (sortingValues) => sortingValues.sortByDistance
);
