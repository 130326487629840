import axios from "axios";
import { FAM_API, HEADERS } from "../constants";

export const getSharedFixture = async ( payload ) => {
  try {
    const { id, coords } = payload;
    const query = new URLSearchParams({
      location: JSON.stringify(coords)
    }).toString();

    const res = await axios.get(`${FAM_API}/fixture/${id}?${query}`, {
      headers: HEADERS,
    });
    const fix = res.data.data.fixture;
    if(fix) return fix; else window.location.href ="/" ;
  } catch (error) {
    window.location.href ="/"
    console.log(error)
    return null;
  }
};
