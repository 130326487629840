import {
  FETCH_FIXTURE,
  // FETCH_FIXTURE_DETAILS_START,
  // FETCH_FIXTURE_DETAILS_SUCCESS,
  RESET_FIXTURE_DETAILS,
  FETCH_SUPPORTERS_DETAILS_START,
  FETCH_SUPPORTERS_DETAILS_SUCCESS,
  FETCH_MATCH_DETAILS_START,
  FETCH_MATCH_DETAILS_SUCCESS,
  RESET_MATCH_DETAILS,
  FETCH_STANDINGS_START,
  FETCH_STANDINGS_SUCCESS,
  FETCH_STADIUM_IMAGES_START,
  FETCH_STADIUM_IMAGES_SUCCESS,
  FETCH_SUMMARY_START,
  FETCH_SUMMARY_SUCCESS,
  FETCH_MATCH_DETAILS_FAIL,
  FETCH_TRAVEL_TIME_SUCCESS,
  FETCH_SHARED_FIXTURE_SUCCESS,
} from "./fixtureDetailsTypes";

const INITIAL_STATE = {
  fixture: null,
  standings: null,
  lastFiveMatches: null,
  h2h: null,
  odds: null,
  weather: null,
  supporters: null,
  leagueTeams: null,
  events: null,
  isFetching: false,
  stadiumImages: null,
  isFetchingStadiumImages: true,
  isFetchingEvents: true,
  attractions: null,
  isError: false,
  travelTime: null,
  sharedFixture: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FIXTURE:
      return { ...state, fixture: action.payload };
    case FETCH_SUPPORTERS_DETAILS_START:
    case FETCH_MATCH_DETAILS_START:
    case FETCH_STANDINGS_START:
      return { ...state, isFetching: true };
    case FETCH_SUPPORTERS_DETAILS_SUCCESS:
    case FETCH_MATCH_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: action.payload.attractions ? true : false,
      };

    case FETCH_STANDINGS_SUCCESS:
      return { ...state, isFetching: false, standings: action.payload };
    case FETCH_STADIUM_IMAGES_START:
      return { ...state, isFetchingStadiumImages: true };
    case FETCH_STADIUM_IMAGES_SUCCESS:
      return {
        ...state,
        isFetchingStadiumImages: false,
        stadiumImages: action.payload,
      };

    case FETCH_SUMMARY_START:
      return { ...state, isFetchingEvents: true };
    case FETCH_SUMMARY_SUCCESS:
      return { ...state, isFetchingEvents: false, ...action.payload };
    case FETCH_TRAVEL_TIME_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_SHARED_FIXTURE_SUCCESS:
      return { ...state, ...action.payload };
    case RESET_MATCH_DETAILS:
      return {
        ...state,
        lastFiveMatches: null,
        odds: null,
        h2h: null,
        standings: null,
        events: null,
        supporters: null,
        stadiumImages: null,
        attractions: null,
        ...action.payload,
        // fixture:null
      };
    case RESET_FIXTURE_DETAILS:
      return INITIAL_STATE;

    case FETCH_MATCH_DETAILS_FAIL:
      return {
        ...state,
        // ...action.payload,
        isFetching: false,
        lastFiveMatches: null,
        odds: null,
        h2h: null,
        standings: null,
        isError: true,

        // leagueTeams:null
      };
    default:
      return state;
  }
};
