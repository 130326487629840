import { FETCH_MAP_DETAILS, RESET_MAP_DETAILS,FETCH_MAP_DETAILS_ON_ATTRACTION } from "./mapTypes";

export const fetchMapDetails = (payload) => {  
  return {
    type: FETCH_MAP_DETAILS,
    payload,
  };
};

export const resetMapDetails = () => ({
  type: RESET_MAP_DETAILS,
});

export const fetchMapDetailsOnAttraction = (payload) => {  
  return {
    type: FETCH_MAP_DETAILS_ON_ATTRACTION,
    payload,
  };
};