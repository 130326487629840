import axios from "axios";
import { FAM_API } from "../constants";

export const getNearbyCountries = async (
  coords,
  radius,
  countryCode,
  countries
) => {
  try {
    const res = await axios.get(`${FAM_API}/nearby-countries`, {
      params: {
        lat: coords.lat,
        lng: coords.lng,
        radius: radius,
        iso_a2: countryCode,
      },
    });

    const fetchedCountries = res.data.data && res.data.data.countries;
    const region = res.data.data && res.data.data.region;

    if (!fetchedCountries.length) {
      throw new Error();
    }
    const fetchedCountriesCodes = [];
    for (let i = 0; fetchedCountries.length > i; i++) {
      const currentCountry = fetchedCountries[i];
      fetchedCountriesCodes.push(currentCountry.iso_a2);
    }

    const filteredCountries = [];

    for (let i = 0; i < fetchedCountriesCodes.length; i++) {
      const currentCode = fetchedCountriesCodes[i];
      if (Object.prototype.hasOwnProperty.call(countries, currentCode))
        filteredCountries.push(countries[currentCode]);
    }

    if (!filteredCountries.length) {
      throw new Error();
    }

    let uniqueCountries = [
      ...new Map(filteredCountries.map((c) => [c.code, c])).values(),
    ];

    if (!uniqueCountries.length) {
      throw new Error();
    }

    return { fetchedCountries: uniqueCountries, region: region };
  } catch (error) {
    return { error: "Could not fetch nearby countries" };
  }
};
