import React from "react";

import Player from "../../assets/images/player.png";
import "./matchesNotFound.scss";

const MatchesNotFound = () => {
  return (
    <div className="matches-not-found">
      <div className="matches-not-found-titles">
        <span className="matches-not-found-mainTitle">MATCHES NOT FOUND</span>
        <span className="matches-not-found-subTitle">Please try again</span>
      </div>
      <img className="matches-not-found-img" src={Player} alt="player" onError={(e) => e.target.src = Player}/>
    </div>
  );
};

export default MatchesNotFound;
