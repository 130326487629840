import { all, call } from "redux-saga/effects";
import { leaguesSagas } from "./leagues/leaguesSagas";
import fixturesSagas from "./fixtures/fixturesSagas";
import fixtureDetailsSagas from "./fixtureDetails/fixtureDetailsSagas";
import liveSagas from "./live/liveSagas";
import reviewSagas from "./reviews/reviewSagas";
import attractionSagas from "./attractions/attractionSagas";
import { authSagas } from "./auth/authSagas";

export default function* rootSaga() {
  yield all([
    call(leaguesSagas),
    call(fixturesSagas),
    call(fixtureDetailsSagas),
    call(liveSagas),
    call(reviewSagas),
    call(attractionSagas),
    call(authSagas),
  ]);
}
