import { createSelector } from "reselect";
import { sortFixtures } from "../../helpers/sortFixtures";
import { selectFilterModalSelectedLeaguesIds } from "../filteringValues/filteringValuesSelectors";
import { filterFixtures } from "../../helpers/filterFixtures";
import { filterItemsByDistance } from "../../utils";

// import moment from "moment";

export const selectFixtures = (state) => state.fixtures;
const selectFormValues = (state) => state.formValues;
const selectTempLeagues = (state) =>state.filteringValues 
export const selectSplittedFixtures = createSelector(
  selectFixtures,
  (fixtures) => fixtures.fixtures
);

export const selectIsAllCountries = createSelector(
  selectFixtures,
  (fixtures) => fixtures.isAllCountries
);

export const selectMatches = createSelector(
  [selectFixtures,selectTempLeagues],
  (fixtures,filteringValues) => {
    let matchesObject = fixtures.matches;
    for (const date in matchesObject) {
      if (matchesObject.hasOwnProperty(date)) {
          matchesObject[date].leagues = filteringValues.tempselectedLeagues;
      }
  }
    return matchesObject
  }
);


export const selectedMobileFixture = createSelector(
  selectFixtures,
  (fixtures) => fixtures.mobileSelectedFixtures
);

export const selectFixturesList = createSelector(
  [selectFixtures],
  (fixtures) => {
    const insideRadiusFixtures = fixtures?.fixtures?.insideRadiusFixtures;
    const outsideRadiusFixtures = fixtures?.fixtures?.outsideRadiusFixtures ? fixtures?.fixtures?.outsideRadiusFixtures : [];
    if (insideRadiusFixtures?.length || outsideRadiusFixtures?.length) {
      return [...insideRadiusFixtures, ...outsideRadiusFixtures];
    }
  }
);

export const selectFixturesByLeagues = createSelector(
  [selectFixtures],
  (fixtures) => {
    const insideRadiusFixtures = fixtures?.fixtures?.insideRadiusFixtures;
    const outsideRadiusFixtures = fixtures?.fixtures?.outsideRadiusFixtures;
    if (insideRadiusFixtures?.length || outsideRadiusFixtures?.length) {
      const allFixtures = [...insideRadiusFixtures, ...outsideRadiusFixtures];
    
      
      const sortedFixtures = allFixtures.sort((fixtureA, FixtureB) => {
        if (fixtureA.distance == null) {
          return 1;
        } else if (FixtureB.distance == null) {
          return -1;
        }
        return fixtureA.distance - FixtureB.distance;
      })
      const fixturesByLeague = sortedFixtures.reduce((acc, currentMatch) => {
        if (!acc[currentMatch.league_id]) {
          acc[currentMatch.league_id] = [];
        }
        acc[currentMatch.league_id].push(currentMatch);
        return acc;
      }, {});


      return fixturesByLeague;
    }
  }
);

export const selectFixturesByLeaguesRadius = createSelector(
  [selectFixtures, selectFormValues],
  (fixtures, formValues) => {
    const insideRadiusFixtures = fixtures?.fixtures?.insideRadiusFixtures;
    const outsideRadiusFixtures = fixtures?.fixtures?.outsideRadiusFixtures ? fixtures?.fixtures?.outsideRadiusFixtures : [];
    if (insideRadiusFixtures?.length || outsideRadiusFixtures?.length) {
      const allFixtures = [...insideRadiusFixtures, ...outsideRadiusFixtures];

      const sortedFixtures = allFixtures.sort((fixtureA, FixtureB) => {
        if (fixtureA.distance == null) {
          return 1;
        } else if (FixtureB.distance == null) {
          return -1;
        }
        return fixtureA.distance - FixtureB.distance;
      });
      const fixturesByLeague = sortedFixtures.reduce((acc, currentMatch) => {
        if (!acc[currentMatch.league_id]) {
          acc[currentMatch.league_id] = [];
        }
        if (currentMatch.radius == formValues.radius) {
          acc[currentMatch.league_id].push(currentMatch);
        }
        return acc;
      }, {});
      return fixturesByLeague;
    }
  }
);

const getMarker = (state) => state.map.marker;
const getMarkerSelected = (state) => state.map.markerSelected;
export const getFilteringValues = (state) => state.filteringValues;
export const getSortingvalue = (state) => state.sortingvalue;

export const selectDynamicFixturesList = 
  createSelector(
     [selectFixtures, 
      selectFilterModalSelectedLeaguesIds, 
      getMarker,
      getMarkerSelected,
      getFilteringValues,
      getSortingvalue],
    (fixtures, selectedLeaguesIds, marker , markerSelected, filteringValues, sortingValues) => {
      const { isItFiltered } = filteringValues;
      const mainFixturesList = {
        insideRadiusFixtures: [],
        outsideRadiusFixtures: [],
      };
      for (let key in fixtures.fixtures) {
        const fixList = fixtures?.fixtures[key];
        if (fixList?.length) {
          if (!sortingValues) {
            const fixtures = fixList.filter((x) => x?.lat && x?.lng && x?.venue);
            mainFixturesList[key] = isItFiltered
              ? filterFixtures(fixtures, selectedLeaguesIds)
              : fixtures;
          } else {
            if (markerSelected && sortingValues && marker) {
              mainFixturesList[key] = sortingValues
                ? fixList.filter(
                  (x) => x.lat === marker.lat && x.lng === marker.lng
                )
                : fixList;
            } else {
              const fixturesList = isItFiltered
                ? filterFixtures(fixList, selectedLeaguesIds)
                : fixList;

              const fixtures = sortFixtures(fixturesList, sortingValues);
              const grouped = filterItemsByDistance(fixtures);

              mainFixturesList[key] = grouped;
            }
          }
        }
      }
      return mainFixturesList;
    }
);

export const selectFixturesFetching = createSelector(
  selectFixtures,
  (fixtures) => fixtures.isFetching
);

export const selectHomeFixturesLeagues = createSelector(
  [selectFixtures, selectTempLeagues],
  (fixtures, filteringValues) => {
    // if (fixtures.leagues) {
      // return fixtures.leagues;
    // } else {
      return filteringValues.tempselectedLeagues;
    // }
  }
);

export const selectFicturesLeaguesArray = createSelector(
  [selectFixtures],
  (fixtures) => {
    
    if (fixtures.leagues) {
      const { leagues } = fixtures;
      for (var i = 0; i < leagues.length; i++) {
        leagues[i].isradius = true
      }
      
      return fixtures.leagues;
    } else {
      return fixtures.leagues;
    }
  }
);
export const selectFixturesLeagues = createSelector(
  [selectFixtures],
  
  (fixtures) => {
    if (fixtures.leagues) {
      let normalizedLeagues = {};
      const { leagues } = fixtures;
      if(!leagues){
        return normalizedLeagues;
      }
      for (var i = 0; i < leagues.length; i++)
        if (leagues[i].league_id in normalizedLeagues)
        normalizedLeagues[leagues[i].league_id] = leagues[i];
        else normalizedLeagues[leagues[i].league_id] = leagues[i];

      
      return normalizedLeagues;
    }
  }
);

export const selectSearchBy = createSelector(
  selectFixtures,
  (fixtures) => fixtures.searchBy
);

export const selectFixturesError = createSelector(
  selectFixtures,
  (fixtures) => fixtures.error
);


export const selectAllLoaders = createSelector(
  selectFixtures,
  (fixtures) => fixtures.allLoaders
);