import React from 'react'


export const Boy =()=> <svg xmlns="http://www.w3.org/2000/svg"  width="419" height="430.568" viewBox="0 0 419 430.568">

<g id="Group_1066" data-name="Group 1066" transform="translate(0 0.015)">
  <circle id="Ellipse_106" data-name="Ellipse 106" cx="209.5" cy="209.5" r="209.5" transform="translate(0 -0.015)" fill="#f9f9f9"/>
  <g id="Group_1068" data-name="Group 1068" transform="translate(11.794 71.873)" opacity="0.26">
    <g id="Group_1063" data-name="Group 1063" transform="translate(0 0)">
      <path id="Path_1525" data-name="Path 1525" d="M576.018,698.8c-.538-.679-1.247.672-1.957.172h3.933C577.193,699.414,576.568,698.071,576.018,698.8Z" transform="translate(-499.604 -611.672)" fill="none"/>
      <path id="Path_1526" data-name="Path 1526" d="M656.54,700.852h.678c-.146.1-.292.2-.431.315C656.708,701.059,656.623,700.957,656.54,700.852Z" transform="translate(-593.056 -613.555)" fill="none"/>
      <path id="Path_1527" data-name="Path 1527" d="M540.668,696.125a6.494,6.494,0,0,1,3.156.815h-6.3A6.462,6.462,0,0,1,540.668,696.125Z" transform="translate(-459.131 -609.643)" fill="#e6e6e6"/>
      <path id="Path_1528" data-name="Path 1528" d="M602,691.4a8.919,8.919,0,0,1,5.151,1.631h-10.3A8.916,8.916,0,0,1,602,691.4Z" transform="translate(-532.697 -605.731)" fill="#e6e6e6"/>
      <path id="Path_1529" data-name="Path 1529" d="M660.492,555.9a16.715,16.715,0,0,1,32.8-3.524c.2-.007.4-.015.609-.015a16.719,16.719,0,0,1,16.038,12,11.813,11.813,0,0,1,14.019,3.49H671.811a11.307,11.307,0,0,1-11.331-11.707C660.483,556.063,660.487,555.981,660.492,555.9Z" transform="translate(-660.47 -480.554)" fill="#e6e6e6"/>
      <rect id="Rectangle_239" data-name="Rectangle 239" width="146.454" height="0.345" transform="translate(1.375 87.125)" fill="#3f3d56"/>
      <path id="Path_1530" data-name="Path 1530" d="M422.613,372.035s6.647-2.071,5.627,1.336-6.619,1.686-6.619,1.686Z" transform="translate(-323.66 -340.944)" fill="#ffb8b8"/>
      <path id="Path_1531" data-name="Path 1531" d="M451.573,291.222s3.407-4.655,6.716.177,6.4,8.817,6.4,8.817l8.236,2.685,4.765.423-1.313,4.236-13.854-2.01S447.1,296.39,451.573,291.222Z" transform="translate(-378.03 -272.918)" fill="#2f2e41"/>
      <path id="Path_1532" data-name="Path 1532" d="M495.4,450.352s5.175,3.623,4.83,6.9-2.588,12.248-2.588,12.248l-4.658-.69s-.517-6.9,1.208-9.488l-8.28-4.658Z" transform="translate(-406.657 -406.266)" fill="#ffb8b8"/>
      <path id="Path_1533" data-name="Path 1533" d="M588.225,499.852s.345,6.383-.517,8.97-1.725,12.247-1.725,12.247h-4.83s-2.243-8.452.518-12.247c0,0,.862-5-1.553-7.418Z" transform="translate(-510.949 -447.228)" fill="#ffb8b8"/>
      <path id="Path_1534" data-name="Path 1534" d="M522.474,390.732s11.213,7.073,11.213,9.315l-8.97,7.418-3.1-3.45.345,5.175-9.66,1.9s-3.45-13.11-3.277-13.628.69-8.108.69-8.108Z" transform="translate(-444.077 -355.789)" fill="#2f2e41"/>
      <path id="Path_1535" data-name="Path 1535" d="M500.22,552.352l5,1.035-.889,3.654a13.048,13.048,0,0,0-.146,5.489h0s-5.175,1.38-5.175.69,1.208-3.45.863-5.348A20.715,20.715,0,0,1,500.22,552.352Z" transform="translate(-413.888 -490.671)" fill="#666bf1"/>
      <path id="Path_1536" data-name="Path 1536" d="M467.613,610.71c.172-.69,1.606-3.819,1.665-3.289s2.82,1.392,3.855.357c0,0,1.38-1.208,1.9,0s1.553,3.278,1.553,3.278,4.658,2.242,3.968,3.45c-.14.244-.526.347-1.054.356a.281.281,0,0,1,.019.1v.122a.284.284,0,0,1-.284.284h-.122a.284.284,0,0,1-.284-.284v-.122a.281.281,0,0,1,.031-.127c-.141-.012-.287-.029-.438-.049a.281.281,0,0,1,.062.175v.122a.284.284,0,0,1-.284.284h-.122a.284.284,0,0,1-.284-.284v-.122a.283.283,0,0,1,.147-.247c-.4-.066-.811-.15-1.223-.244a.283.283,0,0,1,.042.146v.122a.284.284,0,0,1-.284.284h-.122a.284.284,0,0,1-.284-.284v-.122a.283.283,0,0,1,.169-.259c-.309-.075-.612-.154-.9-.232a.281.281,0,0,1,.041.146v.122a.284.284,0,0,1-.284.284h-.122a.284.284,0,0,1-.284-.284v-.122a.283.283,0,0,1,.2-.27c-1.013-.285-1.752-.532-1.752-.532s-.858-.312-1.9-.734v.105a.284.284,0,0,1-.284.284h-.122a.284.284,0,0,1-.284-.284v-.122a.282.282,0,0,1,.11-.223c-.209-.088-.419-.178-.627-.269v.1a.284.284,0,0,1-.284.284h-.122a.284.284,0,0,1-.284-.284V612.2a.283.283,0,0,1,.121-.232c-.225-.1-.442-.21-.642-.313,0,.009,0,.018,0,.028v.122a.284.284,0,0,1-.284.284h-.122a.284.284,0,0,1-.284-.284v-.122a.284.284,0,0,1,.227-.278C467.875,611.126,467.571,610.877,467.613,610.71Z" transform="translate(-383.867 -536.092)" fill="#2f2e41"/>
      <path id="Path_1537" data-name="Path 1537" d="M587.513,616.7l5.465-.345s-.331,5.693.331,7.59.331,2.588.331,2.588l-4.8.345Z" transform="translate(-517.654 -543.631)" fill="#666bf1"/>
      <path id="Path_1538" data-name="Path 1538" d="M545.818,668.227c.025-.711.774-4.069.943-3.564s3.048.773,3.844-.455c0,0,1.1-1.469,1.856-.4s2.2,2.882,2.2,2.882,5.023,1.222,4.6,2.547c-.086.268-.442.449-.957.568a.285.285,0,0,1,.04.094l.025.12a.284.284,0,0,1-.218.337l-.12.026a.284.284,0,0,1-.337-.219l-.026-.12a.282.282,0,0,1,0-.13c-.14.017-.287.032-.438.044a.282.282,0,0,1,.1.158l.026.12a.284.284,0,0,1-.218.337l-.12.026a.284.284,0,0,1-.337-.219l-.026-.12a.282.282,0,0,1,.092-.272c-.4.018-.825.022-1.248.016a.283.283,0,0,1,.071.134l.026.12a.284.284,0,0,1-.219.337l-.119.026a.284.284,0,0,1-.337-.219l-.026-.12a.283.283,0,0,1,.111-.288c-.318-.009-.63-.023-.929-.039a.281.281,0,0,1,.071.134l.026.12a.284.284,0,0,1-.218.337l-.12.026a.284.284,0,0,1-.337-.219l-.025-.12a.283.283,0,0,1,.139-.305c-1.05-.068-1.825-.155-1.825-.155s-.9-.126-2.009-.322l.022.1a.284.284,0,0,1-.219.337l-.12.026a.284.284,0,0,1-.337-.219l-.025-.12a.282.282,0,0,1,.061-.241c-.222-.042-.447-.087-.67-.133l.02.095a.284.284,0,0,1-.219.337l-.12.026a.284.284,0,0,1-.337-.219l-.026-.12a.283.283,0,0,1,.07-.253c-.242-.056-.476-.113-.693-.172a.254.254,0,0,1,.008.026l.026.12a.284.284,0,0,1-.219.337l-.12.026a.284.284,0,0,1-.337-.219l-.026-.12a.284.284,0,0,1,.164-.319C546.161,668.579,545.812,668.4,545.818,668.227Z" transform="translate(-476.026 -582.58)" fill="#2f2e41"/>
      <ellipse id="Ellipse_107" data-name="Ellipse 107" cx="5.003" cy="5.003" rx="5.003" ry="5.003" transform="translate(73.395 2.513)" fill="#ffb8b8"/>
      <path id="Path_1539" data-name="Path 1539" d="M583.492,235.352s-2.76,3.45-4.14,3.278l6.038,4.658s.69-2.933,1.553-3.278S583.492,235.352,583.492,235.352Z" transform="translate(-509.579 -228.354)" fill="#ffb8b8"/>
      <path id="Path_1540" data-name="Path 1540" d="M546,272.327s0-19.035,3.194-20.675,5.3-2.574,5.3-2.574h0a4.478,4.478,0,0,0,1.721,3.2,6.4,6.4,0,0,0,2.7,1.5l.019-.081.072-.205,1.74,2.706a3.393,3.393,0,0,1,2.07,1.38c.28.56-1.04,6.2-.259,11.3.663,4.329,3.457,8.236,3.536,8.711.134.8-3.262-1.3-4.791-2.285l-.039.042s.862.345-.863-.172a11.249,11.249,0,0,0-3.342-.269c0,.3-.006.538-.022.7-.173,1.725-8.8,4.14-10,3.623S546,272.327,546,272.327Z" transform="translate(-482.868 -239.713)" fill="#2f2e41"/>
      <path id="Path_1541" data-name="Path 1541" d="M613.743,421.852s5.175,4.657,1.725,5.52-4.83-4.83-4.83-4.83Z" transform="translate(-544.574 -382.683)" fill="#ffb8b8"/>
      <path id="Path_1542" data-name="Path 1542" d="M598.551,485.174s-2.415-2.242-3.8-1.035-2.243,2.07-2.243,2.07S596.48,483.794,598.551,485.174Z" transform="translate(-523.344 -433.93)" fill="#6c63ff"/>
      <path id="Path_1543" data-name="Path 1543" d="M599.553,643.636s-1.361-1.264-2.139-.583-1.264,1.167-1.264,1.167S598.386,642.858,599.553,643.636Z" transform="translate(-524.974 -565.56)" fill="#2f2e41"/>
      <path id="Path_1544" data-name="Path 1544" d="M511.172,573.239s-.935-1.605-1.877-1.181-1.549.748-1.549.748S510.282,572.156,511.172,573.239Z" transform="translate(-421.343 -506.919)" fill="#2f2e41"/>
      <path id="Path_1545" data-name="Path 1545" d="M623.227,269.047a3.247,3.247,0,0,1,6.067,2.182q-.019.084-.04.169a34.044,34.044,0,0,1-4.313,10.005l1.9,8.453,2.07,4.313L624.6,295.2l-5.347-12.938S620.724,274.077,623.227,269.047Z" transform="translate(-558.792 -254.741)" fill="#2f2e41"/>
      <path id="Path_1546" data-name="Path 1546" d="M450,156.065l3.278,12.42-2.76-5.865Z" transform="translate(-383.074 -129.143)" opacity="0.2"/>
      <path id="Path_1547" data-name="Path 1547" d="M540.294,196.471s2.765-2.911,5.549-1.078,4.1,3.614,3.831,4.854a18,18,0,0,1-1.081,2.965s.5-2.569-1.8-2.759a20.012,20.012,0,0,1-5.489-1.715l-2.154,4.6s-.3-1.066-1.149-.76S537.809,196.812,540.294,196.471Z" transform="translate(-465.038 -194.787)" fill="#2f2e41"/>
      <path id="Path_1550" data-name="Path 1550" d="M426.1,577.936h3.477l1.738-3.011-1.738-3.011H426.1l-1.738,3.011Zm3.278-.345H426.3l-1.539-2.666,1.539-2.666h3.078l1.539,2.666Z" transform="translate(-327.098 -506.859)" fill="#fff"/>
    </g>
    <g id="Group_1064" data-name="Group 1064" transform="translate(93.045 62.485)">
      <g id="soccer_1_" data-name="soccer (1)">
        <path id="Path_1038" data-name="Path 1038" d="M6.523,0A6.523,6.523,0,1,1,0,6.523,6.53,6.53,0,0,1,6.523,0Z" fill="#303c42"/>
        <g id="Group_316" data-name="Group 316" transform="translate(0.7 1.854)">
          <path id="Path_1039" data-name="Path 1039" d="M374.718,76.091l1.6.229,1.2-2.157-.7-1.4A5.983,5.983,0,0,0,374.718,76.091Z" transform="translate(-374.718 -72.762)" fill="#fff"/>
          <path id="Path_1040" data-name="Path 1040" d="M250.58,323.83l1.524-.871-.907-1.814-1.827-.228-.651,1.518Z" transform="translate(-246.096 -314.594)" fill="#fff"/>
          <path id="Path_1041" data-name="Path 1041" d="M87.45,249.686l2.4.24,1.2-1.917-.709-1.89-2.4-.24L86.5,247.8Z" transform="translate(-80.908 -241.468)" fill="#fff"/>
          <path id="Path_1042" data-name="Path 1042" d="M108.608,98.469l-1.337,1.114.7,2.1,2.272.227.228-2.279Z" transform="translate(-100.86 -97.814)" fill="#fff"/>
        </g>
        <path id="Path_1043" data-name="Path 1043" d="M226.422,143.334l1.89.236,1.451-1.934-.714-2.142-2.637-.24-1.2,2.151Z" transform="translate(-222.455 -135.706)" fill="#666bf1"/>
        <path id="Path_1044" data-name="Path 1044" d="M192.97,22.319l.738,1.475,2.727.248,1.422-1.185-.254-1.364a5.906,5.906,0,0,0-4.632.826Z" transform="translate(-189.727 -20.789)" fill="#fff"/>
        <path id="Path_1045" data-name="Path 1045" d="M59.572,34.2l.212,1.136,2.007,1.254h.85A6,6,0,0,0,59.572,34.2Z" transform="translate(-51.113 -33.333)" fill="#2ab300"/>
        <path id="Path_1046" data-name="Path 1046" d="M22.57,149.333h-1l-.241,2.41.708,1.888,1.038-.261a5.891,5.891,0,0,0-.509-4.036Z" transform="translate(-10.729 -145.528)" fill="#fff"/>
        <path id="Path_1047" data-name="Path 1047" d="M36.126,331.436l-.864.217L34,333.669v.658A6,6,0,0,0,36.126,331.436Z" transform="translate(-23.947 -322.991)" fill="#ffda1a"/>
        <path id="Path_1048" data-name="Path 1048" d="M142.744,417.372v-.848l-2.387-.239-1.69.966v.826a5.879,5.879,0,0,0,4.077-.705Z" transform="translate(-133.231 -405.679)" fill="#fff"/>
        <path id="Path_1049" data-name="Path 1049" d="M323.031,397.5v-.72L321.06,395.3l-1.06.185A5.988,5.988,0,0,0,323.031,397.5Z" transform="translate(-318.139 -385.23)" fill="#5b5b5b"/>
        <path id="Path_1050" data-name="Path 1050" d="M323.031,397.5v-.72L321.06,395.3l-1.06.185A5.988,5.988,0,0,0,323.031,397.5Z" transform="translate(-318.139 -385.23)" fill="#2ab300" opacity="0.1"/>
        <path id="Path_1051" data-name="Path 1051" d="M375.629,228.5l1.291-.225.7-1.636-1.216-1.947-1.679-.24a5.826,5.826,0,0,0,.9,4.048Z" transform="translate(-374.121 -218.733)" fill="#fff"/>
        <path id="Path_1052" data-name="Path 1052" d="M138.667,429.541v-.826l1.051-.6a5.755,5.755,0,0,0,3.026-.065v.786a5.879,5.879,0,0,1-4.077.705Z" transform="translate(-133.231 -417.143)" opacity="0.1"/>
        <path id="Path_1053" data-name="Path 1053" d="M66.773,392.257a6.03,6.03,0,0,1-1.322,1.341v-.61A5.96,5.96,0,0,0,66.773,392.257Z" transform="translate(-55.395 -382.263)" opacity="0.1"/>
        <path id="Path_1054" data-name="Path 1054" d="M287.8,364.126l-.591.338-1.861-1.4.2-.456A5.99,5.99,0,0,0,287.8,364.126Z" transform="translate(-282.021 -353.374)" opacity="0.1"/>
        <path id="Path_1055" data-name="Path 1055" d="M389.282,225.256a5.979,5.979,0,0,1,.06-.8l1.35.193a5.94,5.94,0,0,0,1.172,2.862l-.329.767-1.291.225A5.939,5.939,0,0,1,389.282,225.256Z" transform="translate(-388.738 -218.733)" opacity="0.1"/>
        <path id="Path_1056" data-name="Path 1056" d="M400.22,76.092a5.984,5.984,0,0,1,2.1-3.329l.051.1a5.915,5.915,0,0,0-.946,3.208c0,.065.008.128.01.193Z" transform="translate(-399.52 -70.909)" opacity="0.1"/>
        <path id="Path_1057" data-name="Path 1057" d="M6.523,0A6.523,6.523,0,1,1,0,6.523,6.53,6.53,0,0,1,6.523,0Z" fill="url(#linear-gradient)"/>
      </g>
    </g>
  </g>
  <ellipse id="Ellipse_96" data-name="Ellipse 96" cx="179.007" cy="32.181" rx="179.007" ry="32.181" transform="translate(22.795 366.191)" fill="#3f3d56"/>
  <g id="Group_1061" data-name="Group 1061" transform="translate(66.794 71.878)">
    <path id="Path_1515" data-name="Path 1515" d="M390.985,361.232s-12.6,35.461-7,48.059a156.437,156.437,0,0,0,14.464,24.729S395.185,363.565,390.985,361.232Z" transform="translate(-380.167 -277.527)" opacity="0.1"/>
    <path id="Path_1516" data-name="Path 1516" d="M400.249,514.924s-.933,8.865-1.4,9.332.467,1.4,0,2.8-.933,3.266,0,3.733-5.133,41.527-5.133,41.527-14.931,19.6-8.865,50.392l1.866,31.262s14.464.933,14.464-4.2a85.485,85.485,0,0,1-.933-8.865c0-2.8,2.333-2.8.933-4.2s-1.4-2.333-1.4-2.333,2.333-1.866,1.866-2.333,4.2-33.595,4.2-33.595,5.133-5.133,5.133-7.932v-2.8s2.333-6.066,2.333-6.532,12.6-28.929,12.6-28.929l5.133,20.53,5.6,29.4s2.8,26.6,8.4,36.861c0,0,9.8,33.595,9.8,32.661s16.331-3.266,15.864-7.465-9.8-62.99-9.8-62.99l2.333-87.253Z" transform="translate(-380.565 -349.099)" fill="#2f2e41"/>
    <path id="Path_1517" data-name="Path 1517" d="M385.15,768.042s-12.6,24.729-4.2,25.662,11.665.933,15.4-2.8c2.041-2.041,6.173-4.779,9.5-6.842a12.286,12.286,0,0,0,5.83-11.683c-.246-2.283-1.1-4.164-3.2-4.339-5.6-.467-12.131-5.6-12.131-5.6Z" transform="translate(-378.064 -465.506)" fill="#2f2e41"/>
    <path id="Path_1518" data-name="Path 1518" d="M515.961,802.281s-12.6,24.729-4.2,25.662,11.665.933,15.4-2.8c2.041-2.041,6.173-4.779,9.5-6.842a12.286,12.286,0,0,0,5.83-11.683c-.246-2.283-1.1-4.164-3.2-4.339-5.6-.467-12.131-5.6-12.131-5.6Z" transform="translate(-439.352 -481.548)" fill="#2f2e41"/>
    <circle id="Ellipse_101" data-name="Ellipse 101" cx="19.614" cy="19.614" r="19.614" transform="translate(43.68 4.009)" fill="#ffb8b8"/>
    <path id="Path_1519" data-name="Path 1519" d="M460.061,260.308s-14.01,25.778-15.13,25.778,25.218,8.406,25.218,8.406,7.285-24.657,8.406-26.9Z" transform="translate(-409.376 -230.241)" fill="#ffb8b8"/>
    <path id="Path_1520" data-name="Path 1520" d="M458.332,300.213s-28-15.4-30.795-14.931-32.661,26.6-32.195,37.327,4.2,28.462,4.2,28.462,1.4,49.459,4.2,49.925-.467,8.865.467,8.865,65.323,0,65.789-1.4S458.332,300.213,458.332,300.213Z" transform="translate(-386.157 -241.938)" fill="#d0cde1"/>
    <path id="Path_1521" data-name="Path 1521" d="M535.052,521.892s8.865,27.062,1.4,26.129-10.732-23.329-10.732-23.329Z" transform="translate(-447.245 -352.801)" fill="#ffb8b8"/>
    <path id="Path_1522" data-name="Path 1522" d="M485.482,310.271S468.218,314,471.018,337.333s7.932,46.659,7.932,46.659l17.264,37.794,1.866,7,12.6-3.266-9.332-54.125s-3.266-57.858-7.466-59.723A18.07,18.07,0,0,0,485.482,310.271Z" transform="translate(-421.471 -253.628)" fill="#d0cde1"/>
    <path id="Path_1524" data-name="Path 1524" d="M495.655,221.583l.065-1.5,2.982.742a3.332,3.332,0,0,0-1.336-2.453l3.176-.178a34.268,34.268,0,0,0-22.919-14.164c-6.87-1-14.519.445-19.23,5.544a23.174,23.174,0,0,0-4.742,8.826c-1.881,5.909-2.264,12.953,1.658,17.756,3.986,4.882,10.949,5.838,17.223,6.442,2.207.213,4.521.41,6.566-.446a15.8,15.8,0,0,0-.879-6.929,4.617,4.617,0,0,1-.467-2.207c.279-1.866,2.768-2.336,4.638-2.084s4.119.638,5.348-.794c.846-.986.8-2.423.909-3.718C488.951,222.895,495.623,222.322,495.655,221.583Z" transform="translate(-412.834 -203.736)" fill="#2f2e41"/>
  </g>
  <g id="Group_1062" data-name="Group 1062" transform="translate(245.093 345.408)">
    <g id="soccer_1_2" data-name="soccer (1)" transform="translate(0 0)">
      <path id="Path_1038-2" data-name="Path 1038" d="M32.3,0a32.3,32.3,0,1,0,32.3,32.3A32.34,32.34,0,0,0,32.3,0Z" fill="#303c42"/>
      <g id="Group_316-2" data-name="Group 316" transform="translate(10.915 9.181)">
        <path id="Path_1039-2" data-name="Path 1039" d="M388.573,89.251l-7.919,1.132L374.718,79.7l3.469-6.937A29.631,29.631,0,0,1,388.573,89.251Z" transform="translate(-338.351 -72.762)" fill="#fff"/>
        <path id="Path_1040-2" data-name="Path 1040" d="M256.268,335.345l-7.549-4.312,4.493-8.985,9.047-1.13,3.223,7.518Z" transform="translate(-228.25 -289.604)" fill="#fff"/>
        <path id="Path_1041-2" data-name="Path 1041" d="M104.328,264.734l-11.889,1.189L86.5,256.429l3.511-9.362,11.872-1.187,7.121,9.494Z" transform="translate(-86.505 -224.035)" fill="#fff"/>
        <path id="Path_1042-2" data-name="Path 1042" d="M116.5,98.469l6.622,5.518L119.653,114.4,108.4,115.527l-1.129-11.288Z" transform="translate(-104.651 -95.225)" fill="#fff"/>
      </g>
      <path id="Path_1043-2" data-name="Path 1043" d="M241.758,159.459l-9.358,1.17-7.184-9.579,3.536-10.609,13.057-1.187,5.918,10.653Z" transform="translate(-196.798 -121.683)" fill="#666bf1"/>
      <path id="Path_1044-2" data-name="Path 1044" d="M217.167,26.214l-3.653,7.305-13.5,1.227-7.041-5.868,1.259-6.755a29.249,29.249,0,0,1,22.938,4.089Z" transform="translate(-168.621 -18.641)" fill="#fff"/>
      <path id="Path_1045-2" data-name="Path 1045" d="M74.769,34.2l-1.049,5.624-9.938,6.211H59.572A29.725,29.725,0,0,1,74.769,34.2Z" transform="translate(-52.055 -29.888)" fill="#2ab300"/>
      <path id="Path_1046-2" data-name="Path 1046" d="M24.6,149.333h4.935l1.193,11.934-3.505,9.348-5.142-1.293A29.176,29.176,0,0,1,24.6,149.333Z" transform="translate(-18.641 -130.49)" fill="#fff"/>
      <path id="Path_1047-2" data-name="Path 1047" d="M34,331.436l4.277,1.075,6.238,9.981v3.258A29.7,29.7,0,0,1,34,331.436Z" transform="translate(-29.712 -289.615)" fill="#ffda1a"/>
      <path id="Path_1048-2" data-name="Path 1048" d="M138.667,421.665v-4.2l11.82-1.182,8.369,4.783v4.088a29.118,29.118,0,0,1-20.189-3.492Z" transform="translate(-121.17 -363.758)" fill="#fff"/>
      <path id="Path_1049-2" data-name="Path 1049" d="M320,406.188v-3.564l9.762-7.322,5.25.916A29.658,29.658,0,0,1,320,406.188Z" transform="translate(-279.622 -345.422)" fill="#5b5b5b"/>
      <path id="Path_1050-2" data-name="Path 1050" d="M320,406.188v-3.564l9.762-7.322,5.25.916A29.658,29.658,0,0,1,320,406.188Z" transform="translate(-279.622 -345.422)" fill="#2ab300" opacity="0.1"/>
      <path id="Path_1051-2" data-name="Path 1051" d="M384.53,244.5l-6.392-1.115-3.473-8.1,6.025-9.64L389,224.452A28.855,28.855,0,0,1,384.53,244.5Z" transform="translate(-327.389 -196.13)" fill="#fff"/>
      <path id="Path_1052-2" data-name="Path 1052" d="M158.856,435.436v-4.088l-5.2-2.974a28.5,28.5,0,0,1-14.985-.324v3.895a29.117,29.117,0,0,0,20.189,3.492Z" transform="translate(-121.17 -374.037)" opacity="0.1"/>
      <path id="Path_1053-2" data-name="Path 1053" d="M65.451,392.257A29.866,29.866,0,0,0,72,398.9v-3.019A29.511,29.511,0,0,1,65.451,392.257Z" transform="translate(-57.192 -342.761)" opacity="0.1"/>
      <path id="Path_1054-2" data-name="Path 1054" d="M285.344,370.108l2.927,1.672,9.214-6.91-.967-2.257A29.666,29.666,0,0,1,285.344,370.108Z" transform="translate(-249.339 -316.858)" opacity="0.1"/>
      <path id="Path_1055-2" data-name="Path 1055" d="M402.075,228.433a29.592,29.592,0,0,0-.3-3.981l-6.687.955a29.422,29.422,0,0,1-5.806,14.175l1.629,3.8L397.3,244.5A29.412,29.412,0,0,0,402.075,228.433Z" transform="translate(-340.162 -196.13)" opacity="0.1"/>
      <path id="Path_1056-2" data-name="Path 1056" d="M410.857,89.252a29.632,29.632,0,0,0-10.385-16.489l-.251.5A29.294,29.294,0,0,1,404.9,89.154c0,.322-.038.635-.048.955Z" transform="translate(-349.719 -63.582)" opacity="0.1"/>
      <path id="Path_1057-2" data-name="Path 1057" d="M32.3,0a32.3,32.3,0,1,0,32.3,32.3A32.34,32.34,0,0,0,32.3,0Z" fill="url(#linear-gradient-2)"/>
    </g>
  </g>
  <g id="Group_1065" data-name="Group 1065" transform="translate(138.11 118.85)" opacity="0.26">
    <g id="Group_1063-2" data-name="Group 1063" transform="translate(0 0)">
      <path id="Path_1525-2" data-name="Path 1525" d="M577.678,698.98c.985-1.244,2.284,1.231,3.584.316h-7.2C575.527,700.11,576.671,697.65,577.678,698.98Z" transform="translate(-446.909 -539.446)" fill="none"/>
      <path id="Path_1526-2" data-name="Path 1526" d="M657.781,700.852H656.54c.267.187.534.374.789.576C657.474,701.232,657.629,701.043,657.781,700.852Z" transform="translate(-503.336 -541.002)" fill="none"/>
      <path id="Path_1527-2" data-name="Path 1527" d="M543.3,696.125a11.886,11.886,0,0,0-5.778,1.493h11.542A11.832,11.832,0,0,0,543.3,696.125Z" transform="translate(-421.911 -537.768)" fill="#e6e6e6"/>
      <path id="Path_1528-2" data-name="Path 1528" d="M606.291,691.4a16.331,16.331,0,0,0-9.432,2.986H615.71A16.326,16.326,0,0,0,606.291,691.4Z" transform="translate(-462.506 -534.534)" fill="#e6e6e6"/>
      <path id="Path_1529-2" data-name="Path 1529" d="M776.674,569.006a30.608,30.608,0,0,0-60.053-6.453c-.371-.013-.741-.028-1.115-.028A30.614,30.614,0,0,0,686.139,584.5a21.631,21.631,0,0,0-25.669,6.39h95.478A20.7,20.7,0,0,0,776.7,569.458Q776.687,569.233,776.674,569.006Z" transform="translate(-506.024 -431.045)" fill="#e6e6e6"/>
      <rect id="Rectangle_239-2" data-name="Rectangle 239" width="268.172" height="0.632" transform="translate(0 159.534)" fill="#3f3d56"/>
      <path id="Path_1530-2" data-name="Path 1530" d="M432.119,372.553s-12.172-3.791-10.3,2.446,12.121,3.087,12.121,3.087Z" transform="translate(-342.62 -315.622)" fill="#ffb8b8"/>
      <path id="Path_1531-2" data-name="Path 1531" d="M498.581,292.9s-6.238-8.524-12.3.325c-6.072,8.867-11.718,16.146-11.718,16.146l-15.081,4.917-8.725.774,2.4,7.757,25.368-3.68S506.773,302.361,498.581,292.9Z" transform="translate(-362.554 -259.381)" fill="#2f2e41"/>
      <path id="Path_1532-2" data-name="Path 1532" d="M494.792,450.352s-9.476,6.633-8.844,12.635,4.738,22.427,4.738,22.427l8.528-1.263s.948-12.635-2.211-17.373l15.162-8.528Z" transform="translate(-386.607 -369.627)" fill="#ffb8b8"/>
      <path id="Path_1533-2" data-name="Path 1533" d="M580.243,499.852s-.632,11.687.948,16.425,3.159,22.427,3.159,22.427h8.844s4.106-15.477-.948-22.427c0,0-1.579-9.16,2.843-13.582Z" transform="translate(-451.053 -403.492)" fill="#ffb8b8"/>
      <path id="Path_1534-2" data-name="Path 1534" d="M529.544,391.879s-20.531,12.951-20.531,17.057l16.425,13.582,5.685-6.317-.632,9.476,17.688,3.475s6.317-24.006,6-24.954-1.263-14.846-1.263-14.846Z" transform="translate(-402.408 -327.895)" fill="#2f2e41"/>
      <path id="Path_1535-2" data-name="Path 1535" d="M508.173,552.352l-9.16,1.9,1.628,6.691a23.891,23.891,0,0,1,.268,10.05h0s9.476,2.527,9.476,1.263-2.211-6.317-1.579-9.792S508.173,552.352,508.173,552.352Z" transform="translate(-395.566 -439.409)" fill="#666bf1"/>
      <path id="Path_1536-2" data-name="Path 1536" d="M491.426,613.593c-.316-1.263-2.94-6.992-3.049-6.023s-5.163,2.548-7.058.653c0,0-2.527-2.211-3.475,0s-2.843,6-2.843,6-8.528,4.106-7.265,6.317c.256.447.964.636,1.93.652a.516.516,0,0,0-.035.183v.224a.52.52,0,0,0,.52.52h.224a.52.52,0,0,0,.52-.52v-.224a.515.515,0,0,0-.057-.232c.258-.023.526-.052.8-.089a.516.516,0,0,0-.113.32v.224a.52.52,0,0,0,.52.52h.224a.52.52,0,0,0,.52-.52v-.224a.518.518,0,0,0-.269-.453c.727-.121,1.486-.276,2.24-.447a.516.516,0,0,0-.076.268v.224a.52.52,0,0,0,.52.52h.224a.52.52,0,0,0,.52-.52v-.224a.519.519,0,0,0-.309-.474c.566-.138,1.121-.282,1.648-.425a.515.515,0,0,0-.076.267v.224a.52.52,0,0,0,.52.52h.224a.52.52,0,0,0,.52-.52v-.224a.518.518,0,0,0-.366-.494c1.855-.522,3.208-.973,3.208-.973s1.572-.572,3.475-1.344v.193a.52.52,0,0,0,.52.52h.224a.52.52,0,0,0,.52-.52v-.224a.517.517,0,0,0-.2-.408c.382-.16.767-.326,1.149-.494v.178a.52.52,0,0,0,.52.52h.224a.52.52,0,0,0,.52-.52v-.224a.518.518,0,0,0-.222-.426c.412-.192.809-.384,1.175-.573,0,.017-.005.033-.005.05v.224a.52.52,0,0,0,.52.52h.224a.52.52,0,0,0,.52-.52v-.224a.52.52,0,0,0-.416-.509C490.946,614.354,491.5,613.9,491.426,613.593Z" transform="translate(-374.082 -476.96)" fill="#2f2e41"/>
      <path id="Path_1537-2" data-name="Path 1537" d="M598.884,616.984l-10.007-.632s.607,10.424-.606,13.9-.606,4.738-.606,4.738l8.794.632Z" transform="translate(-456.112 -483.193)" fill="#666bf1"/>
      <path id="Path_1538-2" data-name="Path 1538" d="M570.482,672.222c-.045-1.3-1.418-7.451-1.727-6.526s-5.581,1.416-7.039-.833c0,0-2.01-2.689-3.4-.724s-4.032,5.277-4.032,5.277-9.2,2.238-8.422,4.664c.157.491.81.823,1.752,1.041a.518.518,0,0,0-.072.172l-.047.219a.52.52,0,0,0,.4.617l.219.047a.52.52,0,0,0,.617-.4l.047-.219a.513.513,0,0,0-.007-.238c.257.032.526.058.8.08a.515.515,0,0,0-.178.29l-.047.219a.52.52,0,0,0,.4.617l.219.047a.52.52,0,0,0,.617-.4l.047-.219a.517.517,0,0,0-.169-.5c.736.033,1.511.04,2.284.03a.515.515,0,0,0-.13.246l-.047.219a.52.52,0,0,0,.4.617l.219.047a.52.52,0,0,0,.617-.4l.047-.219a.519.519,0,0,0-.2-.528c.582-.017,1.155-.042,1.7-.071a.514.514,0,0,0-.13.245l-.047.219a.52.52,0,0,0,.4.617l.219.047a.52.52,0,0,0,.617-.4l.047-.219a.518.518,0,0,0-.255-.559c1.923-.124,3.341-.283,3.341-.283s1.656-.232,3.678-.59l-.04.189a.52.52,0,0,0,.4.617l.219.047a.52.52,0,0,0,.617-.4l.047-.219a.517.517,0,0,0-.112-.441c.407-.077.818-.158,1.226-.243l-.037.174a.52.52,0,0,0,.4.617l.219.047a.52.52,0,0,0,.617-.4l.047-.219a.518.518,0,0,0-.129-.463c.443-.1.872-.207,1.269-.315a.462.462,0,0,0-.016.048l-.047.219a.52.52,0,0,0,.4.617l.219.047a.52.52,0,0,0,.617-.4l.047-.219a.52.52,0,0,0-.3-.585C569.854,672.866,570.493,672.537,570.482,672.222Z" transform="translate(-427.587 -515.394)" fill="#2f2e41"/>
      <circle id="Ellipse_107-2" data-name="Ellipse 107" cx="9.16" cy="9.16" r="9.16" transform="translate(117.977 4.601)" fill="#ffb8b8"/>
      <path id="Path_1539-2" data-name="Path 1539" d="M585.878,235.352s5.054,6.317,7.581,6L582.4,249.882s-1.263-5.37-2.843-6S585.878,235.352,585.878,235.352Z" transform="translate(-450.529 -222.539)" fill="#ffb8b8"/>
      <path id="Path_1540-2" data-name="Path 1540" d="M582.8,291.649s0-34.855-5.848-37.857-9.709-4.713-9.709-4.713h0a8.2,8.2,0,0,1-3.152,5.865c-1.938,1.5-3.868,2.736-4.946,2.74l-.035-.148-.131-.376-3.185,4.956s-2.843.632-3.79,2.527c-.513,1.025,1.9,11.347.474,20.689-1.214,7.926-6.33,15.081-6.475,15.951-.245,1.471,5.973-2.382,8.773-4.183.041.046.071.077.071.077s-1.579.632,1.579-.316a20.6,20.6,0,0,1,6.119-.492c0,.549.011.985.04,1.281.316,3.159,16.109,7.581,18.32,6.633S582.8,291.649,582.8,291.649Z" transform="translate(-427.705 -231.929)" fill="#2f2e41"/>
      <path id="Path_1541-2" data-name="Path 1541" d="M615.968,421.852s-9.476,8.528-3.159,10.108,8.844-8.844,8.844-8.844Z" transform="translate(-471.933 -350.129)" fill="#ffb8b8"/>
      <path id="Path_1542-2" data-name="Path 1542" d="M592.513,486.331s4.422-4.106,6.949-1.9,4.106,3.79,4.106,3.79S596.3,483.8,592.513,486.331Z" transform="translate(-459.533 -392.498)" fill="#6c63ff"/>
      <path id="Path_1543-2" data-name="Path 1543" d="M596.15,644.288s2.492-2.314,3.917-1.068,2.314,2.136,2.314,2.136S598.286,642.864,596.15,644.288Z" transform="translate(-462.021 -501.322)" fill="#2f2e41"/>
      <path id="Path_1544-2" data-name="Path 1544" d="M507.746,574.28s1.711-2.94,3.436-2.162,2.836,1.37,2.836,1.37S509.376,572.3,507.746,574.28Z" transform="translate(-401.541 -452.842)" fill="#2f2e41"/>
      <path id="Path_1545-2" data-name="Path 1545" d="M630.528,270.55c-3.131-6.294-12.658-2.861-11.11,4q.035.153.073.31a62.33,62.33,0,0,0,7.9,18.32l-3.475,15.478-3.79,7.9,7.9,1.9,9.792-23.69S635.11,279.76,630.528,270.55Z" transform="translate(-477.824 -244.354)" fill="#2f2e41"/>
      <path id="Path_1546-2" data-name="Path 1546" d="M456,156.065l-6,22.742,5.054-10.74Z" transform="translate(-307.86 -106.769)" opacity="0.2"/>
      <path id="Path_1547-2" data-name="Path 1547" d="M554.888,197.87s-5.063-5.331-10.16-1.974-7.514,6.618-7.015,8.889a32.963,32.963,0,0,0,1.979,5.429s-.919-4.7,3.3-5.052,10.051-3.141,10.051-3.141l3.944,8.422s.558-1.952,2.1-1.392S559.439,198.494,554.888,197.87Z" transform="translate(-421.999 -194.787)" fill="#2f2e41"/>
      <path id="Path_1550-2" data-name="Path 1550" d="M433.908,582.941h-6.366l-3.183-5.513,3.183-5.513h6.366l3.183,5.513Zm-6-.632h5.637l2.818-4.881-2.818-4.882h-5.637l-2.818,4.881Z" transform="translate(-344.493 -452.792)" fill="#fff"/>
    </g>
    <g id="Group_1064-2" data-name="Group 1064" transform="translate(76.429 114.416)">
      <g id="soccer_1_3" data-name="soccer (1)">
        <path id="Path_1038-3" data-name="Path 1038" d="M11.944,0A11.944,11.944,0,1,0,23.887,11.944,11.957,11.957,0,0,0,11.944,0Z" fill="#303c42"/>
        <g id="Group_316-3" data-name="Group 316" transform="translate(4.036 3.395)">
          <path id="Path_1039-3" data-name="Path 1039" d="M379.841,78.859l-2.928.419-2.195-3.95L376,72.762A10.956,10.956,0,0,1,379.841,78.859Z" transform="translate(-361.272 -72.762)" fill="#fff"/>
          <path id="Path_1040-3" data-name="Path 1040" d="M251.51,326.252l-2.791-1.594,1.661-3.322,3.345-.418,1.192,2.78Z" transform="translate(-241.151 -309.339)" fill="#fff"/>
          <path id="Path_1041-3" data-name="Path 1041" d="M93.095,252.85l-4.4.44L86.5,249.78l1.3-3.462,4.39-.439,2.633,3.51Z" transform="translate(-86.505 -237.802)" fill="#fff"/>
          <path id="Path_1042-3" data-name="Path 1042" d="M110.685,98.469l2.448,2.04-1.284,3.851-4.161.416-.417-4.174Z" transform="translate(-106.302 -97.27)" fill="#fff"/>
        </g>
        <path id="Path_1043-3" data-name="Path 1043" d="M231.332,146.725l-3.46.433-2.656-3.542,1.308-3.923,4.828-.439,2.188,3.939Z" transform="translate(-214.709 -132.757)" fill="#666bf1"/>
        <path id="Path_1044-3" data-name="Path 1044" d="M201.917,23.138l-1.351,2.7-4.993.454-2.6-2.17.466-2.5a10.815,10.815,0,0,1,8.481,1.512Z" transform="translate(-183.967 -20.338)" fill="#fff"/>
        <path id="Path_1045-3" data-name="Path 1045" d="M65.191,34.2,64.8,36.284l-3.674,2.3H59.572A10.991,10.991,0,0,1,65.191,34.2Z" transform="translate(-56.793 -32.608)" fill="#2ab300"/>
        <path id="Path_1046-3" data-name="Path 1046" d="M22.542,149.333h1.825l.441,4.413-1.3,3.456-1.9-.478a10.788,10.788,0,0,1,.932-7.391Z" transform="translate(-20.338 -142.366)" fill="#fff"/>
        <path id="Path_1047-3" data-name="Path 1047" d="M34,331.436l1.581.4,2.307,3.691v1.2A10.98,10.98,0,0,1,34,331.436Z" transform="translate(-32.417 -315.973)" fill="#ffda1a"/>
        <path id="Path_1048-3" data-name="Path 1048" d="M138.667,418.275v-1.552l4.37-.437,3.095,1.768v1.512a10.765,10.765,0,0,1-7.465-1.291Z" transform="translate(-132.198 -396.864)" fill="#fff"/>
        <path id="Path_1049-3" data-name="Path 1049" d="M320,399.327v-1.318l3.61-2.707,1.941.339A10.966,10.966,0,0,1,320,399.327Z" transform="translate(-305.07 -376.859)" fill="#5b5b5b"/>
        <path id="Path_1050-3" data-name="Path 1050" d="M320,399.327v-1.318l3.61-2.707,1.941.339A10.966,10.966,0,0,1,320,399.327Z" transform="translate(-305.07 -376.859)" fill="#2ab300" opacity="0.1"/>
        <path id="Path_1051-3" data-name="Path 1051" d="M378.312,231.864l-2.363-.412-1.284-3,2.228-3.564,3.074-.439a10.667,10.667,0,0,1-1.654,7.412Z" transform="translate(-357.185 -213.98)" fill="#fff"/>
        <path id="Path_1052-3" data-name="Path 1052" d="M146.132,430.78v-1.512l-1.924-1.1a10.537,10.537,0,0,1-5.54-.12v1.44a10.766,10.766,0,0,0,7.465,1.291Z" transform="translate(-132.198 -408.079)" opacity="0.1"/>
        <path id="Path_1053-3" data-name="Path 1053" d="M65.451,392.257a11.042,11.042,0,0,0,2.421,2.455V393.6A10.913,10.913,0,0,1,65.451,392.257Z" transform="translate(-62.397 -373.956)" opacity="0.1"/>
        <path id="Path_1054-3" data-name="Path 1054" d="M285.344,365.384l1.082.618,3.407-2.555-.358-.835A10.968,10.968,0,0,1,285.344,365.384Z" transform="translate(-272.031 -345.695)" opacity="0.1"/>
        <path id="Path_1055-3" data-name="Path 1055" d="M394.012,225.924a10.942,10.942,0,0,0-.111-1.472l-2.472.353a10.879,10.879,0,0,1-2.147,5.241l.6,1.405,2.363.412A10.875,10.875,0,0,0,394.012,225.924Z" transform="translate(-371.12 -213.98)" opacity="0.1"/>
        <path id="Path_1056-3" data-name="Path 1056" d="M404.153,78.86a10.956,10.956,0,0,0-3.84-6.1l-.093.186a10.831,10.831,0,0,1,1.732,5.875c0,.119-.014.235-.018.353Z" transform="translate(-381.548 -69.368)" opacity="0.1"/>
        <path id="Path_1057-3" data-name="Path 1057" d="M11.944,0A11.944,11.944,0,1,0,23.887,11.944,11.957,11.957,0,0,0,11.944,0Z" fill="url(#linear-gradient-2)"/>
      </g>
    </g>
  </g>
</g>
</svg>