import React from "react";
import People from "../../assets/images/testBear.png";

import "./helpSupporterBtn.scss";

const HelpSupporterBtn = () => {
  return (
    // <a
    //   href={`https://www.footballaroundme.com/fam/single-form/?url=${window.location.href}`}
    //   target="_blank"
    //   rel="noopener noreferrer"
    //   className="help-supporter-btn"
    // >
    //   <img className="help-supporter-img" src={People} alt="people" />
    //   <span>Click here to help us get your fan experience reviews</span>
    // </a>
    <div></div>
  );
};

export default HelpSupporterBtn;
