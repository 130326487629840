import React from "react";
import SupportersDetails from "../components/SupportersDetails";
import MatchDetails from "../components/MatchDetails";
import StadiumImages from "../components/StadiumImages";
import PubsSupporters from "../components/PubsSupporters";
import Statistics from "../components/Statistics";
import LineUp from "../components/LineUp";
import LiveStandings from "../components/LiveStandings/LiveStandings";
import Summary from "../components/Summary/Summary";
import WishlistTeams from "../pages/Wishlist/wishlistTeams";
import SelectedCompetition from "../components/FilterByLeagues/Addons/SelectedCompetition";
import SearchCompetition from "../components/FilterByLeagues/Addons/SearchCompetition";
// import Restaurants from "../components/Restaurants";

export const mainDetailsTabs = [
  { title: "Match Info", id: 1 },
  {
    title: "Fans Info",
    id: 2,
  },
  // { title: "Stadium Images", id: 3 },
];

export const mainDetailsComponents = {
  1: <MatchDetails type="home" />,
  2: <SupportersDetails />,
  // 3: <StadiumImages />,
};

export const competitionTabs = [
  { title: "Search Competition", id: 1,  },
  {
    title: "Selected Competition",
    id: 2,
  },
];
export const aboutTabs = [
  { title: "Who We Are ", id: 1 },
  {
    title: "Why We Built Football Around Me ",
    id: 2,
  },
];



export const wishlistDetailsTabs = [
  { title: "Wishlist", id: 1 },
  {
    title: "Journal",
    id: 2,
  },
];

export const wishListDetailsComponents = {
  1: <WishlistTeams />,
  2: "Journal",
};

export const subDetailsTabs = [
  { title: "Supporters Attractions", id: 1 },
  // { title: "Restaurants", id: 2 },
];

export const subDetailsComponents = {
  1: <PubsSupporters />,
  // 2: <Restaurants />,
};

export const liveDetailsTabs = [
  {
    title: "Match Details",
    id: 1,
  },
  { title: "Summary", id: 2 },
  { title: "Statistics", id: 3 },
  { title: "Line Up", id: 4 },
  { title: "Standings", id: 5 },
];

export const liveDetailsComponents = {
  1: <MatchDetails type="live" />,
  2: <Summary />,
  3: <Statistics />,
  4: <LineUp />,
  5: <LiveStandings />,
};
