import { call, takeLatest, put, all } from "redux-saga/effects";
import { FETCH_LEAGUES_START, CHECK_UPDATE_LEAGUES } from "./leaguesTypes";
import { getLeagues, updateLeagues } from "../../helpers/getLeagues";
import { fetchLeaguesSuccess, fetchLeaguesFailure } from "./leaguesActions";

function* fetchLeaguesAsync() {
  const { leagues, error } = yield call(getLeagues);

  if (leagues) yield put(fetchLeaguesSuccess(leagues));
  else yield put(fetchLeaguesFailure(error));
}

function* checkUpdateLeagues() {
  yield call(updateLeagues);
}

function* watchFetchLeagues() {
  yield takeLatest(FETCH_LEAGUES_START, fetchLeaguesAsync);
  yield takeLatest(CHECK_UPDATE_LEAGUES, checkUpdateLeagues);
}

export function* leaguesSagas() {
  yield all([call(watchFetchLeagues)]);
}
