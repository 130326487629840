import axios from "axios";
import { FAM_API } from "../constants";

export const getLeagues = async () => {
  try {
    const response = await axios.get(`${FAM_API}/leagues`);
    const leagues = response.data.data.leagues;
    return { leagues };
  } catch (error) {
    return { error: "Could not fetch leagues" };
  }
};

export const updateLeagues = async () => {
  try {
    const response = await axios.get(`${FAM_API}/leagues/update-leagues`);
    console.log(response);
    //const leagues = response.data.data.leagues;
  //  return { leagues };
  } catch (error) {
    return { error: "Could not fetch leagues" };
  }
};
