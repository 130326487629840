import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";

import { selectMarker } from "../../redux/map/mapSelectors";
import { fetchFixture } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import "./supporterInfoButton.scss";
import { selectAreFixturesDetailsFetched } from "../../redux/sidebar/sidebarSelectors";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { selectLiveMatch } from "../../redux/live/liveSelectors";
import {
  fetchLiveMatch,
  fetchIsMobileSidebarVisible
} from "../../redux/live/liveActions";

const SupporterInfoButton = ({
  selectedMarker,
  fetchFixture,
  fetchSidebarDetails,
  areFixturesDetailsFetched,
  width,
  fetchMapDetails,
  route,
  liveMatch,
  fetchLiveMatch,
  fetchIsMobileSidebarVisible
}) => {
  const handleClick = () => {
    if (route === "home") {
      if (width > 1000) {
        fetchFixture(selectedMarker);
        fetchSidebarDetails({
          areFixturesDetailsFetched: true
        });
      } else {
        const { lat, lng } = selectedMarker;
        fetchFixture(selectedMarker);
        fetchMapDetails({
          center: { lat, lng },
          marker: selectedMarker
        });

        fetchSidebarDetails({
          areFixturesDetailsFetched: true,
          areFixturesFetched: false
        });
      }
    } else {
      fetchLiveMatch(selectedMarker);
      fetchIsMobileSidebarVisible({
        isMobileSidebarVisible: true,
        isFormVisible: false
      });
    }
  };
  if (
    (selectedMarker && !areFixturesDetailsFetched && route === "home") ||
    (route !== "home" && width <= 1000 && liveMatch)
  ) {
    const marker = route === "home" ? selectedMarker : liveMatch;
    return (
      // <div onClick={handleClick} className="supporter-info-btn">
      //   <div className="supporter-info-team-comparison">
      //     <img
      //       className="supporter-info-btn-img"
      //       src={marker?.home_team?.logo}
      //       alt="logo"
      //     />
      //     VS
      //     <img
      //       className="supporter-info-btn-img"
      //       src={marker?.away_team?.logo}
      //       alt="logo"
      //     />
      //   </div>
      //   Supporter Info
      // </div>
      <div></div>
    );
  } else {
    return null;
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchFixture: (fixture) => dispatch(fetchFixture(fixture)),
  fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchLiveMatch: (payload) => dispatch(fetchLiveMatch(payload)),
  fetchIsMobileSidebarVisible: (payload) =>
    dispatch(fetchIsMobileSidebarVisible(payload))
});

const mapStateToProps = createStructuredSelector({
  selectedMarker: selectMarker,
  areFixturesDetailsFetched: selectAreFixturesDetailsFetched,
  width: selectWidth,
  liveMatch: selectLiveMatch
});

SupporterInfoButton.propTypes = {
  selectedMarker: PropTypes.object,
  fetchFixture: PropTypes.func,
  fetchSidebarDetails: PropTypes.func,
  areFixturesDetailsFetched: PropTypes.bool,
  width: PropTypes.number,
  fetchMapDetails: PropTypes.func,
  route: PropTypes.string,
  liveMatch: PropTypes.object,
  fetchLiveMatch: PropTypes.func,
  fetchIsMobileSidebarVisible: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupporterInfoButton);
