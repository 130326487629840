import React from 'react';

import './styles.scss';

const bc = 'tag';

class Tag extends React.Component {

    onClose = (item)=>{
        const { onClose } = this.props;
        
        onClose&&onClose(item);
    }
    render(){
        const { tags } = this.props;
        
        return (
            <div className={bc}>
                {
                    tags.map((item, index)=>{
                        return (
                            <div className={'tag-block'}  key={index} >
                                <div>
                                    {item.name}
                                </div>
                                <div className={'close'} onClick={()=>{this.onClose(item)}}>
                                    {'x'}
                                 </div>       
                            </div>    
                        )
                    })
                }
            </div>    
        )
    }
}

export default Tag;