import {
  FETCH_USER_LOCATION_START,
  FETCH_USER_LOCATION_SUCCESS,
  FETCH_USER_LOCATION_FAILURE,
  FETCH_USER_LOCATION,
} from "./userLocationTypes";

export const fetchUserLocationStart = () => ({
  type: FETCH_USER_LOCATION_START,
});

export const fetchUserLocation = (payload) => ({
  type: FETCH_USER_LOCATION,
  payload,
});

export const fetchUserLocationSuccess = (payload) => {
  return {
    type: FETCH_USER_LOCATION_SUCCESS,
    payload,
  };
};

export const fetchUserLocationFailure = (payload) => ({
  type: FETCH_USER_LOCATION_FAILURE,
  payload,
});
