import { FETCH_FORM_VALUES, SELECT_DAY } from "./formValuesTypes";
import moment from "moment";
import { getAllDatesBetween } from "../../helpers/getAllDates";
const currentDate = new Date();

    
const datesInitial = Object.keys(
  getAllDatesBetween(currentDate, new Date(moment(new Date()).add(6, "days")))
);

const INITIAL_STATE = {
  radius: 180,
  searchedLocation: null,
  dates: datesInitial,
  currentDate: datesInitial[0],
  countryCode: "",
  address: "",
  selectDay: datesInitial[0],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FORM_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case SELECT_DAY: {
      return { ...state, selectDay: action.payload };
    }
    default:
      return state;
  }
};
