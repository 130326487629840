export const colorSetter = (distance) => {
  if (distance <= 50) {
    return "#3CAEA3";
  }
  if (distance > 50 && distance <= 100) {
    return "#20639B";
  }

  if (distance > 100 && distance <= 200) {
    return "#F6D55C";
  }

  return "#ED553B";
};
