import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { RiCloseLine } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";

import { selectFavMatches } from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import LiveMatchesDates from "../LiveMatchesDates";
import {
  openDetailsSection,
  fetchLiveMatch,
  showOnlyLiveMatches,
} from "../../redux/live/liveActions";
import "./liveMatches.scss";
import Spinner from "../Spinner";
import {
  selectIsFetchingLiveMatches,
  selectAreLiveDetailsFetched,
  selectLiveMatchesDate,
  selectFixturesForLive,
  selectLiveMatches,
} from "../../redux/live/liveSelectors";
import LiveMatchesList from "../LiveMatchesList";
import { animated } from "react-spring";
import { useLiveListProps, useLiveDetailsProps } from "../../utils/getProps";
import LiveMatchDetails from "../LiveMatchDetails";
import { selectNormalizedLeaguesList } from "../../redux/leagues/leaguesSelectors";
import { showOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesActions";
import { selectShowOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import { selectInternalMap } from "../../redux/map/mapSelectors";
import { selectIsItFiltered } from "../../redux/filteringValues/filteringValuesSelectors";
import ShowLiveMatches from "../ShowLiveMatches";
import {
  getLiveMatches,
  animateCamera,
  searchLiveMatches,
  usePrevious,
} from "../../utils";
import ShowAllMatches from "../ShowAllMatches";
import SearchLeaguesInput from "../SearchLeaguesInput";

const LiveMatches = ({
  isFetchingLiveMatches,
  areLiveDetailsFetched,
  openDetailsSection,
  leagues,
  fixturesForLive,
  liveDate,
  liveMatches,
  showOnlyFav,
  onlyFavMatches,
  width,
  setFilterModal,
  fetchLiveMatch,
  refs,
  map,
  favMatches,
  itemRef,
  isItFiltered,
  showOnlyLiveMatches,
}) => {
  const [index, setIndex] = useState(1);
  const [inputVal, setInputVal] = useState("");

  const cond =
    fixturesForLive &&
    fixturesForLive[liveDate] &&
    Object.values(fixturesForLive[liveDate])
      .flat()
      .some((x) => favMatches.includes(x._id));

  const handleCloseBtn = () => {
    openDetailsSection(false);
    fetchLiveMatch(null);
    const markers = Object.values(fixturesForLive[liveDate]).flat();
    if (map) {
      animateCamera(markers, map);
    }
  };

  const liveMatchesList = searchLiveMatches(
    new Date(liveDate).getDate() === new Date().getDate()
      ? getLiveMatches(fixturesForLive[liveDate], liveMatches)
      : fixturesForLive[liveDate],
    inputVal
  );

  return (
    <div className="live-matches">
      {fixturesForLive && fixturesForLive[liveDate] && (
        <>
          {cond ? (
            <div
              onClick={() => {
                showOnlyFav(onlyFavMatches ? false : true);
                showOnlyLiveMatches({ onlyLiveMatches: false });
              }}
              className={`icon-container fav-container ${onlyFavMatches ? "yellow-bg" : "grey-bg"
                } ${!areLiveDetailsFetched && "slide-down"}`}
            >
              <AiFillStar color="white" size={19} />
            </div>
          ) : null}

          <div
            className="icon-container filter-btn-container"
            onClick={() => setFilterModal(true)}
          >
            <FiFilter color="white" size={20} />
          </div>
        </>
      )}

      {areLiveDetailsFetched && (
        <div
          onClick={() => handleCloseBtn()}
          className={`icon-container red-bg`}
        >
          <RiCloseLine color="white" size={25} />
        </div>
      )}
      <LiveMatchesDates />
      <div className={`${isFetchingLiveMatches && "hide-live"}`}>
        <animated.div style={useLiveListProps(areLiveDetailsFetched, width)}>
          {leagues && (
            <>
              {new Date().getDate() === new Date(liveDate).getDate() &&
                !isEmpty(liveMatches) &&
                !isItFiltered ? (
                  <ShowLiveMatches itemRef={itemRef} />
                ) : (
                  !isEmpty(fixturesForLive[liveDate]) &&
                  isItFiltered && (
                    <ShowAllMatches
                      itemRef={itemRef}
                      type="listButton"
                      route="live"
                    />
                  )
                )}

              {fixturesForLive && fixturesForLive[liveDate] && leagues ? (
                <div className="search-matches-input">
                  <SearchLeaguesInput
                    placeholder="Search by Team name / League name / Country name"
                    inputVal={inputVal}
                    setInputVal={setInputVal}
                  />
                </div>
              ) : null}

              <LiveMatchesList
                itemRef={itemRef}
                liveDate={liveDate}
                refs={refs}
                setIndex={setIndex}
                liveMatches={liveMatchesList}
                leagues={leagues}
              />
            </>
          )}
        </animated.div>
        <animated.div style={useLiveDetailsProps(areLiveDetailsFetched, width)}>
          {areLiveDetailsFetched && (
            <div
              className={`live-details-section ${areLiveDetailsFetched &&
                width >= 768 &&
                width <= 1024 &&
                "large-width"
                }`}
            >
              <LiveMatchDetails setIndex={setIndex} index={index} />
            </div>
          )}
        </animated.div>
      </div>
      <div
        className={`hide-spinner ${isFetchingLiveMatches && "show-spinner"}`}
      >
        <Spinner />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openDetailsSection: (payload) => dispatch(openDetailsSection(payload)),
  showOnlyFav: (payload) => dispatch(showOnlyFav(payload)),
  fetchLiveMatch: (payload) => dispatch(fetchLiveMatch(payload)),
  showOnlyLiveMatches: (payload) => dispatch(showOnlyLiveMatches(payload)),
});

const mapStateToProps = (state) => ({
  isFetchingLiveMatches: selectIsFetchingLiveMatches(state),
  areLiveDetailsFetched: selectAreLiveDetailsFetched(state),
  leagues: selectNormalizedLeaguesList(state),
  fixturesForLive: selectFixturesForLive(state),
  liveDate: selectLiveMatchesDate(state),
  liveMatches: selectLiveMatches(state),
  onlyFavMatches: selectShowOnlyFav(state),
  width: selectWidth(state),
  map: selectInternalMap(state),
  favMatches: selectFavMatches(state),
  isItFiltered: selectIsItFiltered(state),
});

LiveMatches.propTypes = {
  openDetailsSection: PropTypes.func,
  isFetchingLiveMatches: PropTypes.bool,
  areLiveDetailsFetched: PropTypes.bool,
  fetchLiveMatchesDetailsStart: PropTypes.func,
  leagues: PropTypes.object,
  fixturesForLive: PropTypes.object,
  liveDate: PropTypes.string,
  liveMatches: PropTypes.object,
  isItFiltered: PropTypes.bool,
  showOnlyFav: PropTypes.func,
  width: PropTypes.number,
  onlyFavMatches: PropTypes.bool,
  filterModal: PropTypes.bool,
  setFilterModal: PropTypes.func,
  fetchLiveMatch: PropTypes.func,
  refs: PropTypes.object,
  map: PropTypes.object,
  favMatches: PropTypes.array,
  itemRef: PropTypes.object,
  showOnlyLiveMatches: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveMatches);
