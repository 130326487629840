import React from "react";
import PropTypes from "prop-types";

import "./lineUpsPlayers.scss";

const LineUpsPlayers = ({ homeLineUp, awayLineUp }) => {
  return (
    <div className="line-ups-row">
      <div className="home-col">
        <span className="line-ups-row-number">{`${
          homeLineUp?.number || ""
        }`}</span>
        <span className="line-ups-row-player">{homeLineUp?.player || ""}</span>
      </div>
      <div className="away-col">
        <span className="line-ups-row-number">{`${
          awayLineUp?.number || ""
        }`}</span>
        <span className="line-ups-row-player">{awayLineUp?.player || ""}</span>
      </div>
    </div>
  );
};

LineUpsPlayers.propTypes = {
  homeLineUp: PropTypes.object,
  awayLineUp: PropTypes.object,
};

export default LineUpsPlayers;
