import { createSelector } from "reselect";

const selectCountries = (state) => state.countries;

export const selectCountriesList = createSelector(
  [selectCountries],
  (countries) => countries.countriesList
);

export const selectNormalizedCountriesList = createSelector(
  [selectCountriesList],
  (list) => {
    let normalizedList = {};

    for (var i = 0; i < list.length; i++)
      if (list[i].code in normalizedList)
        normalizedList[list[i].code] = list[i];
      else normalizedList[list[i].code] = list[i];
    return normalizedList;
  }
);
