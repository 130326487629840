import { createSelector } from "reselect";

const selectMap = (state) => state.map;

export const selectMarker = createSelector(selectMap, (map) => map.marker);
export const selectZoom = createSelector(selectMap, (map) => map.zoom);
export const selectMapCenter = createSelector(selectMap, (map) => map.center);
export const selectIsMobile = createSelector(selectMap, (map) => map.isMobile);
export const selectInternalMap = createSelector(
  selectMap,
  (map) => map.internalMap
);

export const selectCircle = createSelector(selectMap, (map) => map.circle);

export const selectMarkerSelectedOnHover = createSelector(
  selectMap,
  (map) => map.markerSelectedOnHover
);

export const selectMarkerSelected = createSelector(
  selectMap,
  (map) => map.markerSelected
);

export const selectAttractionMarkerSelected = createSelector(
  selectMap,
  (map) => map.attractionMarkerSelectedOnHover
);
