import React from "react";
import mail from "../../assets/images/mail.svg";
import user from "../../assets/images/user.svg";

const Step2 = ({ userInfo, setUserInfo }) => {
  return (
    <div className="third-stepper">
      <div className="third-stepper-left-section">
        <div className="input-email">
          <h4>Want latest updates and news regarding the App ?</h4>
          <input
            type="email"
            value={userInfo.user_email}
            name="user_email"
            onChange={(e) =>
              setUserInfo({ ...userInfo, user_email: e.target.value })
            }
            placeholder="Enter your email Address"
            id=""
          />
        </div>
        <img src={mail} alt="mail-icon" onError={(e) => e.target.src = mail} />
      </div>
      <div className="third-stepper-right-section">
        <div className="input-email">
          <h4>We will be happy to post your name next to the review. (optional)</h4>
          <input
            type="text"
            onChange={(e) =>
              setUserInfo({ ...userInfo, user_name: e.target.value })
            }
            value={userInfo.user_name}
            name="user_name"
            placeholder="Enter your name"
            id=""
          />
        </div>
        <img src={user} alt="user-icon" onError={(e) => e.target.src = user} />
      </div>
    </div>
  );
};

export default Step2;
