import React from "react";
import "./authright.scss";
import signInImg from "../../assets/images/signin-img.png";
export const AuthRight = ({
  title = "Title",
  subTitle = "",
}) => {
  return (
    <div className="sigin-right">
      <div className="img-text">
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </div>
      <img className="signin-img" src={signInImg} alt="signIn-Img" />
    </div>
  );
};
