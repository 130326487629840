export const searchLeagues = (leagues, inputVal) =>
  inputVal
    ? leagues.filter(
        ({ name, country }) =>
          name
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(inputVal.replace(/\s/g, "").toLowerCase()) ||
          country
            .replace(/\s/g, "")
            .toLowerCase()
            .includes(inputVal.replace(/\s/g, "").toLowerCase())
      )
    : leagues;
