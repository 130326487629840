import React from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import PropTypes from "prop-types";

import "./substituteRow.scss";

const SubstituteRow = ({ awayTeamEvent, homeTeamEvent }) => {
  return (
    <div className="subst-row">
      <div className="substitute">
        <span className="substitute-score">
          {homeTeamEvent?.elapsed}
          {homeTeamEvent && "'"}
        </span>
        <div className="substitute-details">
          <div>
            {homeTeamEvent && (
              <BsChevronDown
                className="substitute-icon"
                size={12}
                color="red"
              />
            )}
            <span>{homeTeamEvent?.assist}</span>
          </div>
          <div>
            {homeTeamEvent && (
              <BsChevronUp
                className="substitute-icon"
                size={12}
                color="green"
              />
            )}
            <span>{homeTeamEvent?.player}</span>
          </div>
        </div>
      </div>
      <div className="substitute">
        <span className="substitute-score">
          {awayTeamEvent?.elapsed}
          {awayTeamEvent && "'"}
        </span>
        <div className="substitute-details">
          <div>
            {awayTeamEvent && (
              <BsChevronDown
                className="substitute-icon"
                size={12}
                color="red"
              />
            )}
            <span>{awayTeamEvent?.assist}</span>
          </div>
          <div>
            {awayTeamEvent && (
              <BsChevronUp
                className="substitute-icon"
                size={12}
                color="green"
              />
            )}
            <span>{awayTeamEvent?.player}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

SubstituteRow.propTypes = {
  homeTeamEvent: PropTypes.object,
  awayTeamEvent: PropTypes.object
};

export default SubstituteRow;
