import React from "react";
import instagram from "../../assets/images/instagram.svg";
import footbalImg from "../../assets/images/football-player.png";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useHistory } from "react-router-dom";
const Step3 = ({ addReview }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };
  return (
    <div className="fourth-stepper">
      <h2>Thank You For Sharing Your Review</h2>
      {/* <p>We will review your review 🙂 and post it in stadium details.</p> */}
      <p>
        Your feedback helps fellow football fans enjoy the football culture even
        more. Our team is currently reviewing your review, and it will be posted
        on our app soon!
      </p>
      <button className="go-back-btn" onClick={goBack}>
        GO TO APPLICATION
      </button>
      {/* <p>Do you want to review another attraction</p> */}
      <button className="review-pub-btn" onClick={addReview}>
        <IoIosAddCircleOutline />
        REVIEW ANOTHER ATTRACTION
      </button>
      <img
        className="footbal-img"
        src={footbalImg}
        alt="footbal-img"
        onError={(e) =>
          (e.target.src =
            footbalImg)
        }
      />
      {/* <div className="review-text">
        <p>
          You are more then welcome to contact me for more question on intagram{" "}
        </p>
        <img
          src={instagram}
          alt="instagram-icon"
          onError={(e) =>
            (e.target.src =
              "https://media.api-sports.io/football/teams/14834.png")
          }
        />
      </div> */}
    </div>
  );
};
export default Step3;
