import { FETCH_REVIEWS, FETCH_REVIEW_DATA } from "./reviewTypes";

//ACTION
export const fetchReview = (payload) => ({
  type: FETCH_REVIEWS,
  payload,
});

//SAGA ACTION
export const fetchReviewData = (payload) => ({
    type: FETCH_REVIEW_DATA,
    payload,
  });