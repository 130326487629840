import moment from "moment";

export const getAllDatesBetween = (fromDate, toDate) => {
  let curDate = new Date(fromDate);
  const datesForCalendar = {};
  datesForCalendar[moment(fromDate).format("YYYY-MM-DD")] = {
    startingDay: true,
    color: "#555",
    textColor: "#ddd",
  };
  if (toDate) {
    while (
      moment(curDate).format("YYYY-MM-DD") < moment(toDate).format("YYYY-MM-DD")
    ) {
      curDate = new Date(curDate.setDate(curDate.getDate() + 1));
      datesForCalendar[moment(curDate).format("YYYY-MM-DD")] = {
        color: "#555",
        textColor: "#ddd",
      };
    }
    datesForCalendar[moment(toDate).format("YYYY-MM-DD")] = {
      endingDay: true,
      color: "#555",
      textColor: "#ddd",
    };
  }
  return datesForCalendar;
};
