import { createSelector } from "reselect";

const selectFavLiveMatches = (state) => state.favoriteLiveMatches;

export const selectFavMatches = createSelector(
  selectFavLiveMatches,
  (favoriteLiveMatches) => favoriteLiveMatches.favoriteMatches
);

export const selectShowOnlyFav = createSelector(
  selectFavLiveMatches,
  (favoriteLiveMatches) => favoriteLiveMatches.showOnlyFav
);
