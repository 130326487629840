import moment from "moment";

export const filterItemsByDistance = (fixtures) => {
  const grouped = fixtures.reduce((a, b) => {
    if (a[moment(b.event_date).format("DD MMM YYYY")]) {
      a[moment(b.event_date).format("DD MMM YYYY")].push(b);
    } else {
      a[moment(b.event_date).format("DD MMM YYYY")] = [];
      a[moment(b.event_date).format("DD MMM YYYY")].push(b);
    }
    return a;
  }, {});

  for (let key in grouped) {
    const arr = grouped[key];
    var result1 = [];
    var result2 = [];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].distance !== null) {
        result1.push(arr[i]);
      } else {
        result2.push(arr[i]);
      }
    }

    grouped[key] = result1.concat(result2);
  }

  return Object.values(grouped).flat();
};
