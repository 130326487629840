import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import leaguesReducer from "./leagues/leaguesReducer";
import userLocationReducer from "./userLocation/userLocationReducer";
import { countriesReducer } from "./countries/countriesReducer";
import fixturesReducer from "./fixtures/fixturesReducer";
import formValuesReducer from "./formValues/formValuesReducer";
import fixtureDetailsReducer from "./fixtureDetails/fixtureDetailsReducer";
import mapReducer from "./map/mapReducer";
import sidebarReducer from "./sidebar/sidebarReducer";
import sortingValuesReducer from "./sortingValues/sortingValuesReducer";
import filteringValuesReducer from "./filteringValues/filteringValuesReducer";
import screenDimensionsReducer from "./screenDimensions/screenDimensionsReducer";
import liveReducer from "./live/liveReducer";
import favoriteMatchesReducer from "./favoriteMatches/favoriteMatchesReducer";
import reviewReducer from "./reviews/reviewsReducer";
import attractionReducer from "./attractions/attractionReducer";
import authReducer from "./auth/authReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["favoriteLiveMatches"],
};

const rootReducer = combineReducers({
  leagues: leaguesReducer,
  userLocation: userLocationReducer,
  countries: countriesReducer,
  fixtures: fixturesReducer,
  formValues: formValuesReducer,
  fixtureDetails: fixtureDetailsReducer,
  map: mapReducer,
  sidebar: sidebarReducer,
  sortingValues: sortingValuesReducer,
  filteringValues: filteringValuesReducer,
  screenDimensions: screenDimensionsReducer,
  live: liveReducer,
  favoriteLiveMatches: favoriteMatchesReducer,
  reviews: reviewReducer,
  attraction: attractionReducer,
  user: authReducer,
});

export default persistReducer(persistConfig, rootReducer);
