export const countriesCoords = {
  ad: { lat: 42.5, lng: 1.5 },
  ae: { lat: 24, lng: 54 },
  af: { lat: 33, lng: 65 },
  ag: { lat: 17.05, lng: -61.8 },
  ai: { lat: 18.25, lng: -63.16666666 },
  al: { lat: 41, lng: 20 },
  am: { lat: 40, lng: 45 },
  ao: { lat: -12.5, lng: 18.5 },
  aq: { lat: -90, lng: 0 },
  ar: { lat: -34, lng: -64 },
  as: { lat: -14.33333333, lng: -170 },
  at: { lat: 47.33333333, lng: 13.33333333 },
  au: { lat: -27, lng: 133 },
  aw: { lat: 12.5, lng: -69.96666666 },
  ax: { lat: 60.116667, lng: 19.9 },
  az: { lat: 40.5, lng: 47.5 },
  ba: { lat: 44, lng: 18 },
  bb: { lat: 13.16666666, lng: -59.53333333 },
  bd: { lat: 24, lng: 90 },
  be: { lat: 50.83333333, lng: 4 },
  bf: { lat: 13, lng: -2 },
  bg: { lat: 43, lng: 25 },
  bh: { lat: 26, lng: 50.55 },
  bi: { lat: -3.5, lng: 30 },
  bj: { lat: 9.5, lng: 2.25 },
  bl: { lat: 18.5, lng: -63.41666666 },
  bm: { lat: 32.33333333, lng: -64.75 },
  bn: { lat: 4.5, lng: 114.66666666 },
  bo: { lat: -17, lng: -65 },
  br: { lat: -10, lng: -55 },
  bs: { lat: 24.25, lng: -76 },
  bt: { lat: 27.5, lng: 90.5 },
  bv: { lat: -54.43333333, lng: 3.4 },
  bw: { lat: -22, lng: 24 },
  by: { lat: 53, lng: 28 },
  bz: { lat: 17.25, lng: -88.75 },
  ca: { lat: 60, lng: -95 },
  cc: { lat: -12.5, lng: 96.83333333 },
  cd: { lat: 0, lng: 25 },
  cf: { lat: 7, lng: 21 },
  cg: { lat: -1, lng: 15 },
  ch: { lat: 47, lng: 8 },
  ci: { lat: 8, lng: -5 },
  ck: { lat: -21.23333333, lng: -159.76666666 },
  cl: { lat: -30, lng: -71 },
  cm: { lat: 6, lng: 12 },
  cn: { lat: 35, lng: 105 },
  co: { lat: 4, lng: -72 },
  cr: { lat: 10, lng: -84 },
  cu: { lat: 21.5, lng: -80 },
  cv: { lat: 16, lng: -24 },
  cw: { lat: 12.116667, lng: -68.933333 },
  cx: { lat: -10.5, lng: 105.66666666 },
  cy: { lat: 35, lng: 33 },
  cz: { lat: 49.75, lng: 15.5 },
  de: { lat: 51, lng: 9 },
  dj: { lat: 11.5, lng: 43 },
  dk: { lat: 56, lng: 10 },
  dm: { lat: 15.41666666, lng: -61.33333333 },
  do: { lat: 19, lng: -70.66666666 },
  dz: { lat: 28, lng: 3 },
  ec: { lat: -2, lng: -77.5 },
  ee: { lat: 59, lng: 26 },
  eg: { lat: 27, lng: 30 },
  eh: { lat: 24.5, lng: -13 },
  er: { lat: 15, lng: 39 },
  es: { lat: 40, lng: -4 },
  et: { lat: 8, lng: 38 },
  fi: { lat: 64, lng: 26 },
  fj: { lat: -18, lng: 175 },
  fk: { lat: -51.75, lng: -59 },
  fm: { lat: 6.91666666, lng: 158.25 },
  fo: { lat: 62, lng: -7 },
  fr: { lat: 46, lng: 2 },
  ga: { lat: -1, lng: 11.75 },
  gb: { lat: 54, lng: -2 },
  gd: { lat: 12.11666666, lng: -61.66666666 },
  ge: { lat: 42, lng: 43.5 },
  gf: { lat: 4, lng: -53 },
  gg: { lat: 49.46666666, lng: -2.58333333 },
  gh: { lat: 8, lng: -2 },
  gi: { lat: 36.13333333, lng: -5.35 },
  gl: { lat: 72, lng: -40 },
  gm: { lat: 13.46666666, lng: -16.56666666 },
  gn: { lat: 11, lng: -10 },
  gp: { lat: 16.25, lng: -61.583333 },
  gq: { lat: 2, lng: 10 },
  gr: { lat: 39, lng: 22 },
  gs: { lat: -54.5, lng: -37 },
  gt: { lat: 15.5, lng: -90.25 },
  gu: { lat: 13.46666666, lng: 144.78333333 },
  gw: { lat: 12, lng: -15 },
  gy: { lat: 5, lng: -59 },
  hk: { lat: 22.267, lng: 114.188 },
  hn: { lat: 15, lng: -86.5 },
  hr: { lat: 45.16666666, lng: 15.5 },
  ht: { lat: 19, lng: -72.41666666 },
  hu: { lat: 47, lng: 20 },
  id: { lat: -5, lng: 120 },
  ie: { lat: 53, lng: -8 },
  il: { lat: 31.47, lng: 35.13 },
  im: { lat: 54.25, lng: -4.5 },
  in: { lat: 20, lng: 77 },
  io: { lat: -6, lng: 71.5 },
  iq: { lat: 33, lng: 44 },
  ir: { lat: 32, lng: 53 },
  is: { lat: 65, lng: -18 },
  it: { lat: 42.83333333, lng: 12.83333333 },
  je: { lat: 49.25, lng: -2.16666666 },
  jm: { lat: 18.25, lng: -77.5 },
  jo: { lat: 31, lng: 36 },
  jp: { lat: 36, lng: 138 },
  ke: { lat: 1, lng: 38 },
  kg: { lat: 41, lng: 75 },
  kh: { lat: 13, lng: 105 },
  ki: { lat: 1.41666666, lng: 173 },
  km: { lat: -12.16666666, lng: 44.25 },
  kn: { lat: 17.33333333, lng: -62.75 },
  kp: { lat: 40, lng: 127 },
  kr: { lat: 37, lng: 127.5 },
  kw: { lat: 29.5, lng: 45.75 },
  ky: { lat: 19.5, lng: -80.5 },
  kz: { lat: 48, lng: 68 },
  la: { lat: 18, lng: 105 },
  lb: { lat: 33.83333333, lng: 35.83333333 },
  lc: { lat: 13.88333333, lng: -60.96666666 },
  li: { lat: 47.26666666, lng: 9.53333333 },
  lk: { lat: 7, lng: 81 },
  lr: { lat: 6.5, lng: -9.5 },
  ls: { lat: -29.5, lng: 28.5 },
  lt: { lat: 56, lng: 24 },
  lu: { lat: 49.75, lng: 6.16666666 },
  lv: { lat: 57, lng: 25 },
  ly: { lat: 25, lng: 17 },
  ma: { lat: 32, lng: -5 },
  mc: { lat: 43.73333333, lng: 7.4 },
  md: { lat: 47, lng: 29 },
  me: { lat: 42.5, lng: 19.3 },
  mf: { lat: 18.08333333, lng: -63.95 },
  mg: { lat: -20, lng: 47 },
  mh: { lat: 9, lng: 168 },
  mk: { lat: 41.83333333, lng: 22 },
  ml: { lat: 17, lng: -4 },
  mm: { lat: 22, lng: 98 },
  mn: { lat: 46, lng: 105 },
  mo: { lat: 22.16666666, lng: 113.55 },
  mp: { lat: 15.2, lng: 145.75 },
  mq: { lat: 14.666667, lng: -61 },
  mr: { lat: 20, lng: -12 },
  ms: { lat: 16.75, lng: -62.2 },
  mt: { lat: 35.83333333, lng: 14.58333333 },
  mu: { lat: -20.28333333, lng: 57.55 },
  mv: { lat: 3.25, lng: 73 },
  mw: { lat: -13.5, lng: 34 },
  mx: { lat: 23, lng: -102 },
  my: { lat: 2.5, lng: 112.5 },
  mz: { lat: -18.25, lng: 35 },
  na: { lat: -22, lng: 17 },
  nc: { lat: -21.5, lng: 165.5 },
  ne: { lat: 16, lng: 8 },
  nf: { lat: -29.03333333, lng: 167.95 },
  ng: { lat: 10, lng: 8 },
  ni: { lat: 13, lng: -85 },
  nl: { lat: 52.5, lng: 5.75 },
  no: { lat: 62, lng: 10 },
  np: { lat: 28, lng: 84 },
  nr: { lat: -0.53333333, lng: 166.91666666 },
  nu: { lat: -19.03333333, lng: -169.86666666 },
  nz: { lat: -41, lng: 174 },
  om: { lat: 21, lng: 57 },
  pa: { lat: 9, lng: -80 },
  pe: { lat: -10, lng: -76 },
  pf: { lat: -15, lng: -140 },
  pg: { lat: -6, lng: 147 },
  ph: { lat: 13, lng: 122 },
  pk: { lat: 30, lng: 70 },
  pl: { lat: 52, lng: 20 },
  pm: { lat: 46.83333333, lng: -56.33333333 },
  pn: { lat: -25.06666666, lng: -130.1 },
  pr: { lat: 18.25, lng: -66.5 },
  ps: { lat: 31.9, lng: 35.2 },
  pt: { lat: 39.5, lng: -8 },
  pw: { lat: 7.5, lng: 134.5 },
  py: { lat: -23, lng: -58 },
  qa: { lat: 25.5, lng: 51.25 },
  re: { lat: -21.15, lng: 55.5 },
  ro: { lat: 46, lng: 25 },
  rs: { lat: 44, lng: 21 },
  ru: { lat: 60, lng: 100 },
  rw: { lat: -2, lng: 30 },
  sa: { lat: 25, lng: 45 },
  sb: { lat: -8, lng: 159 },
  sc: { lat: -4.58333333, lng: 55.66666666 },
  sd: { lat: 15, lng: 30 },
  se: { lat: 62, lng: 15 },
  sg: { lat: 1.36666666, lng: 103.8 },
  si: { lat: 46.11666666, lng: 14.81666666 },
  sj: { lat: 78, lng: 20 },
  sk: { lat: 48.66666666, lng: 19.5 },
  sl: { lat: 8.5, lng: -11.5 },
  sm: { lat: 43.76666666, lng: 12.41666666 },
  sn: { lat: 14, lng: -14 },
  so: { lat: 10, lng: 49 },
  sr: { lat: 4, lng: -56 },
  ss: { lat: 7, lng: 30 },
  st: { lat: 1, lng: 7 },
  sv: { lat: 13.83333333, lng: -88.91666666 },
  sx: { lat: 18.033333, lng: -63.05 },
  sy: { lat: 35, lng: 38 },
  sz: { lat: -26.5, lng: 31.5 },
  tc: { lat: 21.75, lng: -71.58333333 },
  td: { lat: 15, lng: 19 },
  tf: { lat: -49.25, lng: 69.167 },
  tg: { lat: 8, lng: 1.16666666 },
  th: { lat: 15, lng: 100 },
  tj: { lat: 39, lng: 71 },
  tk: { lat: -9, lng: -172 },
  tl: { lat: -8.83333333, lng: 125.91666666 },
  tm: { lat: 40, lng: 60 },
  tn: { lat: 34, lng: 9 },
  to: { lat: -20, lng: -175 },
  tr: { lat: 39, lng: 35 },
  tt: { lat: 11, lng: -61 },
  tv: { lat: -8, lng: 178 },
  tw: { lat: 23.5, lng: 121 },
  tz: { lat: -6, lng: 35 },
  ua: { lat: 49, lng: 32 },
  ug: { lat: 1, lng: 32 },
  um: { lat: 19.2911437, lng: 166.618332 },
  us: { lat: 38, lng: -97 },
  uy: { lat: -33, lng: -56 },
  uz: { lat: 41, lng: 64 },
  va: { lat: 41.9, lng: 12.45 },
  vc: { lat: 13.25, lng: -61.2 },
  ve: { lat: 8, lng: -66 },
  vg: { lat: 18.431383, lng: -64.62305 },
  vi: { lat: 18.35, lng: -64.933333 },
  vn: { lat: 16.16666666, lng: 107.83333333 },
  vu: { lat: -16, lng: 167 },
  wf: { lat: -13.3, lng: -176.2 },
  ws: { lat: -13.58333333, lng: -172.33333333 },
  xk: { lat: 42.666667, lng: 21.166667 },
  ye: { lat: 15, lng: 48 },
  yt: { lat: -12.83333333, lng: 45.16666666 },
  za: { lat: -29, lng: 24 },
  zm: { lat: -15, lng: 30 },
  zw: { lat: -20, lng: 30 },
  europe: { lat: 48.1321146, lng: 4.1712031 },
  africa: { lat: 2.1941438, lng: 5.1939365 },
  asia: { lat: 23.8322302, lng: 62.543739 },
  americas: { lat: 64.072351, lng: -150.267272 },
  oceania: { lat: -8.6046882, lng: 91.4188935 },
  world: { lat: 44.2639148, lng: -44.3736498 },
};
