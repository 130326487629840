import axios from "axios";
import { FOOTBALL_API, HEADERS } from "../constants";

export const getLeagueTeams = async (league_id, season) => {
  try {
    const res = await axios.get(`${FOOTBALL_API}/teams`, {
      params: { league: `${league_id}`, season: season },
      headers: HEADERS,
    });
    const teamNames = res.data.response.reduce((a, c) => {
      if (a[c.team["id"]]) {
        a[c.team["id"]] = c;
      } else {
        a[c.team["id"]] = { ...c };
      }
      return a;
    }, {});
    if (!Object.values(teamNames).length) {
      throw new Error();
    }
    return teamNames;
  } catch (error) {
    return null;
  }
};
