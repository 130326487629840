import axios from "axios";
import { FAM_API } from "../constants";

export const getStadiumReview = async (id) => {
    try {
      const response = await axios.get(`${FAM_API}/get-stadium-reviews/${id}`).catch((error) => { console.log(error)});
      return response;
    } catch (error) {
        console.log(error);
    }
    
};

export const getPubsReview = async (id) => { 
    try {
      const response = axios.get(`${FAM_API}/supporter-reviews-stadium?stadium=${id}`).catch((error) => { console.log(error)});
      return response;
    } catch (error) {
      console.log(error);
    }
};