import React from "react";
import Error from "../../assets/images/error.png";

import "./errorMessage.scss";

const ErrorMessage = () => {
  return (
    <div className="error">
      <img className="error-img" src={Error} alt="error" />
    </div>
  );
};

export default ErrorMessage;
