import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import { logger, monitorReducerEnhancer } from "./middlewares";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

export default function configureStore(preloadedState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];

  if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  if (process.env.NODE_ENV === "development") {
    enhancers.push(monitorReducerEnhancer);
  }

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(
    rootReducer,
    { countries: { countriesList: preloadedState } },
    composedEnhancers
  );

  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
}
