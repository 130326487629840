import {
  FETCH_FIXTURES_BY_LOCATION_START,
  FETCH_FIXTURES_BY_COUNTRY_START,
  FETCH_FIXTURES_BY_LOCATION_SUCCESS,
  FETCH_FIXTURES_BY_LOCATION_FAILURE,
  FETCH_MATCHES_BY_LOCATION_START,
  FETCH_MATCHES_BY_COUNTRY_START,
  FETCH_MATCHES_BY_LOCATION_SUCCESS,
  FETCH_MATCHES_BY_LOCATION_FAILURE,
  FETCH_FIXTURES_BY_LEAGUES_START,
  FETCH_FIXTURES_BY_LEAGUES_SUCCESS,
  FETCH_FIXTURES_BY_LEAGUES_FAILURE,
  RESET_FIXTURES,
  MATCH_SPLIT_BY_DAY,
  SET_FIXTURES,
  SET_LEAGUES,
  SELECTED_MOBILE_FIXTURES,
  RESET_SELECTED_MOBILE_FIXTURES,
  FETCH_MATCHDAY_LOADERS,
  FETCH_ISCOUNTRY_SEARCH,
  CHECK_UPDATE_FIXTURES,
} from "./fixturesTypes";

export const checkUpdateFixtures = () => ({
  type: CHECK_UPDATE_FIXTURES,
});

export const fetchFixturesByLocationStart = (isAllCountries) => ({
  type: FETCH_FIXTURES_BY_LOCATION_START,
  payload: isAllCountries,
});

export const fetchFixturesByCountryStart = (isAllCountries) => ({
  type: FETCH_FIXTURES_BY_COUNTRY_START,
  payload: isAllCountries
});


export const fetchMatchdayLoaders = (allLoaders) => ({
  type: FETCH_MATCHDAY_LOADERS,
  payload: allLoaders,
});

export const fetchIsCountry = (isCountrySearch) => ({
  type: FETCH_ISCOUNTRY_SEARCH,
  payload: isCountrySearch,
});

export const fetchFixturesByLocationSuccess = (fixtures) => ({
  type: FETCH_FIXTURES_BY_LOCATION_SUCCESS,
  payload: fixtures,
});

export const fetchFixturesByLocationFailure = (err) => ({
  type: FETCH_FIXTURES_BY_LOCATION_FAILURE,
  payload: err,
});

export const fetchMatchesByLocationStart = (countries) => ({
  type: FETCH_MATCHES_BY_LOCATION_START,
  payload: countries,
});

export const fetchMatchesByCountryStart = (countries) => ({
  type: FETCH_MATCHES_BY_COUNTRY_START,
  payload: countries,
});

export const fetchMatchesByLocationSuccess = (fixtures) => ({
  type: FETCH_MATCHES_BY_LOCATION_SUCCESS,
  payload: fixtures,
});

export const fetchMatchesByLocationFailure = (err) => ({
  type: FETCH_MATCHES_BY_LOCATION_FAILURE,
  payload: err,
});

export const fetchFixturesByLeaguesStart = (payload) => ({
  type: FETCH_FIXTURES_BY_LEAGUES_START,
  payload,
});

export const fetchFixturesByLeaguesSuccess = (fixtures) => ({
  type: FETCH_FIXTURES_BY_LEAGUES_SUCCESS,
  payload: fixtures,
});

export const fetchFixturesByLeaguesFailure = (err) => ({
  type: FETCH_FIXTURES_BY_LEAGUES_FAILURE,
  payload: err,
});

export const resetFixtures = () => ({
  type: RESET_FIXTURES,
});

export const matchSlitByDay = (payload) => ({
  type: MATCH_SPLIT_BY_DAY,
  payload
});


export const setFixture = (payload)=>({
  type: SET_FIXTURES,
  payload
})

export const setLeagues = (payload)=>({
  type: SET_LEAGUES,
  payload
})

export const selectMobileFixture = (payload)=>({
  type: SELECTED_MOBILE_FIXTURES,
  payload
});

export const resetMobileFixture = ()=>({
  type: RESET_SELECTED_MOBILE_FIXTURES,  
})