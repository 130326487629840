import React, { useEffect } from "react";
import { Modal } from "react-responsive-modal";
import PropTypes from "prop-types";
import { RiCloseLine } from "react-icons/ri";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { FiPhoneCall } from "react-icons/fi";
import { GoLocation } from "react-icons/go";

import "./supportersDetails.scss";
// import ReviewsListItem from "../ReviewsListItem";
import { selectFixture } from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import ReviewsMap from "../ReviewsMap";
import Ratings from "../Ratings";
import { selectStadium } from "../../redux/reviews/reviewSelectors";

//ACTION
import { fetchReviewData } from '../../redux/reviews/reviewActions'

import { middleRate } from './SupportersDetails'

const bc = 'stadium-modal-review';

const StadiumModalReview = ({
  reviewsModal,
  setReviewsModal,
  fixture,
  stadiums
}) => {
  const { home_team, lat, lng, venue } = fixture;

  const renderStadiums = () =>{
    return stadiums.map((item, index)=>{
      const border = stadiums.length === index+1 ? {borderBottom: 'none'}: {}     
      
      return (
        <div key={stadiums?._id} className={'commend-block'} style={border}>
          <div>
            <div className='user-info'>
            <div className={'user-name'}>
              <div>{item.reviewer_name ? item.reviewer_name : 'Anonymous'}</div>              
            </div>
            <div className="reviews-modal-ratings">
                  <Ratings infoClass={'margin'}  size="12px" rating={middleRate(item)}/>
            </div>
            </div>
            <div>
              {
                item.about ?
                <div className={'about'}>
                  <div className='title'>Review :</div>
                  <div>{item.about}</div>
               </div> : null
              }              
            </div>  
            <div className={'stadium-rate'}>
              {item.food_expense?
                <div className="reviews-modal-ratings">
                  <div className='value-name'>Food Expense</div> <div><Ratings infoClass={'margin'} size="12px" rating={item.food_expense?item.food_expense:0}/></div>
                </div>
                : null
              }
              {
                item.food_quality?
                <div className="reviews-modal-ratings">
                  <div className='value-name'>Food Quality</div>  <div><Ratings infoClass={'margin'} size="12px" rating={item.food_quality?item.food_quality:0} /> </div>
                </div>
                : null 
              }
              {item.seat_view?
                <div className="reviews-modal-ratings">
                  <div className='value-name'>Seat View</div> <Ratings infoClass={'margin'} size="12px" rating={item.seat_view?item.seat_view:0} />
                </div>
                : null
              }
              {item.stadium_atmosphere?
                <div className="reviews-modal-ratings">
                  <div className='value-name'>Stadium Atmosphere</div><Ratings infoClass={'margin'} size="12px" rating={item.stadium_atmosphere ? item.stadium_atmosphere:0} />
                </div>
                : null
              }
              {item.stadium_equipment?
                <div className="reviews-modal-ratings" >
                  <div className='value-name' >Stadium Equipment</div><Ratings infoClass={'margin'} size="12px" rating={item?.stadium_equipment?item?.stadium_equipment:0} />
                </div> 
                : null 
              }                          
            </div> 
           </div> 
        </div>  
      )
    })
  }
  
  return (
    <Modal
      styles={{ modal: { padding: 0, borderRadius: 10 , overflow: 'hidden' } }}
      showCloseIcon={false}
      open={reviewsModal}
      onClose={() => setReviewsModal(false)}
      center
    >
      <div className="reviews-modal">
        <div className="reviews-modal-header">
          <RiCloseLine
            onClick={() => setReviewsModal(false)}
            className="reviews-modal-header-icon"
            size={25}
            color="#2e2e2e"
          />
          <span className="reviews-modal-header-title">{venue.name}</span>
          <img
            src={home_team.logo}
            alt="logo"
            className="reviews-modal-header-logo"
            onError={(e) => e.target.src = home_team.logo}
          />
        </div>
        <div className="reviews-modal-content">
          <div className="reviews-modal-map-container">
            <div className="inner-map-container">
              <ReviewsMap center={{ lat: lat, lng: lng }}  />
            </div>             
            </div>
            <div className={'comments'}>              
              {
                renderStadiums()
              }                           
            </div>            
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  fixture: selectFixture,
  stadiums: selectStadium
});

const mapDispatchToProps = {
  fetchReviewData
}

StadiumModalReview.propTypes = {
  supporter: PropTypes.object,
  reviewsModal: PropTypes.bool,
  setReviewsModal: PropTypes.func,
  fixture: PropTypes.object,
  width: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(StadiumModalReview);
