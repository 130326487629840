import React, { useState, useEffect } from "react";
import { animated } from "react-spring";
import moment from "moment";
import { connect } from "react-redux";
import { RiCloseLine } from "react-icons/ri";
import PropTypes from "prop-types";
import _ from "lodash";

import "./live.scss";
import { useMapContentWrapperProps } from "../../utils";
import MobileFixturesList from "../../components/MobileFixturesList";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import {
  selectFixturesFetching,
  selectFixturesError
} from "../../redux/fixtures/fixturesSelectors";
import LiveMatches from "../../components/LiveMatches";
import {
  selectAreLiveDetailsFetched,
  selectIsMobileSidebarVisible,
  selectIsFormVisible,
  selectLiveMatchesDate,
  selectFixturesForLive,
  selectLiveMatches
} from "../../redux/live/liveSelectors";
import LiveMatchDetails from "../../components/LiveMatchDetails";
import MatchesNotFound from "../../components/MatchesNotFound";
import SearchForm from "../../components/SearchForm";
import {
  fetchLiveMatchesStart,
  fetchIsMobileSidebarVisible,
  fetchLiveDates,
  resetLive,
  fetchFixturesForLiveStart,
  fetchLiveMatchesDate,
  liveKeepFetching,
  openDetailsSection,
  resetLiveModals
} from "../../redux/live/liveActions";
import { fetchFixturesByLocationFailure } from "../../redux/fixtures/fixturesActions";
import { getAllDatesBetween } from "../../helpers/getAllDates";
import { selectNormalizedLeaguesList } from "../../redux/leagues/leaguesSelectors";
import FilterModal from "../../components/FilterModal";
import { resetMapDetails } from "../../redux/map/mapActions";
// import { FaCoins } from "react-icons/fa";
// import Spinner from "../../components/Spinner";
import Maps from "../../components/Maps";
import { resetFilteringValues } from "../../redux/filteringValues/filteringValuesActions";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import { GApageView } from "../../utils/GAPageView";
// const Maps = lazy(() => retry(() => import("../../components/Maps")));

const Live = ({
  width,
  areLiveDetailsFetched,
  isFetchingFixtures,
  isMobileSidebarVisible,
  isFormVisible,
  error,
  fetchLiveMatchesStart,
  fetchLiveDates,
  fetchIsMobileSidebarVisible,
  liveMatches,
  fetchFixturesForLiveStart,
  liveDate,
  fetchLiveMatchesDate,
  fixturesForLive,
  normalizedLeaguesList,
  liveKeepFetching,
  userLocation,
  resetFilteringValues,
  resetMapDetails,
  openDetailsSection
}) => {
  const [filterModal, setFilterModal] = useState(false);
  const [index, setIndex] = useState(1);
  const itemRef = React.createRef();
  const refs =
    fixturesForLive &&
    fixturesForLive[liveDate] &&
    Object.values(fixturesForLive[liveDate])
      .flat()
      .reduce((acc, value) => {
        acc[value.fixture_id] = React.createRef();
        return acc;
      }, {});

  const onMarkerClick = (fixture_id) => {
    if (width <= 1000) {
      const myElement = document.getElementById(`item-${fixture_id}`);
      var topPos = myElement.offsetTop;
      document.getElementById("snap-point").scrollTop = topPos - 67;
    } else {
      refs[fixture_id].current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      });
    }
  };

  useEffect(() => {
    GApageView("Live Matches - Web");
    const startDate = moment(new Date()).subtract(4, "days");
    const endDate = moment(new Date()).add(4, "days");
    const datesArr = Object.keys(getAllDatesBetween(startDate, endDate));
    fetchLiveDates({ dates: datesArr });
    fetchLiveMatchesDate(datesArr[4]);
  }, []);

  useEffect(() => {
    if (!fixturesForLive[liveDate] && normalizedLeaguesList && userLocation) {
      const date = moment(new Date()).format("YYYY-MM-DD");
      fetchFixturesForLiveStart(liveDate || date);
    }
  }, [liveDate, normalizedLeaguesList, userLocation]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchLiveMatchesStart(fixturesForLive);
      liveKeepFetching(true);
    }, 60000);
    return () => {
      clearInterval(interval);
      resetMapDetails();
      openDetailsSection(false);
      resetFilteringValues();
    };
  }, []);

  const markers =
    liveDate && new Date(liveDate).getDate() === new Date().getDate()
      ? fixturesForLive[liveDate] &&
      _.uniqBy(
        [
          ...Object.values(liveMatches || {}).flat(),
          ...Object.values(fixturesForLive[liveDate]).flat()
        ],
        "fixture_id"
      )
      : fixturesForLive &&
      fixturesForLive[liveDate] &&
      Object.values(fixturesForLive[liveDate]).flat();

  return (
    // <Suspense fallback={<Spinner />}>
    <div className="live">
      <animated.div
        style={useMapContentWrapperProps(
          width,
          areLiveDetailsFetched,
          isMobileSidebarVisible,
          "live"
        )}
        className={`mapContent-wrapper`}
      >
        {width <= 1000 && (
          <RiCloseLine
            className="mobile-close-content"
            onClick={() => {
              fetchIsMobileSidebarVisible({
                isMobileSidebarVisible: false,
                isFormVisible: false
              });
            }}
            color="#2e2e2e"
            size={28}
          />
        )}

        {width <= 1000 && (
          <div
            className={`live-content ${isMobileSidebarVisible && isFormVisible && "live-content-gap"
              }`}
          >
            {isMobileSidebarVisible ? (
              isFormVisible ? (
                !error ? (
                  <SearchForm />
                ) : (
                    <MatchesNotFound />
                  )
              ) : (
                  <LiveMatchDetails index={index} setIndex={setIndex} />
                )
            ) : null}
          </div>
        )}

        {width > 1000 && (
          <LiveMatches
            refs={refs}
            setFilterModal={setFilterModal}
            filterModal={filterModal}
            itemRef={itemRef}
          />
        )}
      </animated.div>
      <div
        className={`maps-wrapper ${isFetchingFixtures && "map-overlay"} ${isFetchingFixtures && "map"
          }`}
      >
        <Maps
          onMarkerClick={onMarkerClick}
          itemRef={itemRef}
          type="live"
          setFilterModal={setFilterModal}
          markers={markers}
        />
      </div>

      {width <= 1000 && (
        <div
          className={`mobile-fixtures-list-wrapper ${isMobileSidebarVisible ? "hide" : ""
            }`}
        >
          <MobileFixturesList setFilterModal={setFilterModal} type="live" />
        </div>
      )}
      <FilterModal
        type="live"
        filterModal={filterModal}
        setFilterModal={setFilterModal}
      />
    </div>
    // </Suspense>
  );
};

const mapStateToProps = (state) => ({
  areLiveDetailsFetched: selectAreLiveDetailsFetched(state),
  width: selectWidth(state),
  isFetchingFixtures: selectFixturesFetching(state),
  isMobileSidebarVisible: selectIsMobileSidebarVisible(state),
  isFormVisible: selectIsFormVisible(state),
  error: selectFixturesError(state),
  userLocation: selectUserLocationCoords(state),
  liveDate: selectLiveMatchesDate(state),
  liveMatches: selectLiveMatches(state),
  fixturesForLive: selectFixturesForLive(state),
  normalizedLeaguesList: selectNormalizedLeaguesList(state)
});

Live.propTypes = {
  areLiveDetailsFetched: PropTypes.bool,
  width: PropTypes.number,
  isFetchingFixtures: PropTypes.bool,
  isMobileSidebarVisible: PropTypes.bool,
  userLocation: PropTypes.object,
  isFormVisible: PropTypes.bool,
  resetFilteringValues: PropTypes.func,
  error: PropTypes.any,
  fetchLiveMatchesStart: PropTypes.func,
  fetchFixturesByLocationFailure: PropTypes.func,
  fetchIsMobileSidebarVisible: PropTypes.func,
  fetchLiveDates: PropTypes.func,
  resetLive: PropTypes.func,
  liveMatches: PropTypes.object,
  fetchFixturesForLiveStart: PropTypes.func,
  liveDate: PropTypes.object || PropTypes.string,
  fixturesForLive: PropTypes.object,
  fetchLiveMatchesDate: PropTypes.func,
  normalizedLeaguesList: PropTypes.object,
  liveKeepFetching: PropTypes.func,
  openDetailsSection: PropTypes.func,
  resetMapDetails: PropTypes.func,
  resetLiveModals: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  fetchLiveMatchesStart: (payload) => dispatch(fetchLiveMatchesStart(payload)),
  fetchFixturesByLocationFailure: (payload) =>
    dispatch(fetchFixturesByLocationFailure(payload)),
  fetchIsMobileSidebarVisible: (payload) =>
    dispatch(fetchIsMobileSidebarVisible(payload)),
  resetFilteringValues: () => dispatch(resetFilteringValues()),
  fetchLiveDates: (payload) => dispatch(fetchLiveDates(payload)),
  resetLive: () => dispatch(resetLive()),
  fetchFixturesForLiveStart: (payload) =>
    dispatch(fetchFixturesForLiveStart(payload)),
  fetchLiveMatchesDate: (payload) => dispatch(fetchLiveMatchesDate(payload)),
  liveKeepFetching: (payload) => dispatch(liveKeepFetching(payload)),
  resetMapDetails: () => dispatch(resetMapDetails()),
  openDetailsSection: () => dispatch(openDetailsSection()),
  resetLiveModals: () => dispatch(resetLiveModals())
});

export default connect(mapStateToProps, mapDispatchToProps)(Live);
