import React, { useState, useRef, useEffect } from "react";
import Select, { components } from "react-select";
import "./MultiSelect.scss";
import { getTeamSearch } from "../../helpers/getTeamSearch";
import { getStadiumSearch } from "../../helpers/getStadiumSearch";
import { FaSearch, FaChevronDown } from "react-icons/fa"; // Import icons
import Mixpanel from "../../utils/mixPanel";

const MultiSelectComponent = ({
  placeholderText = "",
  searchBy = "team",
  selectedOptions,
  setSelectedOptions,
  isSetting,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(""); // Track input value
  const [loading, setLoading] = useState(false); // Track loading state
  const debounceTimeout = useRef(null);
  const platform = typeof window !== "undefined" ? "web" : "mobile";
  const fetchOptions = async (query) => {
    try {
      setLoading(true); // Set loading state when fetching
      let response;
      if (searchBy === "team") {
        Mixpanel.track(`Searched matches`,    {
          platform: platform,
          application_type:
            platform === "web" ? "web app" : "mobile app",
        });
        response = await getTeamSearch(query);
      } else if (searchBy === "stadium") {
        Mixpanel.track(`Searched matches`,    {
          platform: platform,
          application_type:
            platform === "web" ? "web app" : "mobile app",
        });
        response = await getStadiumSearch(query);
      }

      if (response) {
        setOptions(
          response.map((res) => ({
            label: res?.team?.name || res?.name,
            value: isSetting ? res._id : res.id,
            flagUrl: res?.team?.logo || res?.image, // Assuming the flag URL is part of the response
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const debounceFetchOptions = (inputValue) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (inputValue) {
        fetchOptions(inputValue);
      }
    }, 600);
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue); // Update input value
    debounceFetchOptions(inputValue);
  };

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    if (searchBy) {
      setOptions([]);
    }
  }, [searchBy]);

  useEffect(() => {
    if (!inputValue) {
      setOptions([]);
    }
  }, [inputValue]);

  const formatOptionLabel = ({ label, flagUrl }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={flagUrl}
        alt=""
        style={{ width: 20, height: 20, borderRadius: 50, marginRight: 10 }}
      />
      {label}
    </div>
  );

  // Custom dropdown indicator component
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {!inputValue && options.length === 0 ? <FaSearch /> : <FaChevronDown />}
      </components.DropdownIndicator>
    );
  };

  // Custom message when no options are available
  const noOptionsMessage = () => {
    console.log("noOptionsMessage", loading, inputValue, options);

    if (!loading && inputValue && options.length === 0) {
      return "No options found";
    }

    if (loading && inputValue) {
      return "Searching...";
    }
    return null;
  };

  return (
    <div className="multi-select-container">
      <Select
        isMulti
        name="search"
        placeholder={placeholderText}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        value={selectedOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        formatOptionLabel={formatOptionLabel}
        components={
          isSetting
            ? {
                DropdownIndicator,
                MultiValue: () => null,
                MultiValueRemove: () => null,
              }
            : {
                DropdownIndicator,
              }
        }
        noOptionsMessage={noOptionsMessage} // Use custom no options message
      />
    </div>
  );
};

export default MultiSelectComponent;
