export const setCookie = (name, value, hours) => {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000); // Convert hours to milliseconds
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value}; ${expires}; path=/`;
};

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let c = cookies[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const clearCookies = () => {
  var cookies = document.cookie.split(";");

  var currentDomain = window.location.hostname;
  var currentPath = window.location.pathname;

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // Clear cookie for the current path
    document.cookie =
      name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=" + currentPath;

    // Clear cookie for the root path
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";

    // Optionally, clear cookie for the current domain
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" +
      currentDomain;
  }
};
