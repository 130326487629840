export function move(arr) {
  const indexes = [];

  for (let i = 0; i < arr.length; i++) {
    if (!arr[i].distance) {
      indexes.push(i);
    }
  }

  indexes.forEach((x) => {
    const item = arr.splice(x, 1);
    arr.splice(arr.length, 0, item[0]);
  });

  return arr;
}
