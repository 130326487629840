import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";

const Tooltip = ({ children, content = "" }: any) => {
  const hideTooltip = () => {
    ReactTooltip.hide();
  };

  useEffect(() => {
    document.addEventListener("click", hideTooltip);
    return () => {
      document.removeEventListener("click", hideTooltip);
    };
  }, []);

  return (
    <>
      <a data-tip={content} onClick={hideTooltip}>
        {children}
      </a>
      <ReactTooltip place="right" type="light" effect="solid" />
    </>
  );
};

export default Tooltip;
