import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import Scrollbars from "react-custom-scrollbars";

import {
  selectSupporters,
  selectIsFetchingFixtureDetails,
  selectFixture
} from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import SupporterCard from "../SupporterCard";
import { SUPPORTER } from "../../utils/mockSupporter";
import { fetchSupportersDetailsStart } from "../../redux/fixtureDetails/fixtureDetailsActions";
import Spinner from "../Spinner";
import ReviewCard from "../ReviewCard";

const PubsSupporters = ({
  supporters,
  isFetching,
  fetchSupportersDetailsStart,
  fixture
}) => {
  const supportersList = supporters
    ? supporters.filter((x) => x.type.toLowerCase() === "pub")
    : [];

  useEffect(() => {
    if (!supporters) {
      fetchSupportersDetailsStart("home");
    }
  }, [fixture]);
  
  return (
    <Scrollbars autoHide style={{}}>
      {isFetching && <Spinner styles={{ marginTop: 50 }} />}
      {!isFetching ? (
        <div>
          {supportersList.length ? (
            supportersList.map((supporter, idx) => {
              return (
                <>
                  <SupporterCard
                    key={idx}
                    supporter={supporter}
                    index={idx}
                    type="pubsSupporters"
                  />                  
                </>
              );
            })
          ) : (
            <div className="review-card-wrapper">
              <ReviewCard styles={{ width: "95%", height: 140 }} />
            </div>
          )}         
        </div>
      ) : null}
    </Scrollbars>
  );
};

const mapStateToProps = createStructuredSelector({
  supporters: selectSupporters,
  isFetching: selectIsFetchingFixtureDetails,
  fixture: selectFixture
});

const mapDispatchToProps = (dispatch) => ({
  fetchSupportersDetailsStart: (payload) =>
    dispatch(fetchSupportersDetailsStart(payload))
});

PubsSupporters.propTypes = {
  supporters: PropTypes.array,
  isFetching: PropTypes.bool,
  fetchSupportersDetailsStart: PropTypes.func,
  fixture: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(PubsSupporters);
