import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { IoIosFootball } from "react-icons/io";

import "./summary.scss";
import {
  selectLiveMatch,
  selectLiveMatchesDate,
} from "../../redux/live/liveSelectors";
import { trimString } from "../../helpers/trimString";
import {
  selectEvents,
  selectIsFetchingEvents,
} from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import { fetchSummaryStart } from "../../redux/fixtureDetails/fixtureDetailsActions";
import Spinner from "../Spinner";

const Summary = ({
  separatedEvents,
  liveMatch,
  isFetchingEvents,
  liveDate,
  fetchSummaryStart,
}) => {
  const eventsList =
    liveMatch.events && liveMatch.events.length
      ? liveMatch.events
      : separatedEvents;
  const condition = eventsList && eventsList.some((x) => x.type !== "subst");

  let score = {
    [liveMatch?.home_team.team_id]: 0,
    [liveMatch?.away_team.team_id]: 0,
  };

  useEffect(() => {
    let interval;
    const cond = eventsList && eventsList.some((x) => x.type !== "subst");
    if (
      new Date(liveDate).getDate() === new Date().getDate() &&
      "events" in liveMatch
    ) {
      interval = setInterval(() => {
        fetchSummaryStart();
      }, 60000);
    }
    if (!cond) {
      fetchSummaryStart();
    }

    return () => {
      clearInterval(interval);
    };
  }, [liveMatch]);

  const getScores = (type, team_id, detail) => {
    const cond =
      (type === "Goal" && detail === "Normal Goal") || detail === "Penalty";
    if (liveMatch?.statusShort === "NS") return "- : -";

    score = {
      ...score,
      [team_id]: cond ? score[team_id] + 1 : score[team_id],
    };

    return `${score[liveMatch?.home_team.team_id]} - ${
      score[liveMatch?.away_team.team_id]
    }`;
  };

  return (
    <div className="summary">
      {isFetchingEvents && !liveMatch.events ? (
        <div className="summary-spinner">
          <Spinner />
        </div>
      ) : condition ? (
        <>
          <div className="summary-details-title">SUMMARY</div>
          <div className="lastFiveMatches-header">
            <div className="lastFiveMatches-teamHeader">
              <img
                alt="logo"
                className="lastFiveMatches-teamHeaderLogo"
                src={liveMatch?.home_team?.logo || liveMatch?.home_team.logo}
                onError={(e) => e.target.src = liveMatch?.home_team?.logo || liveMatch?.home_team.logo}
              />
              <span className="lastFiveMatches-teamHeaderName">
                {trimString(liveMatch?.home_team.name, 15)}
              </span>
            </div>
            <div className="lastFiveMatches-teamHeader">
              <img
                className="lastFiveMatches-teamHeaderLogo"
                src={liveMatch?.away_team?.logo || liveMatch?.away_team.logo}
                alt="logo"
                onError={(e) => e.target.src = liveMatch?.away_team?.logo || liveMatch?.away_team.logo}
              />
              <span className="lastFiveMatches-teamHeaderName">
                {trimString(liveMatch?.away_team.name, 15)}
              </span>
            </div>
          </div>
          {eventsList.map(({ type, team_id, player, detail, elapsed }, idx) => {
            if (type !== "subst") {
              return (
                <div
                  className={`summary-row-container ${
                    idx === 0 && "top-border"
                  }`}
                >
                  <span className="event-time">{elapsed}&rsquo;</span>
                  <div key={idx} className={`summary-row`}>
                    <div className="home-team">
                      {liveMatch?.home_team.team_id === team_id && (
                        <>
                          <span>{player || "N/A"}</span>
                          {type === "Goal" ? (
                            <>
                              <IoIosFootball size={14} />
                              {detail === "Missed Penalty" && " (MP)"}
                            </>
                          ) : (
                            <span
                              className={`card ${
                                detail === "Red Card"
                                  ? "red-card"
                                  : "yellow-card"
                              }`}
                            ></span>
                          )}
                        </>
                      )}
                    </div>
                    <div className="middle-score">
                      {getScores(type, team_id, detail)}
                    </div>
                    <div className="away-team">
                      {liveMatch?.away_team.team_id === team_id && (
                        <>
                          {type === "Goal" ? (
                            <>
                              {detail === "Missed Penalty" && "(MP) "}
                              <IoIosFootball size={14} />
                            </>
                          ) : (
                            <span
                              className={`card ${
                                detail === "Red Card"
                                  ? "red-card"
                                  : "yellow-card"
                              }`}
                            ></span>
                          )}
                          <span>{player || "N/A"}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </>
      ) : (
        <div className="no-summary-message">
          This match has no statistics for now.
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  fetchSummaryStart,
};
const mapStateToProps = createStructuredSelector({
  liveMatch: selectLiveMatch,
  separatedEvents: selectEvents,
  liveDate: selectLiveMatchesDate,
  isFetchingEvents: selectIsFetchingEvents,
});

Summary.propTypes = {
  separatedEvents: PropTypes.array,
  liveMatch: PropTypes.object,
  liveDate: PropTypes.string,
  isFetchingEvents: PropTypes.bool,
  fetchSummaryStart: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
