import { FETCH_SIDEBAR_DETAILS, RESET_SIDEBAR_DETAILS } from "./sidebarTypes";

export const fetchSidebarDetails = (payload) => ({
  type: FETCH_SIDEBAR_DETAILS,
  payload,
});

export const resetSidebarDetails = () => ({
  type: RESET_SIDEBAR_DETAILS,
});
