export const FETCH_FIXTURES_BY_LOCATION_START =
  "FETCH_FIXTURES_BY_LOCATION_START";

export const FETCH_FIXTURES_BY_COUNTRY_START  =
  "FETCH_FIXTURES_BY_COUNTRY_START"; 

export const FETCH_MATCHDAY_LOADERS = 
  "FETCH_MATCHDAY_LOADERS";

export const FETCH_ISCOUNTRY_SEARCH = 
  "FETCH_ISCOUNTRY_SEARCH"; 

export const FETCH_FIXTURES_BY_LOCATION_SUCCESS =
  "FETCH_FIXTURES_BY_LOCATION_SUCCESS";
export const FETCH_FIXTURES_BY_LOCATION_FAILURE =
  "FETCH_FIXTURES_BY_LOCATION_FAILURE";

export const FETCH_MATCHES_BY_LOCATION_START =
  "FETCH_MATCHES_BY_LOCATION_START";

export const FETCH_MATCHES_BY_COUNTRY_START = 
  "FETCH_MATCHES_BY_COUNTRY_START";  
export const FETCH_MATCHES_BY_LOCATION_SUCCESS =
  "FETCH_MATCHES_BY_LOCATION_SUCCESS";
export const FETCH_MATCHES_BY_LOCATION_FAILURE =
  "FETCH_MATCHES_BY_LOCATION_FAILURE";

export const FETCH_FIXTURES_BY_LEAGUES_START =
  "FETCH_FIXTURES_BY_LEAGUES_START";
export const FETCH_FIXTURES_BY_LEAGUES_SUCCESS =
  "FETCH_FIXTURES_BY_LEAGUES_SUCCESS";
export const FETCH_FIXTURES_BY_LEAGUES_FAILURE =
  "FETCH_FIXTURES_BY_LEAGUES_FAILURE";
export const RESET_FIXTURES = "RESET_FIXTURES";
export const CHECK_UPDATE_FIXTURES = "CHECK_UPDATE_FIXTURES";

export const MATCH_SPLIT_BY_DAY = 'MATCH_SPLIT_BY_DAY';
export const SET_FIXTURES = 'SET_FIXTURES';
export const SET_LEAGUES = 'SET_LEAGUES';
export const SELECTED_MOBILE_FIXTURES = 'SELECTED_MOBILE_FIXTURES';
export const RESET_SELECTED_MOBILE_FIXTURES = 'RESET_SELECTED_MOBILE_FIXTURES';