import {
  FETCH_USER_LOCATION_START,
  FETCH_USER_LOCATION_SUCCESS,
  FETCH_USER_LOCATION_FAILURE,
  FETCH_USER_LOCATION,
} from "./userLocationTypes";

const INITIAL_STATE = {
  // coordinates: { lat: 51.5073509, lng: -0.1277583 },
  // isFetching: false,
  // countryCode: "GB",
  // address: "London, UK",
  // error: null
  coordinates: null,
  isFetching: false,
  countryCode: "",
  address: "",
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_LOCATION_START:
      return { ...state, isFetching: true };
    case FETCH_USER_LOCATION:
      return { ...state, ...action.payload };
    case FETCH_USER_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        coordinates: action.payload.coordinates,
        countryCode: action.payload.countryCode,
        address: action.payload.address,
      };

    case FETCH_USER_LOCATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        coordinates: { lat: 51.5073509, lng: -0.1277583 },
        countryCode: "GB",
        address: "London, UK",
      };

    default:
      return state;
  }
};
