import axios from "axios";
import { HEADERS, FOOTBALL_API } from "../constants";

export const getStatistics = async (fixture_id) => {
  try {
    const res = await axios.get(
      `${FOOTBALL_API}/statistics/fixture/${fixture_id}`,
      { headers: HEADERS }
    );
    const statistics = res.data.api.statistics;
    if (statistics.constructor === Array) {
      throw new Error();
    }

    return { statistics };
  } catch (error) {
    return { statistics: null };
  }
};
