import React from "react";
import PropTypes from "prop-types";

import { trimString } from "../../helpers/trimString";
import LastFiveMatchesRow from "../LastFiveMatchesRow";
import "./lastFiveMatches.scss";

const LastFiveMatches = ({
  lastFiveMatches,
  fixture: { home_team, away_team },
  leagueTeams,
}) => {
  const { homeTeamLastFiveMatches, awayTeamLastFiveMatches } = lastFiveMatches;

  return (
    <div className="lastFiveMatches-wrapper">
      <div className="lastFiveMatches-title">LAST FIVE MATCHES</div>
      <div className="lastFiveMatches">
        <div className="lastFiveMatches-header">
          <div className="lastFiveMatches-teamHeader">
            <img
              alt="logo"
              className="lastFiveMatches-teamHeaderLogo"
              src={home_team?.logo}
              onError={(e) => (e.target.src = home_team?.logo)}
            />
            <span className="lastFiveMatches-teamHeaderName">
              {(leagueTeams[home_team?.team_id] &&
              leagueTeams[home_team?.team_id]?.team.code &&
              leagueTeams[away_team?.team_id] &&
              leagueTeams[away_team?.team_id]?.team.code)
                ? leagueTeams[home_team?.team_id]?.team.code
                :home_team?.name}{" "}
              {"  "}
            </span>
          </div>
          <div className="lastFiveMatches-teamHeader">
            <img
              className="lastFiveMatches-teamHeaderLogo"
              src={away_team?.logo}
              alt="logo"
              onError={(e) => (e.target.src = away_team?.logo)}
            />
            <span className="lastFiveMatches-teamHeaderName">
              {(leagueTeams[away_team?.team_id] &&
              leagueTeams[away_team?.team_id]?.team.code &&
              leagueTeams[home_team?.team_id] &&
              leagueTeams[home_team?.team_id]?.team.code)
                ? leagueTeams[away_team?.team_id]?.team.code
                : away_team?.name}{" "}
              {"  "}
            </span>
          </div>
        </div>
        {homeTeamLastFiveMatches &&
          homeTeamLastFiveMatches.map((homeTeamFixture, idx) => {
            let awayTeamFixture =
              awayTeamLastFiveMatches && awayTeamLastFiveMatches[idx];
            return (
              <LastFiveMatchesRow
                key={homeTeamFixture?.fixture.id}
                homeTeamFixture={homeTeamFixture}
                awayTeamFixture={awayTeamFixture}
                staticHomeTeam={home_team}
                staticAwayTeam={away_team}
                leagueTeams={leagueTeams}
              />
            );
          })}
      </div>
    </div>
  );
};

LastFiveMatches.propTypes = {
  lastFiveMatches: PropTypes.object,
  fixture: PropTypes.object,
  leagueTeams: PropTypes.object,
};

export default LastFiveMatches;
