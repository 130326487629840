import React from "react";
import PropTypes from "prop-types";

import "./AboutTab.scss";

const AboutTabs = ({
  setIndex,
  index,
  tabs,
  components,
  type,
  route,
}) => {
  console.log("tabs1", tabs);
  return (
    <div
      className={`${type === "sub1" ? "sub-tabs1" : "main-tabs1"} ${
        type === "main1" && route === "live1" && "live-details-tabs1"
      }`}
    >
      <div
        className={`tabs1 ${type === "main1" && "box-shadow1"} ${
          type === "sub1" && "negative-margin1"
        } ${route !== "live1" && "home-details-tabs1"}`}
      >
        {tabs.map(({ title, id }) => (
          <span
            key={id}
            onClick={() => setIndex(id)}
            style={{textAlign: 'center'}}
            className={`tab1 ${index === id && "active-tab1"}`}
          >
            {title}
          </span>
        ))}
      </div>

      <div
        className={`${type === "sub1" ? "sub-tab-component1" : "tab-component1"} ${
          route !== "live1" && type !== "sub1" && "home-tab-component1"
        }`}
      >
        {components[index]}
      </div>
    </div>
  );
};

AboutTabs.propTypes = {
  setIndex: PropTypes.func,
  index: PropTypes.number,
  tabs: PropTypes.array,
  components: PropTypes.object,
  type: PropTypes.string,
  route: PropTypes.string,
};

export default AboutTabs;
