import { createSelector } from "reselect";

const selectAttraction = (state) => state.attraction;

export const selectAttractionType = createSelector(
  selectAttraction,
  (attraction) => attraction.attractionTypes
);

export const selectCountries = createSelector(
  selectAttraction,
(attraction) => attraction.countriesList
);

export const selectCreateRes = createSelector(
  selectAttraction,
(attraction) => attraction.attractionRecomendationCreateRes
);

export const selectRecomendation = createSelector(
  selectAttraction,
(attraction) => attraction.attractionRecomendation
);