import axios from "axios";
import { FAM_API } from "../../constants";
import moment from "moment";
import { useState, useEffect } from "react";

const useFetchAirports = (user, venue, fixture) => {
  const [url, setUrl] = useState("https://c111.travelpayouts.com/click?shmarker=584422.kiwicom&promo_id=3791&source_type=customlink&type=click");

  useEffect(() => {
    (async () => {
      try {
        if (!venue.lng && !venue.lat) return;
        const res = await axios.get(
          `${FAM_API}/get-airports?userLocation="${user.lng},${user.lat}"&venueLocation="${venue.lng},${venue.lat}"`
        );
        const { success, data } = res.data;
        if (success) {
          const { userAirport, venueAirport } = data;
          if (venueAirport) {
            let currentDate = moment(fixture?.event_date)
              .subtract(2, "d")
              .format('YYYY-MM-DD');
            let afterDate = moment(fixture?.event_date)
              .add(2, "d")
              .format('YYYY-MM-DD');
              const encodedURL =  encodeURIComponent(`https://www.kiwi.com/deep?from=${userAirport[0]?.iata}&to=${venueAirport[0]?.iata}&departure=${currentDate}&return=${afterDate}`);
            setUrl(
              `https://c111.travelpayouts.com/click?shmarker=584422.kiwicom&promo_id=3791&source_type=customlink&type=click&custom_url=${encodedURL}`
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [fixture]);
  return url;
};

export default useFetchAirports;
