import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { connect } from "react-redux";
import moment from "moment";

import "./liveMatchesDates.scss";
import {
  selectLiveMatchesDate,
  selectAreLiveDetailsFetched,
  selectLiveDates,
  selectLiveFixturesLeagues
} from "../../redux/live/liveSelectors";
import {
  fetchLiveMatchesDate,
  openDetailsSection,
  liveKeepFetching,
  fetchLiveMatch,
  showOnlyLiveMatches
} from "../../redux/live/liveActions";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import { fetchFilteringValues } from "../../redux/filteringValues/filteringValuesActions";
import { showOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesActions";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? (
    <div className="details-visible">{children}</div>
  ) : (
    wrapper(children)
  );

const LiveMatchesDates = ({
  dates,
  date,
  fetchLiveMatchesDate,
  areLiveDetailsFetched,
  openDetailsSection,
  width,
  fetchFilteringValues,
  liveFixturesLeagues,
  liveKeepFetching,
  showOnlyFav,
  fetchLiveMatch,
  showOnlyLiveMatches
}) => {
  
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    arrows: true,
    dots: false
  };

  const slickRef = useRef(null);

  useEffect(() => {
    if (slickRef && slickRef.current) {
      slickRef.current.slickGoTo(1);
    }
  }, [slickRef, areLiveDetailsFetched]);

  useEffect(() => {
    return () => showOnlyLiveMatches({ onlyLiveMatches: false });
  }, []);

  const handleDateClick = (date) => {
    showOnlyLiveMatches({ onlyLiveMatches: false });
    fetchLiveMatch(null);
    showOnlyFav(false);
    fetchFilteringValues({
      isItFiltered: false,
      selectedLeagues: liveFixturesLeagues[date]
        ? liveFixturesLeagues[date]
        : []
    });
    fetchLiveMatchesDate(date);
    openDetailsSection(false);
    new Date(date).getDate() !== new Date().getDate() &&
      liveKeepFetching(false);
  };

  const list = dates ? (
    dates.map((dd) => (
      <div
        key={dd}
        onClick={() => handleDateClick(dd)}
        className={`live-matches-date  ${dd === date && "highlight-date"}`}
      >
        {new Date(dd).getDate() === new Date().getDate()
          ? "Today"
          : moment(dd).format("MMM DD")}
      </div>
    ))
  ) : (
    <span></span>
  );

  return (
    <div className={`live-matches-dates`}>
      <ConditionalWrapper
        condition={areLiveDetailsFetched && width > 1024}
        wrapper={(children) => (
          <Slider ref={slickRef} {...settings}>
            {children}
          </Slider>
        )}
      >
        {list}
      </ConditionalWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dates: selectLiveDates(
    state.live.areLiveDetailsFetched,
    state.screenDimensions.width
  )(state),
  width: selectWidth(state),
  date: selectLiveMatchesDate(state),
  areLiveDetailsFetched: selectAreLiveDetailsFetched(state),
  liveFixturesLeagues: selectLiveFixturesLeagues(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchLiveMatchesDate: (payload) => dispatch(fetchLiveMatchesDate(payload)),
  openDetailsSection: (payload) => dispatch(openDetailsSection(payload)),
  fetchFilteringValues: (payload) => dispatch(fetchFilteringValues(payload)),
  liveKeepFetching: (payload) => dispatch(liveKeepFetching(payload)),
  showOnlyFav: () => dispatch(showOnlyFav()),
  showOnlyLiveMatches: (payload) => dispatch(showOnlyLiveMatches(payload)),
  fetchLiveMatch: (payload) => dispatch(fetchLiveMatch(payload))
});

LiveMatchesDates.propTypes = {
  width: PropTypes.number,
  dates: PropTypes.array,
  date: PropTypes.string,
  fetchLiveMatch: PropTypes.func,
  fetchLiveMatchesDate: PropTypes.func,
  areLiveDetailsFetched: PropTypes.bool,
  fetchFilteringValues: PropTypes.func,
  openDetailsSection: PropTypes.func,
  showOnlyLiveMatches: PropTypes.func,
  liveFixturesLeagues: PropTypes.object,
  liveKeepFetching: PropTypes.func,
  showOnlyFav: PropTypes.func
};

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveMatchesDates);
