import React from "react";
import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";

import "./searchLeaguesInput.scss";
import Mixpanel from "../../utils/mixPanel";

const SearchLeaguesInput = ({ inputVal, setInputVal, placeholder }) => {
  const handleChange = (e) => {
    // Mixpanel.track(` On competition ${e.target.value}`);
    setInputVal(e.target.value);
  };

  return (
    <div className="search-leagues-input-container">
      <input
        className={`search-leagues-input`}
        type="text"
        placeholder={placeholder}
        value={inputVal}
        onChange={handleChange}
      />
      <FiSearch className="search-icon" size={20} />
    </div>
  );
};

SearchLeaguesInput.propTypes = {
  inputVal: PropTypes.any,
  setInputVal: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SearchLeaguesInput;
