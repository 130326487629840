import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";

import SubstituteRow from "../SubstituteRow";
import "./lineUp.scss";
import { connect } from "react-redux";
import {
  selectLiveMatch,
  selectLineUps,
  selectLiveMatchesDate,
  selectLiveEvents,
  selectIsFetchingLineUps,
} from "../../redux/live/liveSelectors";
import { fetchLineUpsStart } from "../../redux/live/liveActions";
import Spinner from "../Spinner";
import LineUpsPlayers from "../LineUpsPlayers";

const LineUp = ({
  liveMatch,
  lineUps,
  fetchLineUpsStart,
  liveDate,
  events,
  isFetching,
}) => {
  const homeTeam = lineUps && lineUps[liveMatch?.home_team?.name];
  const awayTeam = lineUps && lineUps[liveMatch?.away_team?.name];

  const homeTeamEvents =
    events &&
    events.filter(
      (x) => x.type === "subst" && x?.team_id === liveMatch?.home_team?.team_id
    );

  const awayTeamEvents =
    events &&
    events.filter(
      (x) => x.type === "subst" && x?.team_id === liveMatch?.away_team?.team_id
    );

  useEffect(() => {
    let interval;
    if (!lineUps) {
      fetchLineUpsStart();
    }

    if (
      new Date(liveDate).getDate() === new Date().getDate() &&
      "events" in liveMatch
    ) {
      interval = setInterval(() => {
        fetchLineUpsStart();
      }, 900000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [liveMatch]);
  if (!isFetching && !lineUps) {
    return (
      <div className="not-available-text">Line ups are not available yet.</div>
    );
  }

  return (
    <div className="lineup">
      {isFetching && (
        <div className="lineup-spinner">
          <Spinner />
        </div>
      )}

      {homeTeam?.startXI || awayTeam?.startXI ? (
        <>
          <div className="lineup-title">LINE UPS</div>
          <div className="line-ups">
            {homeTeam?.startXI &&
              homeTeam?.startXI.map((x, idx) => {
                const homeLineUp = x;
                const awayLineUp = awayTeam?.startXI[idx];
                return (
                  <LineUpsPlayers
                    key={idx}
                    homeLineUp={homeLineUp}
                    awayLineUp={awayLineUp}
                  />
                );
              })}
          </div>
        </>
      ) : null}
      {homeTeamEvents && homeTeamEvents.length ? (
        <>
          <div className="lineup-title">SUBSTITUTIONS</div>
          <div className="subst">
            {homeTeamEvents &&
              homeTeamEvents.map((event, idx) => {
                const awayTeamEvent = awayTeamEvents[idx];
                return (
                  <SubstituteRow
                    key={idx}
                    homeTeamEvent={event}
                    awayTeamEvent={awayTeamEvent}
                  />
                );
              })}
          </div>
        </>
      ) : null}
      {homeTeam?.substitutes || awayTeam?.substitutes ? (
        <>
          <div className="lineup-title">SUBSTITUTE PLAYERS</div>
          <div className="subst-players">
            <div className="line-ups bottom-gap">
              {homeTeam?.substitutes &&
                homeTeam?.substitutes.map((x, idx) => {
                  const homeLineUp = x;
                  const awayLineUp = awayTeam?.substitutes[idx];
                  return (
                    <LineUpsPlayers
                      key={idx}
                      homeLineUp={homeLineUp}
                      awayLineUp={awayLineUp}
                    />
                  );
                })}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  liveMatch: selectLiveMatch,
  liveDate: selectLiveMatchesDate,
  lineUps: selectLineUps,
  events: selectLiveEvents,
  isFetching: selectIsFetchingLineUps,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLineUpsStart: (payload) => dispatch(fetchLineUpsStart(payload)),
});

LineUp.propTypes = {
  liveMatch: PropTypes.object,
  lineUps: PropTypes.object,
  liveDate: PropTypes.string,
  fetchLineUpsStart: PropTypes.func,
  events: PropTypes.array,
  isFetching: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(LineUp);
