export const filterLiveFixtures = (
  liveFixtures,
  selectedLeaguesIds,
  liveDate
) => {
  let filteredDate = {};
  if (selectedLeaguesIds.length && liveFixtures && liveFixtures[liveDate]) {
    for (let i = 0; i < selectedLeaguesIds.length; i++) {
      const currentLeagueId = selectedLeaguesIds[i];
      if (currentLeagueId in liveFixtures[liveDate]) {
        filteredDate[currentLeagueId] = liveFixtures[liveDate][currentLeagueId];
      }
    }
  }

  return { ...liveFixtures, [liveDate]: filteredDate };
};
