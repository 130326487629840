import React from "react";
import PropTypes from "prop-types";

import "./sortModalCustomButtons.scss";

const SortModalCustomButtons = ({ text, callback, type, order }) => {
  return (
    <div
      onClick={callback}
      className={`sort-modal-custom-btn ${type !== order && "inverse"}`}
    >
      {text}
    </div>
  );
};

SortModalCustomButtons.propTypes = {
  text: PropTypes.string,
  callback: PropTypes.func,
  type: PropTypes.string,
  order: PropTypes.string,
};

export default SortModalCustomButtons;
