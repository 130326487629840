import React, { useState } from "react";
import "./forgotpassword.scss";
import signInImg from "../../assets/images/signin-img.png";
import { useDispatch, useSelector } from "react-redux";
import facebook from "../../assets/images/facebook.svg";
import google from "../../assets/images/google.svg";
import { loginRequest } from "../../redux/auth/authActions";
import { LOGIN_FAILURE } from "../../redux/auth/authTypes";
import { showError } from "../../components/ToastMessage";
import { forgotPasswordCall } from "../../redux/auth/authServices";
import { useHistory } from "react-router-dom";
import { AuthRight } from "../../components/AuthRight/authright";

const ForgotPassword = ({
  title = "Forgot Password",
  btnText = "Continue",
}) => {
  // State for email and password
  const [email, setEmail] = useState("");

  const history = useHistory();

  const data = useSelector((state) => state.user);
  console.log("data", data.user);
  // Handler for input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
  };
  // Handler for login button click
  const handleClick = async () => {
    // Basic validation
    if (!email) {
      const errorMessage = "Email address cannot be empty";
      showError(errorMessage);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      const errorMessage = "Invalid email address";
      showError(errorMessage);
      return;
    }
    // call api
    const data = await forgotPasswordCall({ email });
    if (data?.success) {
      history.push("/reset-password");
    }
  };

  return (
    <div className="signin-container">
      <div className="sigin-left-section">
        <div className="sigin-left">
          <h2 className="heading">{title}</h2>
          <p style={{ fontSize: "14px", lineHeight: "2" }}>
            Please enter your email address to receive a recovery code and reset
            your password.
          </p>
          <div className="input-container">
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              placeholder="Email"
              className="email-input"
            />
          </div>
          {/* <div className="reset-container">
            <p
              className="clickable"
              // onClick={() => history.push("/forgot-password")}
            >
              Resend Code
            </p>
          </div> */}
          {/* <div className="reset-container">
            <p>
              Don't have account? <span>Signin</span>
            </p>
          </div> */}
          <button className="login-btn" onClick={handleClick}>
            {btnText}
          </button>
        </div>
      </div>
      <AuthRight title={title} />
    </div>
  );
};

export default ForgotPassword;
