import React from "react";
import PropTypes from "prop-types";

import "./detailsTabs.scss";

const DetailsTabs = ({ setIndex, index, tabs, components, type, route }) => {
  console.log("tabs", tabs);
  return (
    <div
      className={`${type === "sub" ? "sub-tabs" : "main-tabs"} ${
        type === "main" && route === "live" && "live-details-tabs"
      }`}>
      <div
        className={`tabs ${type === "main" && "box-shadow"} ${
          type === "sub" && "negative-margin"
        } ${route !== "live" && "home-details-tabs"}`}>
        {tabs.map(({ title, id }) => (
          <span
            key={id}
            onClick={() => setIndex(id)}
            className={`tab ${index === id && "active-tab"}`}>
            {title}
          </span>
        ))}
      </div>

      <div
        className={`${type === "sub" ? "sub-tab-component" : "tab-component"} ${
          route !== "live" && type !== "sub" && "home-tab-component"
        }`}>
        {components[index]}
      </div>
    </div>
  );
};

DetailsTabs.propTypes = {
  setIndex: PropTypes.func,
  index: PropTypes.number,
  tabs: PropTypes.array,
  components: PropTypes.object,
  type: PropTypes.string,
  route: PropTypes.string,
};

export default DetailsTabs;
