import React, { useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import StarRatings from "react-star-ratings";
import { connect } from "react-redux";
import { FiPhoneCall } from "react-icons/fi";
import { GoLocation } from "react-icons/go";

import Ratings from "../Ratings";
import { selectFixture } from "../../redux/fixtureDetails/fixtureDetailsSelectors";

import "./supporterCard.scss";
import { GOOGLE_API_KEY } from "../../constants/constants";
import ReviewsModal from "../ReviewsModal";
import ReviewCard from "../ReviewCard";

//ACTION
import { fetchReviewData } from '../../redux/reviews/reviewActions';

//SELECTORS
import { selectPubs } from "../../redux/reviews/reviewSelectors";

export const middleRate = (item)=>{
  let count = 0;
  const a = item?.food_expense?item.food_expense:0;
  const b = item?.food_quality?item.food_quality:0;
  const c = item?.supporter_experience?item.supporter_experience:0;        
  if(a)++count;
  if(b)++count;
  if(c)++count;
  
  return Number(a+b+c)/count
}

const SupporterCard = ({ supporter, fixture, index, type, pubs, fetchReviewData}) => {
  const [reviewsModal, setReviewsModal] = useState(false);
  const { lat, lng } = fixture;
  const {
    name,
    rating,
    ratingCount,
    about,
    phone,
    address,
    // location,
  } = supporter;

  supporter.sup_type = "pubs";
  
  useEffect(()=>{    
    fetchReviewData(supporter)
  },[]);
  
  pubs = pubs
    .filter((item)=>{
      if(supporter._id===item.supporter_id)return true;  
      return false;
    }).filter((item)=>item.approved)

  const countTotal  = pubs.length ? pubs.reduce((acc,cur)=>{
      return middleRate(cur)+acc
  },0): 0  

  return (
    <>
      <div
        onClick={() => index !== -1 && setReviewsModal(true)}
        className="supporter-card-container"
      >
        <div className="supporter-card">
          <div className="card-details">
            <span className="supporter-name">{name}</span>
            <Ratings
              size="10px"
              rating={countTotal? countTotal/pubs.length : 0}
              ratingCount={pubs.length?pubs.length:0}
            />

            <span className="supporter-place-description">{about}</span>
            <div className="supporter-contact">
              <div className="supporter-team">
                <img
                  className="supporter-team-logo"
                  src={fixture.home_team.logo}
                  alt="logo"
                  onError={(e) => e.target.src = fixture.home_team.logo}
                />
                <div className="supporter-team-container">
                  <span className="supporter-type">Home Supporter</span>
                  <span className="supporter-team-name">
                    {fixture.home_team.name}
                  </span>
                </div>
              </div>
              <div className="supporter-contact-info">
                <span className="supporter-number">
                  <span className="location-icon">
                    <FiPhoneCall color="#2e2e2e" size={11} />{" "}
                  </span>
                  {phone || "Number not available"}
                </span>

                <span className="supporter-address">
                  <span className="location-icon">
                    <GoLocation color="#2e2e2e" size={11} />
                  </span>
                  {address}
                </span>
              </div>
            </div>
          </div>
          <div className="card-static-container">
            <img
              className="card-static-img"
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=14&size=100x100&markers=anchor:center|icon:${
                type !== "restaurants"
                  ? "https://tinyurl.com/y7b6qc3v"
                  : "https://tinyurl.com/yatj3drr"
              }|${lat},${lng}&key=${GOOGLE_API_KEY}`}
              alt="map"
              onError={(e) => e.target.src = "https://media.api-sports.io/football/teams/14834.png"}
            />
          </div>
        </div>

        {index === 0 && (          
            <div className="review-card-wrapper">
              <ReviewCard styles={{ width: "95%", height: 140 }} />
            </div>          
        )}
      </div>
      {reviewsModal?<ReviewsModal
        supporter={supporter}
        reviewsModal={reviewsModal}
        pubs = {pubs}
        setReviewsModal={setReviewsModal}
      />: null
      } 
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  fixture: selectFixture,
  pubs :selectPubs
});

SupporterCard.propTypes = {
  supporter: PropTypes.object,
  fixture: PropTypes.object,
  index: PropTypes.number,
  type: PropTypes.string,
  fetchReviewData: PropTypes.func,
  pubs: PropTypes.array
};

const mapDispatchToProps = {
  fetchReviewData
}


export default connect(mapStateToProps, mapDispatchToProps)(SupporterCard);
