import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_START,
  LOGIN_SUCCESS,
  SET_STATE,
  LOGOUT,
  FETCH_WISHLIST_FAILURE,
  FETCH_WISHLIST_SUCCESS,
  FETCH_WISHLIST_REQUEST,
} from "./authTypes";

const INITIAL_STATE = {
  user: null,
  loading: false,
  error: null,
  wishlist: {},
  wishlisting: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_WISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        wishlist: action.payload.wishlist,
        wishlisting: action.payload.wishlisting,
      };
    case FETCH_WISHLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGIN_START:
      return { ...state, isFetching: true };
    case SET_STATE:
      return { ...state, ...action.payload };
    case LOGOUT:
      return { ...state, user: null, wishlist: {}, wishlisting: [] };
    default:
      return state;
  }
};
