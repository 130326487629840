import {
  FETCH_ATTRACTIONS_TYPE,
  FETCH_ATTRACTIONS_TYPE_DATA,
  CREATE_ATTRACTIONS_RECOMENDATION,
  CREATE_ATTRACTIONS_RECOMENDATION_ACTION,
  FETCH_ATTRACTIONS_RECOMENDATION_TYPE_DATA,
  FETCH_ATTRACTIONS_RECOMENDATION_TYPE
} from "./attractionTypes";

//ACTION
export const fetchAttractionType = (payload) => ({
  type: FETCH_ATTRACTIONS_TYPE,
  payload,
});

export const fetchAttractionRecomendationType = (payload) => ({
  type: FETCH_ATTRACTIONS_RECOMENDATION_TYPE,
  payload,
});

export const createAttractionRecomendationAction = (payload) => ({
  type: CREATE_ATTRACTIONS_RECOMENDATION_ACTION,
  payload,
});

//SAGA ACTION
export const fetchAttractionTypeData = (payload) => ({
  type: FETCH_ATTRACTIONS_TYPE_DATA,
  payload,
});

export const fetchAttractionRecomendationTypeData = (payload) => ({
  type: FETCH_ATTRACTIONS_RECOMENDATION_TYPE_DATA,
  payload,
});

export const createAttractionRecomendation = (payload) => ({
  type: CREATE_ATTRACTIONS_RECOMENDATION,
  payload,
});
