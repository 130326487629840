import React from "react";
import PropTypes from "prop-types";

import "./customApplyButton.scss";

const CustomApplyButton = ({ text, handlePress, isFetching , classNameContainer}) => {
  return (
    <div className={`apply-btn-container ${classNameContainer? classNameContainer : ''}`}>
      <div
        onClick={() => !isFetching && handlePress()}
        className={`custom-apply-btn${isFetching ? "-disable" : ""}`}
      >
        {text}
      </div>

    </div>
  );
};

CustomApplyButton.propTypes = {
  handlePress: PropTypes.func,
  isFetching: PropTypes.bool,
  text: PropTypes.string,
};

export default CustomApplyButton;
