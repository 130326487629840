import React from "react";
import PropTypes from "prop-types";

import "./customTabButton.scss";

const CustomTabButton = ({ text, selected, callback }) => {
  return (
    <div onClick={callback} className={`tab-button ${selected && "selected"}`}>
      {text}
    </div>
  );
};

CustomTabButton.propTypes = {
  text: PropTypes.string,
  selected: PropTypes.bool,
  callback: PropTypes.func,
};

export default CustomTabButton;
