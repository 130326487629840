import {
  FETCH_FIXTURES_BY_LOCATION_START,
  FETCH_FIXTURES_BY_COUNTRY_START,
  FETCH_FIXTURES_BY_LOCATION_FAILURE,
  FETCH_FIXTURES_BY_LOCATION_SUCCESS,
  FETCH_FIXTURES_BY_LEAGUES_FAILURE,
  FETCH_FIXTURES_BY_LEAGUES_SUCCESS,
  FETCH_FIXTURES_BY_LEAGUES_START,
  FETCH_MATCHDAY_LOADERS,
  FETCH_ISCOUNTRY_SEARCH,
  RESET_FIXTURES,
  MATCH_SPLIT_BY_DAY,
  SET_FIXTURES,
  SET_LEAGUES,
  SELECTED_MOBILE_FIXTURES,
  RESET_SELECTED_MOBILE_FIXTURES,
} from "./fixturesTypes";

const INITIAL_STATE = {
  fixtures: {
    insideRadiusFixtures: [],
    outsideRadiusFixtures: [],
  },
  leagues: null,
  searchBy: "",
  isFetching: false,
  error: null,
  matches: {},
  mobileSelectedFixtures: 1,
  allLoaders: {},
  isAllCountries: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FIXTURES_BY_LEAGUES_START:
    case FETCH_FIXTURES_BY_LOCATION_START:
      return {
        ...state,
        isFetching: true,
        isAllCountries: action.payload,
      };
    case FETCH_FIXTURES_BY_COUNTRY_START:
      return {
        ...state,
        isFetching: true,
        isAllCountries: action.payload,
      };
    case FETCH_FIXTURES_BY_LEAGUES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        leagues: action.payload.leagues,
        fixtures: action.payload.fixtures,
        searchBy: action.payload.searchBy,
      }
    case FETCH_MATCHDAY_LOADERS:
      return {
        ...state,
        allLoaders: action.payload,
      };
    case FETCH_ISCOUNTRY_SEARCH:
      return {
        ...state,
        isCountrySearch: action.payload,
      };
    case FETCH_FIXTURES_BY_LOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        leagues: action.payload.leagues,
        fixtures: action.payload.fixtures,
        searchBy: action.payload.searchBy,
        error: action.payload.fixtures ? null : "Matches Not Found",
      };
    case FETCH_FIXTURES_BY_LEAGUES_FAILURE:
    case FETCH_FIXTURES_BY_LOCATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        leagues: null,
        fixtures: {
          insideRadiusFixtures: [],
          outsideRadiusFixtures: [],
        },
        error: action.payload,
      };
    case RESET_FIXTURES:
      return INITIAL_STATE;
    case MATCH_SPLIT_BY_DAY:
      return { ...state, matches: action.payload };
    case SET_FIXTURES:
      return { ...state, fixtures: action.payload };
    case SET_LEAGUES:
      return { ...state, leagues: action.payload };
    case SELECTED_MOBILE_FIXTURES:
      return { ...state, mobileSelectedFixtures: action.payload };
    case RESET_SELECTED_MOBILE_FIXTURES:
      return { ...state, mobileSelectedFixtures: "1" };
    default:
      return state;
  }
};
