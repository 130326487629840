
import axios from "axios";
import { FAM_API } from "../constants";

export const updateFixtures = async () => {
    try {
      const response = await axios.post(`${FAM_API}/update-fixtures`);
      console.log(response);
      //const leagues = response.data.data.leagues;
    //  return { leagues };
    } catch (error) {
      return { error: "Could not fetch leagues" };
    }
  };
  