import axios from "axios";
import { FAM_API } from "../../constants";

export const getAttractionTypes = async () => {
  try {
    const response = await axios
      .get(`${FAM_API}/attractions-types`, {
        params: {
          can_user_review: true,
        },
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getCountriesList = async () => {
  try {
    const response = await axios.get(`${FAM_API}/countries`).catch((error) => {
      console.log(error);
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createAttractionRecomendation = async (data) => {
  try {
    const response = await axios.post(
      `${FAM_API}/attractions-recomendation-multiple`,
      data
    );

    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      const errorMessage = error.response.data.error;
      console.log(errorMessage);
      throw new Error(errorMessage);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const getAttractionsByLocation = async (data) => {
  try {
    const response = await axios.get(`${FAM_API}/attractions-by-location`, {
      params: data,
    });

    return response.data.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      const errorMessage = error.response.data.error;
      console.log(errorMessage);
      throw new Error(errorMessage);
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const getAttractionRecomendation = async (attraction_id) => {
  try {
    const response = await axios.get(`${FAM_API}/attractions-recomendation`, {
      params: {
        attraction_id: attraction_id,
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      const errorMessage = error.response.data.error;
      console.log(errorMessage);
      throw new Error(errorMessage);
    } else {
      console.log(error);
      throw error;
    }
  }
};
