export const countries = [
  {
    country: "Albania",
    code: "AL",
    flag: "https://media.api-sports.io/flags/al.svg",
  },
  {
    country: "Algeria",
    code: "DZ",
    flag: "https://media.api-sports.io/flags/dz.svg",
  },
  {
    country: "Andorra",
    code: "AD",
    flag: "https://media.api-sports.io/flags/ad.svg",
  },
  {
    country: "Angola",
    code: "AO",
    flag: "https://media.api-sports.io/flags/ao.svg",
  },
  {
    country: "Argentina",
    code: "AR",
    flag: "https://media.api-sports.io/flags/ar.svg",
  },
  {
    country: "Armenia",
    code: "AM",
    flag: "https://media.api-sports.io/flags/am.svg",
  },
  {
    country: "Aruba",
    code: "AW",
    flag: "https://media.api-sports.io/flags/aw.svg",
  },
  {
    country: "Australia",
    code: "AU",
    flag: "https://media.api-sports.io/flags/au.svg",
  },
  {
    country: "Austria",
    code: "AT",
    flag: "https://media.api-sports.io/flags/at.svg",
  },
  {
    country: "Azerbaidjan",
    code: "AZ",
    flag: "https://media.api-sports.io/flags/az.svg",
  },
  {
    country: "Bahrain",
    code: "BH",
    flag: "https://media.api-sports.io/flags/bh.svg",
  },
  {
    country: "Bangladesh",
    code: "BD",
    flag: "https://media.api-sports.io/flags/bd.svg",
  },
  {
    country: "Barbados",
    code: "BB",
    flag: "https://media.api-sports.io/flags/bb.svg",
  },
  {
    country: "Belarus",
    code: "BY",
    flag: "https://media.api-sports.io/flags/by.svg",
  },
  {
    country: "Belgium",
    code: "BE",
    flag: "https://media.api-sports.io/flags/be.svg",
  },
  {
    country: "Belize",
    code: "BZ",
    flag: "https://media.api-sports.io/flags/bz.svg",
  },
  {
    country: "Benin",
    code: "BJ",
    flag: "https://media.api-sports.io/flags/bj.svg",
  },
  {
    country: "Bermuda",
    code: "BM",
    flag: "https://media.api-sports.io/flags/bm.svg",
  },
  {
    country: "Bhutan",
    code: "BT",
    flag: "https://media.api-sports.io/flags/bt.svg",
  },
  {
    country: "Bolivia",
    code: "BO",
    flag: "https://media.api-sports.io/flags/bo.svg",
  },
  {
    country: "Bosnia",
    code: "BA",
    flag: "https://media.api-sports.io/flags/ba.svg",
  },
  {
    country: "Botswana",
    code: "BW",
    flag: "https://media.api-sports.io/flags/bw.svg",
  },
  {
    country: "Brazil",
    code: "BR",
    flag: "https://media.api-sports.io/flags/br.svg",
  },
  {
    country: "Bulgaria",
    code: "BG",
    flag: "https://media.api-sports.io/flags/bg.svg",
  },
  {
    country: "Burkina-Faso",
    code: "BF",
    flag: "https://media.api-sports.io/flags/bf.svg",
  },
  {
    country: "Cambodia",
    code: "KH",
    flag: "https://media.api-sports.io/flags/kh.svg",
  },
  {
    country: "Cameroon",
    code: "CM",
    flag: "https://media.api-sports.io/flags/cm.svg",
  },
  {
    country: "Canada",
    code: "CA",
    flag: "https://media.api-sports.io/flags/ca.svg",
  },
  {
    country: "Chile",
    code: "CL",
    flag: "https://media.api-sports.io/flags/cl.svg",
  },
  {
    country: "China",
    code: "CN",
    flag: "https://media.api-sports.io/flags/cn.svg",
  },
  {
    country: "Colombia",
    code: "CO",
    flag: "https://media.api-sports.io/flags/co.svg",
  },
  {
    country: "Congo-DR",
    code: "CG",
    flag: "https://media.api-sports.io/flags/cg.svg",
  },
  {
    country: "Costa-Rica",
    code: "CR",
    flag: "https://media.api-sports.io/flags/cr.svg",
  },
  {
    country: "Croatia",
    code: "HR",
    flag: "https://media.api-sports.io/flags/hr.svg",
  },
  {
    country: "Cyprus",
    code: "CY",
    flag: "https://media.api-sports.io/flags/cy.svg",
  },
  {
    country: "Czech-Republic",
    code: "CZ",
    flag: "https://media.api-sports.io/flags/cz.svg",
  },
  {
    country: "Denmark",
    code: "DK",
    flag: "https://media.api-sports.io/flags/dk.svg",
  },
  {
    country: "Ecuador",
    code: "EC",
    flag: "https://media.api-sports.io/flags/ec.svg",
  },
  {
    country: "Egypt",
    code: "EG",
    flag: "https://media.api-sports.io/flags/eg.svg",
  },
  {
    country: "El-Salvador",
    code: "SV",
    flag: "https://media.api-sports.io/flags/sv.svg",
  },
  {
    country: "England",
    code: "GB",
    flag: "https://media.api-sports.io/flags/gb.svg",
  },
  {
    country: "Estonia",
    code: "EE",
    flag: "https://media.api-sports.io/flags/ee.svg",
  },
  {
    country: "Ethiopia",
    code: "ET",
    flag: "https://media.api-sports.io/flags/et.svg",
  },
  {
    country: "Faroe-Islands",
    code: "FO",
    flag: "https://media.api-sports.io/flags/fo.svg",
  },
  {
    country: "Fiji",
    code: "FJ",
    flag: "https://media.api-sports.io/flags/fj.svg",
  },
  {
    country: "Finland",
    code: "FI",
    flag: "https://media.api-sports.io/flags/fi.svg",
  },
  {
    country: "France",
    code: "FR",
    flag: "https://media.api-sports.io/flags/fr.svg",
  },
  {
    country: "Georgia",
    code: "GE",
    flag: "https://media.api-sports.io/flags/ge.svg",
  },
  {
    country: "Germany",
    code: "DE",
    flag: "https://media.api-sports.io/flags/de.svg",
  },
  {
    country: "Greece",
    code: "GR",
    flag: "https://media.api-sports.io/flags/gr.svg",
  },
  {
    country: "Guadeloupe",
    code: "GP",
    flag: "https://media.api-sports.io/flags/gp.svg",
  },
  {
    country: "Guatemala",
    code: "GT",
    flag: "https://media.api-sports.io/flags/gt.svg",
  },
  {
    country: "Guinea",
    code: "GN",
    flag: "https://media.api-sports.io/flags/gn.svg",
  },
  {
    country: "Haiti",
    code: "HT",
    flag: "https://media.api-sports.io/flags/ht.svg",
  },
  {
    country: "Honduras",
    code: "HN",
    flag: "https://media.api-sports.io/flags/hn.svg",
  },
  {
    country: "Hong-Kong",
    code: "HK",
    flag: "https://media.api-sports.io/flags/hk.svg",
  },
  {
    country: "Hungary",
    code: "HU",
    flag: "https://media.api-sports.io/flags/hu.svg",
  },
  {
    country: "Iceland",
    code: "IS",
    flag: "https://media.api-sports.io/flags/is.svg",
  },
  {
    country: "India",
    code: "IN",
    flag: "https://media.api-sports.io/flags/in.svg",
  },
  {
    country: "Indonesia",
    code: "ID",
    flag: "https://media.api-sports.io/flags/id.svg",
  },
  {
    country: "Iran",
    code: "IR",
    flag: "https://media.api-sports.io/flags/ir.svg",
  },
  {
    country: "Ireland",
    code: "IE",
    flag: "https://media.api-sports.io/flags/ie.svg",
  },
  {
    country: "Israel",
    code: "IL",
    flag: "https://media.api-sports.io/flags/il.svg",
  },
  {
    country: "Italy",
    code: "IT",
    flag: "https://media.api-sports.io/flags/it.svg",
  },
  {
    country: "Ivory-Coast",
    code: "CI",
    flag: "https://media.api-sports.io/flags/ci.svg",
  },
  {
    country: "Jamaica",
    code: "JM",
    flag: "https://media.api-sports.io/flags/jm.svg",
  },
  {
    country: "Japan",
    code: "JP",
    flag: "https://media.api-sports.io/flags/jp.svg",
  },
  {
    country: "Jordan",
    code: "JO",
    flag: "https://media.api-sports.io/flags/jo.svg",
  },
  {
    country: "Kazakhstan",
    code: "KZ",
    flag: "https://media.api-sports.io/flags/kz.svg",
  },
  {
    country: "Kenya",
    code: "KE",
    flag: "https://media.api-sports.io/flags/ke.svg",
  },
  {
    country: "Kuwait",
    code: "KW",
    flag: "https://media.api-sports.io/flags/kw.svg",
  },
  {
    country: "Latvia",
    code: "LV",
    flag: "https://media.api-sports.io/flags/lv.svg",
  },
  {
    country: "Lebanon",
    code: "LB",
    flag: "https://media.api-sports.io/flags/lb.svg",
  },
  {
    country: "Lithuania",
    code: "LT",
    flag: "https://media.api-sports.io/flags/lt.svg",
  },
  {
    country: "Luxembourg",
    code: "LU",
    flag: "https://media.api-sports.io/flags/lu.svg",
  },
  {
    country: "Macedonia",
    code: "MK",
    flag: "https://media.api-sports.io/flags/mk.svg",
  },
  {
    country: "Malawi",
    code: "MW",
    flag: "https://media.api-sports.io/flags/mw.svg",
  },
  {
    country: "Malaysia",
    code: "MY",
    flag: "https://media.api-sports.io/flags/my.svg",
  },
  {
    country: "Malta",
    code: "MT",
    flag: "https://media.api-sports.io/flags/mt.svg",
  },
  {
    country: "Mexico",
    code: "MX",
    flag: "https://media.api-sports.io/flags/mx.svg",
  },
  {
    country: "Moldova",
    code: "MD",
    flag: "https://media.api-sports.io/flags/md.svg",
  },
  {
    country: "Montenegro",
    code: "ME",
    flag: "https://media.api-sports.io/flags/me.svg",
  },
  {
    country: "Morocco",
    code: "MA",
    flag: "https://media.api-sports.io/flags/ma.svg",
  },
  {
    country: "Namibia",
    code: "NA",
    flag: "https://media.api-sports.io/flags/na.svg",
  },
  {
    country: "Netherlands",
    code: "NL",
    flag: "https://media.api-sports.io/flags/nl.svg",
  },
  {
    country: "New-Zealand",
    code: "NZ",
    flag: "https://media.api-sports.io/flags/nz.svg",
  },
  {
    country: "Nicaragua",
    code: "NI",
    flag: "https://media.api-sports.io/flags/ni.svg",
  },
  {
    country: "Nigeria",
    code: "NG",
    flag: "https://media.api-sports.io/flags/ng.svg",
  },
  {
    country: "Northern-Ireland",
    code: "GB",
    flag: "https://media.api-sports.io/flags/gb.svg",
  },
  {
    country: "Norway",
    code: "NO",
    flag: "https://media.api-sports.io/flags/no.svg",
  },
  {
    country: "Oman",
    code: "OM",
    flag: "https://media.api-sports.io/flags/om.svg",
  },
  {
    country: "Panama",
    code: "PA",
    flag: "https://media.api-sports.io/flags/pa.svg",
  },
  {
    country: "Paraguay",
    code: "PY",
    flag: "https://media.api-sports.io/flags/py.svg",
  },
  {
    country: "Peru",
    code: "PE",
    flag: "https://media.api-sports.io/flags/pe.svg",
  },
  {
    country: "Poland",
    code: "PL",
    flag: "https://media.api-sports.io/flags/pl.svg",
  },
  {
    country: "Portugal",
    code: "PT",
    flag: "https://media.api-sports.io/flags/pt.svg",
  },
  {
    country: "Qatar",
    code: "QA",
    flag: "https://media.api-sports.io/flags/qa.svg",
  },
  {
    country: "Romania",
    code: "RO",
    flag: "https://media.api-sports.io/flags/ro.svg",
  },
  {
    country: "Russia",
    code: "RU",
    flag: "https://media.api-sports.io/flags/ru.svg",
  },
  {
    country: "Rwanda",
    code: "RW",
    flag: "https://media.api-sports.io/flags/rw.svg",
  },
  {
    country: "San-Marino",
    code: "SM",
    flag: "https://media.api-sports.io/flags/sm.svg",
  },
  {
    country: "Saudi-Arabia",
    code: "SA",
    flag: "https://media.api-sports.io/flags/sa.svg",
  },
  {
    country: "Scotland",
    code: "GB",
    flag: "https://media.api-sports.io/flags/gb.svg",
  },
  {
    country: "Senegal",
    code: "SN",
    flag: "https://media.api-sports.io/flags/sn.svg",
  },
  {
    country: "Serbia",
    code: "RS",
    flag: "https://media.api-sports.io/flags/rs.svg",
  },
  {
    country: "Singapore",
    code: "SG",
    flag: "https://media.api-sports.io/flags/sg.svg",
  },
  {
    country: "Slovakia",
    code: "SK",
    flag: "https://media.api-sports.io/flags/sk.svg",
  },
  {
    country: "Slovenia",
    code: "SI",
    flag: "https://media.api-sports.io/flags/si.svg",
  },
  {
    country: "South-Africa",
    code: "ZA",
    flag: "https://media.api-sports.io/flags/za.svg",
  },
  {
    country: "South-Korea",
    code: "KR",
    flag: "https://media.api-sports.io/flags/kr.svg",
  },
  {
    country: "Spain",
    code: "ES",
    flag: "https://media.api-sports.io/flags/es.svg",
  },
  {
    country: "Sudan",
    code: "SD",
    flag: "https://media.api-sports.io/flags/sd.svg",
  },
  {
    country: "Sweden",
    code: "SE",
    flag: "https://media.api-sports.io/flags/se.svg",
  },
  {
    country: "Switzerland",
    code: "CH",
    flag: "https://media.api-sports.io/flags/ch.svg",
  },
  {
    country: "Syria",
    code: "SY",
    flag: "https://media.api-sports.io/flags/sy.svg",
  },
  {
    country: "Thailand",
    code: "TH",
    flag: "https://media.api-sports.io/flags/th.svg",
  },
  {
    country: "Tunisia",
    code: "TN",
    flag: "https://media.api-sports.io/flags/tn.svg",
  },
  {
    country: "Turkey",
    code: "TR",
    flag: "https://media.api-sports.io/flags/tr.svg",
  },
  {
    country: "Ukraine",
    code: "UA",
    flag: "https://media.api-sports.io/flags/ua.svg",
  },
  {
    country: "United-Arab-Emirates",
    code: "AE",
    flag: "https://media.api-sports.io/flags/ae.svg",
  },
  {
    country: "Uruguay",
    code: "UY",
    flag: "https://media.api-sports.io/flags/uy.svg",
  },
  {
    country: "USA",
    code: "US",
    flag: "https://media.api-sports.io/flags/us.svg",
  },
  {
    country: "Uzbekistan",
    code: "UZ",
    flag: "https://media.api-sports.io/flags/uz.svg",
  },
  {
    country: "Venezuela",
    code: "VE",
    flag: "https://media.api-sports.io/flags/ve.svg",
  },
  {
    country: "Vietnam",
    code: "VN",
    flag: "https://media.api-sports.io/flags/vn.svg",
  },
  {
    country: "Wales",
    code: "GB",
    flag: "https://media.api-sports.io/flags/gb.svg",
  },
  {
    country: "World",
    code: null,
    flag: null,
  },
  {
    country: "Zambia",
    code: "ZM",
    flag: "https://media.api-sports.io/flags/zm.svg",
  },
  {
    country: "Zimbabwe",
    code: "ZW",
    flag: "https://media.api-sports.io/flags/zw.svg",
  },
];
