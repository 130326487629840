import React from "react";
import PropTypes from "prop-types";

import { trimString } from "../../helpers/trimString";
import OddsRow from "../OddsRow";
import "./odds.scss";

const Odds = ({ odds, fixture: { home_team, away_team } }) => {
  return (
    <div className="odds">
      <div className="odds-title">ODDS</div>
      <div className="odds-headerRow">
        <div className="odds-homeTeam odds-team">
          <span className="odds-teamName odds-homeTeamName">
            {trimString(home_team?.name, 19)} {"  "}
          </span>
          <img src={home_team?.logo} className="odds-teamLogo" alt="logo" onError={(e) => e.target.src = home_team?.logo} />
        </div>
        <div className="odds-vsContainer">
          <span className="odds-vsTxt">VS</span>
        </div>
        <div className="odds-awayTeam odds-team">
          <img src={away_team?.logo} className="odds-teamLogo" alt="logo" onError={(e) => e.target.src = away_team?.logo}/>
          <span className="odds-teamName odds-awayTeamName">
            {trimString(away_team?.name, 19)} {"  "}
          </span>
        </div>
      </div>

      <div className="odds-tableHeaderRow">
        <div className="odds-tableHeaderCol1">
          <span className="odds-tableHeaderTitle">1</span>
        </div>
        <div className="odds-tableHeaderCol1">
          <span className="odds-tableHeaderTitle">X</span>
        </div>
        <div className="odds-tableHeaderCol1">
          <span className="odds-tableHeaderTitle">2</span>
        </div>
        <div className="odds-tableHeaderCol2"></div>
      </div>

      {odds &&
        odds.map((bookmaker) => (
          <OddsRow key={bookmaker.name} bookmaker={bookmaker} />
        ))}
    </div>
  );
};

Odds.propTypes = {
  odds: PropTypes.array,
  fixture: PropTypes.object,
};

export default Odds;
