export const divideFixturesByDistance = (fixtures, radius,isLeague) => {
  const fixturesWithRadius = {
    insideRadiusFixtures: [],
    outsideRadiusFixtures: [],
  };

  for (let i = 0; i < fixtures?.length; i++) {
    const fixture = fixtures[i];

    if (fixture?.lat) {
      if (fixture?.distance < radius) {
        fixturesWithRadius.insideRadiusFixtures.push(fixture);
      } else if(isLeague) {
        fixturesWithRadius.outsideRadiusFixtures.push(fixture);
      }
    } else if(isLeague) {
      fixturesWithRadius.outsideRadiusFixtures.push(fixture);
    }
  }
  return fixturesWithRadius;
};
