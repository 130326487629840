/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FiSearch } from "react-icons/fi";
import { RiCloseLine } from "react-icons/ri";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import "./autoCompleteSearchBox.scss";
import { connect } from "react-redux";
import { selectUserLocationAddress } from "../../redux/userLocation/userLocationSelectors";
import { selectFormValuesAddress } from "../../redux/formValues/formValuesSelectors";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { fetchFormValues } from "../../redux/formValues/formValuesActions";
import { getUserLocation } from "../../helpers/getUserLocation";
import { fetchUserLocationStart } from "../../redux/userLocation/userLocationActions";
import { useRouteMatch } from "react-router-dom";
import { MdMyLocation } from "react-icons/md";
import Mixpanel from "../../utils/mixPanel";
const platform = typeof window !== "undefined" ? "web" : "mobile";
const AutoCompleteSearchBox = ({
  userLocationAddress,
  searchedLocationAddress,
  fetchFormValues,
  fetchMapDetails,
  handlePress,
  locationString,
  isFetching,
  fetchUserLocationStart,
}) => {
  const [localAddress, setLocalAddress] = useState(locationString || " ");
  const [isFromFlag, setIsFromFlag] = useState(false);
  const match = useRouteMatch();
  const { path } = match;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [suggestionsState, setSuggestionsState] = useState([]); // State to store suggestions
  const dropdownRef = useRef(null); // Ref for detecting outside clicks
  useEffect(() => {
    if (locationString) {
      setLocalAddress(locationString);
    }
  }, [locationString]);
  useEffect(() => {
    console.log(
      searchedLocationAddress,
      "searchedLocationAddress",
      userLocationAddress,
      "userLocationAddress"
    );
    if (path.includes("search")) {
      const sessionAddress = sessionStorage.getItem("search");
      const input = document.getElementsByClassName("location-search-input");
      input[0].addEventListener(
        "keydown",
        (event) => {
          if (event.code == "Enter") {
            // enterEvent&&enterEvent()
          }
        },
        false
      );
      // setLocalAddress(searchedLocationAddress || sessionAddress || userLocationAddress);
      setLocalAddress(searchedLocationAddress);
    }
  }, [searchedLocationAddress, userLocationAddress]);
  useEffect(() => {
    // const { radius, dates, lat, lng, address, iso_a2, leagues } = getUrlParams(
    //   window.location.href
    // );
    if (!path.includes("search")) {
      getUserLocation(fetchUserLocationStart)
        .then((res) => {
          setLocalAddress(res.address);
          Mixpanel.track("Enable location", {
            platform: platform,
            application_type: platform === "web" ? "web app" : "mobile app",
            address: res.address,
          });
          console.log(res, "getUserLocation");
        })
        .catch((err) => {
          Mixpanel.track("Disabled location", {
            platform: platform,
            application_type: platform === "web" ? "web app" : "mobile app",
          });
          setLocalAddress("London, UK");
        });
    }
  }, []);
  useEffect(() => {
    setLocalAddress(locationString);
    const input = document.getElementsByClassName("location-search-input");
    input[0].addEventListener(
      "keydown",
      (event) => {
        if (event.code === "Enter") {
          // enterEvent&&enterEvent()
        }
      },
      false
    );
    handleSelectFromFlag(locationString);
    setIsFromFlag(true);
  }, [locationString]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        isDropdownOpen
      ) {
        handleSelectFirstSuggestion();
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, suggestionsState]);

  const handleSelectFirstSuggestion = () => {
    const suggestionItem = document.querySelector(".suggestion-item");
    const suggestions = suggestionItem ? suggestionItem.textContent : null;
    handleSelect(suggestions);
  };

  console.log(
    searchedLocationAddress,
    "searchedLocationAddress",
    userLocationAddress,
    "userLocationAddress"
  );
  const handleChange = (address) => {
    setLocalAddress(address);
    setIsDropdownOpen(true);
    Mixpanel.track("Pressed search bar", {
      platform: platform,
      application_type: platform === "web" ? "web app" : "mobile app",
    });
  };

  const handleSelect = (address) => {
    setLocalAddress(address);
    let countryCode;

    if (window.google && window.google.maps) {
      geocodeByAddress(address)
        .then((results) => {
          results[0].address_components.forEach((x) => {
            if (x.types.includes("country") || x.address_components) {
              countryCode = x.short_name;
            }
          });
          sessionStorage.setItem("search", `${address}`);
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          Mixpanel.track(`Selected location`, {
            platform: platform,
            application_type: platform === "web" ? "web app" : "mobile app",
            searchedLocation: latLng,
          });
          fetchFormValues({ countryCode, address, searchedLocation: latLng });
          fetchMapDetails({ center: latLng });
        })
        .catch((error) => {
          Mixpanel.track(`Error on Custom Location`, {
            platform: platform,
            application_type: platform === "web" ? "web app" : "mobile app",
            error,
          });
          console.error("Error", error);
        });
    } else {
      console.error("Google Maps API is not available.");
    }
  };

  const handleSelectFromFlag = (address) => {
    setLocalAddress(address);
    let countryCode;
    if (window.google && window.google.maps) {
      geocodeByAddress(address)
        .then((results) => {
          results[0].address_components.forEach((x) => {
            if (x.types.includes("country") || x.address_components) {
              countryCode = x.short_name;
            }
          });
          sessionStorage.setItem("search", `${address}`);
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          Mixpanel.track(`Pressed on current location`, {
            platform: platform,
            application_type: platform === "web" ? "web app" : "mobile app",
            searchedLocation: latLng,
          });
          fetchFormValues({ countryCode, address, searchedLocation: latLng });
          fetchMapDetails({ center: latLng });
          // setTimeout(() => {
          //   handlePress(true, {
          //     countryCode,
          //     address,
          //     searchedLocation: latLng,
          //   });
          //   setIsFromFlag(false);
          // }, 500);
        })
        .catch((error) => console.error("Error", error));
    } else {
      console.error("Google Maps API is not available.");
    }
  };

  const handleLocationPin = () => {
    let countryCode;
    getUserLocation(fetchUserLocationStart)
      .then((res) => {
        console.log("Response", res);
        setLocalAddress(res.address);
        geocodeByAddress(res.address)
          .then((results) => {
            results[0].address_components.forEach((x) => {
              if (x.types.includes("country") || x.address_components) {
                countryCode = x.short_name;
              }
            });
            sessionStorage.setItem("search", `${res.address}`);
            return getLatLng(results[0]);
          })
          .then((latLng) => {
            fetchFormValues({
              countryCode,
              address: res.address,
              searchedLocation: latLng,
            });
            fetchMapDetails({ center: latLng });
          })
          .catch((error) => console.error("Error", error));
      })
      .catch((err) => {
        setLocalAddress("London, UK");
      });
  };

  return (
    <PlacesAutocomplete
      value={localAddress == "undefined" ? "" : localAddress}
      onChange={handleChange}
      onSelect={handleSelect}
      googleCallbackName="myCallbackFunc"
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }, i) => {
        return (
          <div key={i} ref={dropdownRef}>
            <div className="location-search-input-container">
              <FiSearch className="search-icon" size={20} />
              <input
                {...getInputProps({
                  placeholder: "Search by location",
                  className: "location-search-input",
                })}
              />

              {/* {localAddress && ( */}
              <>
                <MdMyLocation
                  className="location-icon"
                  size={22}
                  onClick={handleLocationPin}
                />

                <RiCloseLine
                  onClick={() => {
                    setLocalAddress("");
                  }}
                  className="input-close-icon"
                  size={25}
                />
              </>
              {/* )} */}
            </div>

            {isDropdownOpen && (
              <div className="autocomplete-dropdown-container">
                <div className="absolute-list">
                  {loading && <div></div>}

                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={suggestion.index}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

const mapStateToProps = createStructuredSelector({
  userLocationAddress: selectUserLocationAddress,
  searchedLocationAddress: selectFormValuesAddress,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchFormValues: (payload) => dispatch(fetchFormValues(payload)),
  fetchUserLocationStart: () => dispatch(fetchUserLocationStart()),
});

AutoCompleteSearchBox.propTypes = {
  userLocationAddress: PropTypes.string,
  searchedLocationAddress: PropTypes.string,
  setDestination: PropTypes.func,
  fetchMapDetails: PropTypes.func,
  fetchFormValues: PropTypes.func,
  enterEvent: PropTypes.func,
  locationString: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoCompleteSearchBox);
