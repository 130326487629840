import { createSelector } from "reselect";

const selectReviews = (state) => state.reviews;

export const selectStadium = createSelector(
    selectReviews,
  (reviews) => reviews.stadiums
);

export const selectPubs = createSelector(
  selectReviews,
(reviews) => reviews.pubs
);