import React from "react";
import { GiSoccerField } from "react-icons/gi";
import PropTypes from "prop-types";
import { FaSortAmountDown } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { AiFillStar } from "react-icons/ai";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";

import "./mobileFixturesListButtons.scss";
import { showOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesActions";
import { connect } from "react-redux";
import {
  selectShowOnlyFav,
  selectFavMatches,
} from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import {
  selectFixturesForLive,
  selectLiveMatchesDate,
  selectShowOnlyLive,
  selectLiveMatches,
} from "../../redux/live/liveSelectors";
import {
  fetchLiveMatch,
  showOnlyLiveMatches,
} from "../../redux/live/liveActions";
import { selectIsItFiltered } from "../../redux/filteringValues/filteringValuesSelectors";

const MobileFixturesListButtons = ({
  setFilterModal,
  setSortModal,
  handleShowAllMatchesClick,
  type,
  showOnlyFav,
  onlyFav,
  fixturesForLive,
  liveDate,
  favMatches,
  currentIndex,
  fetchLiveMatch,
  showOnlyLiveMatches,
  onlyLiveMatches,
  isItFiltered,
  liveMatches,
}) => {
  const cond =
    fixturesForLive &&
    fixturesForLive[liveDate] &&
    Object.values(fixturesForLive[liveDate])
      .flat()
      .some((x) => favMatches.includes(x._id));
  return (
    <div></div>
    // <div className="mobile-fixtures-list-buttons">
    //   <div onClick={() => setFilterModal(true)} className="mflb-col mflb-col-1">
    //     <FiFilter className="mflb-side-icon" color="#8f8f8f" size={16} />
    //     <span className="mflb-text mflb-side-text">LEAGUES</span>
    //   </div>
    //   <div onClick={handleShowAllMatchesClick} className="mflb-col mflb-col-2">
    //     {
    //       <>
    //         <GiSoccerField
    //           className="mflb-middle-icon"
    //           color="#5256c8"
    //           size={20}
    //         />
    //         <span className="mflb-text">
    //           {type === "live" &&
    //           new Date(liveDate).getDate() === new Date().getDate() ? (
    //             onlyLiveMatches ||
    //             onlyFav ||
    //             isItFiltered ||
    //             isEmpty(liveMatches) ? (
    //               "SHOW ALL MATCHES"
    //             ) : (
    //               <>
    //                 SHOW LIVE MATCHES <span className="live-dot"></span>
    //               </>
    //             )
    //           ) : (
    //             "SHOW ALL MATCHES"
    //           )}
    //         </span>
    //       </>
    //     }
    //   </div>

    //   {type === "live" ? (
    //     <div
    //       onClick={() => {
    //         showOnlyFav(onlyFav ? false : true);
    //         fetchLiveMatch(null);
    //         showOnlyLiveMatches({ onlyLiveMatches: false });
    //         currentIndex.current = 0;
    //       }}
    //       className={`mflb-col mflb-col-3`}
    //     >
    //       {cond ? (
    //         <>
    //           <AiFillStar
    //             className="mflb-side-icon"
    //             color={onlyFav ? "#ffda1a" : "#8f8f8f"}
    //             size={17}
    //           />
    //           <span className="mflb-text mflb-side-text">FAVORITE</span>
    //         </>
    //       ) : null}
    //     </div>
    //   ) : (
    //     <div
    //       onClick={() => setSortModal(true)}
    //       className={`mflb-col mflb-col-3`}
    //     >
    //       <FaSortAmountDown
    //         className="mflb-side-icon"
    //         color="#8f8f8f"
    //         size={15}
    //       />
    //       <span className="mflb-text mflb-side-text">SORT</span>
    //     </div>
    //   )}
    // </div>
  );
};

const mapDispatchToProps = {
  showOnlyFav,
  fetchLiveMatch,
  showOnlyLiveMatches,
};

const mapStateToProps = createStructuredSelector({
  onlyFav: selectShowOnlyFav,
  favMatches: selectFavMatches,
  fixturesForLive: selectFixturesForLive,
  liveDate: selectLiveMatchesDate,
  onlyLiveMatches: selectShowOnlyLive,
  isItFiltered: selectIsItFiltered,
  liveMatches: selectLiveMatches,
});

MobileFixturesListButtons.propTypes = {
  setSortModal: PropTypes.func,
  isItFiltered: PropTypes.bool,
  setFilterModal: PropTypes.func,
  handleShowAllMatchesClick: PropTypes.func,
  type: PropTypes.string,
  onlyLiveMatches: PropTypes.bool,
  showOnlyFav: PropTypes.func,
  onlyFav: PropTypes.bool,
  liveMatches: PropTypes.object,
  liveDate: PropTypes.string,
  fixturesForLive: PropTypes.object,
  favMatches: PropTypes.array,
  fetchLiveMatch: PropTypes.func,
  showOnlyLiveMatches: PropTypes.func,
  currentIndex: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFixturesListButtons);
