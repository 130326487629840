import {
  FETCH_FILTERING_VALUES,
  RESET_FILTERING_VALUES,
} from "./filteringValuesTypes";

export const fetchFilteringValues = (payload) => ({
  type: FETCH_FILTERING_VALUES,
  payload,
});

export const resetFilteringValues = () => ({
  type: RESET_FILTERING_VALUES,
});

export const fetchIsAllLeaguesChecked = (payload) => ({
  type: FETCH_FILTERING_VALUES,
  payload,
});
