import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";

import App from "./App";
import "rc-slider/assets/index.css";
import "react-responsive-modal/styles.css";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import configureStore from "./redux/store";
import { countries } from "./assets/countries";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

Sentry.init({
  dsn: "https://d2d3935719f4404bbc06fd29c6b65804@o448941.ingest.sentry.io/5431096",

  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "window.myCallbackFunc is not a function",
  ],
});

const { store, persistor } = configureStore(countries);

render(
  <GoogleOAuthProvider clientId="899414182001-t22is9fq0k8k7uhtnm8amllo4fe95jmr.apps.googleusercontent.com">
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <App />
          <ToastContainer />
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
