import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { selectLiveMatch } from "../../redux/live/liveSelectors";
import { liveDetailsTabs, liveDetailsComponents } from "../../utils";
import DetailsTabs from "../DetailsTabs";
import "./liveMatchDetails.scss";
import LiveMatchListItem from "../LiveMatchListItem";

const LiveMatchDetails = ({ fixture, index, setIndex }) => {
  return (
    <div className="live-match-details">
      <div className="live-match-details-title">
        {`${fixture?.league?.name} ${
          fixture?.league?.country !== "World"
            ? "(" + fixture?.league?.country + ")"
            : ""
        }`}
      </div>
      <LiveMatchListItem fixture={fixture} details={true} />
      <DetailsTabs
        tabs={liveDetailsTabs}
        index={index}
        setIndex={setIndex}
        components={liveDetailsComponents}
        type="main"
        route="live"
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  fixture: selectLiveMatch,
});

LiveMatchDetails.propTypes = {
  fixture: PropTypes.object,
  index: PropTypes.number,
  setIndex: PropTypes.func,
};

export default connect(mapStateToProps)(LiveMatchDetails);
