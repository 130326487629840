import React from "react";
import { GiSoccerField } from "react-icons/gi";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";

import "./showLiveMatches.scss";
import {
  showOnlyLiveMatches,
  openDetailsSection,
} from "../../redux/live/liveActions";
import { selectShowOnlyLive } from "../../redux/live/liveSelectors";
import { showOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesActions";
import { selectShowOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesSelectors";

const ShowLiveMatches = ({
  itemRef,
  onlyLiveMatches,
  showOnlyLiveMatches,
  showOnlyFav,
  onlyFav,
  openDetailsSection,
}) => {
  const handleClick = () => {
    openDetailsSection(false);
    if (itemRef) {
      itemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    showOnlyLiveMatches({
      onlyLiveMatches: onlyLiveMatches || onlyFav ? false : true,
    });
    showOnlyFav(false);
  };

  return (
    <div onClick={handleClick} className="list-show-all-matches">
      <GiSoccerField
        className="list-show-all-matches-text"
        size={30}
        color="#2e2e2e"
      />
      <span className="list-show-all-matches-text">
        {onlyLiveMatches || onlyFav ? (
          "Show All Matches"
        ) : (
          <>
            <div className="live-dot-container">
              Show Live Matches <span className="live-dot"></span>
            </div>
          </>
        )}
      </span>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  onlyLiveMatches: selectShowOnlyLive,
  onlyFav: selectShowOnlyFav,
});

ShowLiveMatches.propTypes = {
  itemRef: PropTypes.object,
  onlyLiveMatches: PropTypes.bool,
  showOnlyLiveMatches: PropTypes.func,
  showOnlyFav: PropTypes.func,
  onlyFav: PropTypes.bool,
  openDetailsSection: PropTypes.func,
};

export default connect(mapStateToProps, {
  showOnlyLiveMatches,
  showOnlyFav,
  openDetailsSection,
})(ShowLiveMatches);
