import { takeLatest, put, all, call, select } from "redux-saga/effects";
import axios from 'axios'
import { 
    FETCH_REVIEW_DATA,
} from "./reviewTypes";


import { getStadiumReview, getPubsReview } from '../../helpers/getReview'

//ACTION
import { fetchReview } from './reviewActions'


function* fetchReviewById({payload}) {
  try {
    if(payload.location.sup_type==='stadiums'){
      const res = yield call(getStadiumReview,payload.venue_id);
      const reviews = res?.data?.filter((s)=>s.approved)
      yield put(fetchReview({type: payload.location.sup_type, data: reviews}));
    } else if ( payload.sup_type==='pubs' ){
      const res = yield call(getPubsReview,payload.stadium);   
      yield put(fetchReview({type: payload.sup_type, data: res?.data?.data}));
    } else {

    }    
    
  } catch(e){
      console.log(e)
  }
}

function* fetchReviewData() {
  yield takeLatest(
    FETCH_REVIEW_DATA,
    fetchReviewById
  );
}

export default function* reviewSagas() {
  yield all([    
    call(fetchReviewData),
  ]);
}
