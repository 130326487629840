import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { FaRegClock } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import information from "../../assets/images/information.png";
import StadiumImageBlue from "../../assets/images/blueMarker.png";

import {
  fetchFixture,
  fetchSupportersDetailsStart,
  resetFixtureDetails,
} from "../../redux/fixtureDetails/fixtureDetailsActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import {
  selectSearchBy,
  selectDynamicFixturesList,
  selectFixturesLeagues,
  selectFixturesFetching,
  selectFixturesByLeagues,
  selectFicturesLeaguesArray,
} from "../../redux/fixtures/fixturesSelectors";
import {
  selectInternalMap,
  selectCircle,
  selectMarkerSelected,
  selectMarker,
  selectIsMobile,
} from "../../redux/map/mapSelectors";
import MobileFixturesListItem from "../MobileFixturesListItem.js/MobileFixturesListItem";
import "./mobileFixturesList.scss";
import MobileFixturesListButtons from "../MobileFixturesListButtons";
import { fetchFilteringValues } from "../../redux/filteringValues/filteringValuesActions";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import { AiOutlineCaretLeft } from "react-icons/ai";
import Spinner from "../Spinner";
import LiveMatchesDates from "../LiveMatchesDates";
import ErrorMessage from "../ErrorMessage";
import Ad from "../Ad/Ad";
import {
  selectLiveMatches,
  selectIsFetchingLiveMatches,
  selectFixturesForLive,
  selectLiveMatchesDate,
  selectLiveMatch,
  selectShowOnlyLive,
  selectLiveFixturesLeagues,
  selectIsMobileSidebarVisible,
} from "../../redux/live/liveSelectors";
import {
  fetchIsMobileSidebarVisible,
  fetchLiveMatch,
  resetLiveDetails,
  showOnlyLiveMatches,
} from "../../redux/live/liveActions";
import {
  selectFavMatches,
  selectShowOnlyFav,
} from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import { showOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesActions";
import {
  animateCamera,
  getLiveMatches,
  splitFixturesByLeagues,
} from "../../utils";

import {
  selectFormValuesDates,
  selectorSelectDay,
} from "../../redux/formValues/formValuesSelectors";
import {
  selectIsItFiltered,
  selectSortBy,
} from "../../redux/filteringValues/filteringValuesSelectors";
import { selectNormalizedLeaguesList } from "../../redux/leagues/leaguesSelectors";
import { countriesCoords } from "../../assets/countriesCoords";
import {
  selectAreFixturesDetailsFetched,
  selectIsWishlistFrom,
} from "../../redux/sidebar/sidebarSelectors";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import { selectFixture } from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import { resetMapDetails } from "../../redux/map/mapActions";
import MatchesDates from "../MatchesDates";
// TO SHOW MIDDLE GREEN ARROW
import {
  selectMobileFixture,
  resetMobileFixture,
} from "../../redux/fixtures/fixturesActions";
import { selectedMobileFixture } from "../../redux/fixtures/fixturesSelectors";
import { selectMatches } from "../../redux/fixtures/fixturesSelectors";
import { setFixture, setLeagues } from "../../redux/fixtures/fixturesActions";
import { selectFormValuesSearchedLocation } from "../../redux/formValues/formValuesSelectors";

//NEXT BUTTON
import { selectDay } from "../../redux/formValues/formValuesActions";

//HELPER FUNC
import { sortByLeague } from "../../helpers/sortByLeague";

//COMPONENTS
import MatchNotFound from "../MatchNotFound";
import { GiConsoleController } from "react-icons/gi";
import { Context } from "../../helpers/context/Context";

function MobileFixturesListWishlist(props) {
  const {
    fixtures,
    fetchSidebarDetails,
    fetchFixture,
    width,
    fetchMapDetails,
    searchBy,
    setFilterModal,
    setSortModal,
    fetchFilteringValues,
    deviceLocation,
    markerSelected,
    map,
    circle,
    fetchSupportersDetailsStart,
    resetFixtureDetails,
    type,
    liveMatches,
    isFetchingLiveMatches,
    isFetchingFixtures,
    fetchIsMobileSidebarVisible,
    fixturesForLive,
    liveDate,
    fetchLiveMatch,
    liveMatch,
    favMatchesIds,
    showOnlyFav,
    areFixturesDetailsFetched,
    isMobileSidebarVisible,
    resetLiveDetails,
    showOnlyLiveMatches,
    showOnlyFavMatches,
    onlyLiveMatches,
    isItFiltered,
    leagues,
    fixture,
    fixturesLeagues,
    liveFixturesLeagues,
    dates,
    resetMapDetails,
    selectMobileFixture,
    selectedMobileFixture,
    currentSelectedDay,
    selectDay,
    setFixture,
    setLeagues,
    matches,
    isFetching,
    sortBy,
    marker,
    isMobile,
    isWishlistFrom,
  } = props;
  console.log("isWishlistFrom", fixtures);
  const { insideRadiusFixtures, outsideRadiusFixtures } = fixtures;

  const fixList =
    type !== "live"
      ? [...insideRadiusFixtures, ...outsideRadiusFixtures]
      : new Date(liveDate).getDate() === new Date().getDate()
      ? fixturesForLive[liveDate] &&
        Object.values(
          getLiveMatches(fixturesForLive[liveDate], liveMatches || {})
        ).flat()
      : fixturesForLive &&
        fixturesForLive[liveDate] &&
        Object.values(fixturesForLive[liveDate]).flat();
  if (searchBy !== "leagues" && fixList && fixList.length) {
    if (type !== "live" && !markerSelected) {
      // fixList.splice(insideRadiusFixtures.length, 0, {
      //   title: "Outside Radius",
      //   fixture_id: 2
      // });
    }
  }
  let list =
    type === "live"
      ? splitFixturesByLeagues(
          fixList,
          leagues,
          showOnlyFav,
          onlyLiveMatches,
          favMatchesIds
        ) || []
      : fixList || [];

  if (searchBy !== "leagues" && list && list.length && !markerSelected) {
    // list.splice(0, 0, {
    //   title: "All Matches Scroll Down To Focus",
    //   fixture_id: 1
    // });
  }

  const handleSupporterInfoClick = (fixture, ty, start) => {
    resetFixtureDetails();
    if (fixture?.fixtures?.length) {
      // const markers = [...fixture.fixtures];
      // // CALC CENTER CORD
      // const middleLat = fixture?.fixtures.reduce((acc, cur) => {
      //   if (cur.lat) {
      //     acc.lat = acc.lat + cur.lat;

      //   }
      //   if (cur.lng) {
      //     acc.lng = acc.lng + cur.lng;
      //   }

      //   return acc;
      // }, { lat: 0, lng: 0 });

      // console.log('test', middleLat);
      // SET TO STORE
      fetchMapDetails({
        marker: fixture.fixtures,
        zoom: 8,
        // center: { lat: middleLat.lat / fixture.fixtures.length, lng: middleLat.lng / fixture.fixtures.length }
      });
      // ANIMATE CAM
      // animateCamera(markers, map);
    } else {
      fetchMapDetails({
        marker: fixture,
        zoom: 14,
        center: { lat: fixture?.lat, lng: fixture?.lng },
      });
    }

    if (!fixture.distance && map) {
      if (type === "live") {
        resetLiveDetails();
        const markers = Object.values(fixturesForLive[liveDate]).flat();
        animateCamera(markers, map);
      } else {
        if (searchBy !== "leagues" && circle) {
          // map.fitBounds(circle.getBounds(), 0);
        } else {
          const markers = [
            ...insideRadiusFixtures,
            ...outsideRadiusFixtures,
            { ...deviceLocation },
          ];

          animateCamera(markers, map);
        }
      }
    }

    if (type === "live") {
      if (fixture.distance) {
        resetLiveDetails();

        if (ty === "click") {
          fetchLiveMatch(fixture);

          fetchIsMobileSidebarVisible({
            isMobileSidebarVisible: true,
            isFormVisible: false,
          });
        } else {
          if (fixture.location) {
            fetchSupportersDetailsStart("live");
          }
        }
      }
    } else {
      if (ty === "click") {
        fetchFixture(fixture);

        fetchSidebarDetails({
          areFixturesDetailsFetched: true,
          areFixturesFetched: false,
        });
      } else {
        if (fixture.location) {
          fetchSupportersDetailsStart("home");
        }
      }
    }
    if (ty === "click") {
      const myElement = document.getElementById(`item-${fixture.fixture_id}`);
      var topPos = myElement.offsetTop;
      if (document.getElementById("snap-point")) {
        document.getElementById("snap-point").scrollTop = topPos;
      }
    }
  };
  const handlePress = (type) => {
    // if (dates.length === 1) {
    fetchSidebarDetails({
      areFixturesFetched: false,
      isWishlistFrom: true,
    });
    // }

    // const index = dates.findIndex((d) => {
    //   if (d === currentSelectedDay) return true;
    // });
    // if (type === "up") {
    //   if (index + 1 === dates.length) {
    //     const day = dates[0];
    //     selectDay(day);
    //     setFixture(matches[day]);
    //     setLeagues(matches[day].leagues);
    //     fetchSidebarDetails({
    //       areFixturesDetailsFetched: false,
    //     });
    //   } else {
    //     const day = dates[index + 1];
    //     selectDay(day);
    //     setFixture(matches[day]);
    //     setLeagues(matches[day].leagues);
    //     fetchSidebarDetails({
    //       areFixturesDetailsFetched: false,
    //     });
    //   }
    // }
  };

  return (
    <div className="mobile-fixtures-list-container">
      {isFetchingFixtures || (isFetching && width < 1000 && <Spinner />)}

      {((insideRadiusFixtures.length && type !== "live") ||
        (outsideRadiusFixtures.length && type !== "live") ||
        type === "live") &&
      fixList.length ? (
        <>
          {type === "live" ? (
            <div className="live-matches-dates-container">
              <LiveMatchesDates />
            </div>
          ) : null}
          {type === "live" && !fixList && isFetchingLiveMatches ? (
            <Spinner styles={{ alignItems: "flex-start" }} />
          ) : (
            <div
              className="mobile-fixtures-list mobile-fixtures-design"
              id="snap-point">
              {list &&
                list.length &&
                list
                  .sort((a, b) =>
                    sortBy === "distance"
                      ? a.distance - b.distance
                      : a.event_timestamp - b.event_timestamp
                  )
                  .map((fixture, index) => {
                    const { fixture_id, id } = fixture || {};
                    return (
                      <Sentry.ErrorBoundary
                        key={fixture_id || id}
                        fallback={<ErrorMessage />}>
                        <MobileFixturesListItem
                          index={index}
                          type={type}
                          id={fixture_id || id}
                          normalizedLeagues={leagues}
                          callback={() =>
                            handleSupporterInfoClick(fixture, "click", index)
                          }
                          item={fixture}
                          selectedMobileFixture={selectedMobileFixture}
                          isWishlistFrom={true}
                        />
                      </Sentry.ErrorBoundary>
                    );
                  })}
              {/* map(
                  ({ league, leagueName, fixtures, league_id, _id }, index) => {
                    if (!fixtures) return null;
                    return (
                      <>
                        <div
                          key={`${league_id}-${index}-${fixtures.length}`}
                          id={`item-${league_id || _id}`}
                          className={`mobile-fixture-list-title 
                        ${league_id === "hide" && "hide-item"}`}
                        >
                          <div
                            className="game-list"
                            id={`item-${league_id || _id}`}
                          >
                            <span className="gl-title">
                              <img
                                src={league.logo}
                                onError={(e) => (e.target.src = league.logo)}
                              ></img>
                              {leagueName}
                            </span>
                            <div className={"count"}>
                              <span className="gl-total">
                                {fixtures.length}
                              </span>
                            </div>
                          </div>
                        </div>
                        {fixtures */}

              {/* </>
                    // );
                  }
                )} */}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="btn-container"></div>
          {/* <MatchesDates /> */}
          <div className="mobile-card-wrapper">
            <MatchNotFound
              handlePressUp={() => handlePress("up")}
              dates={dates}
              currentSelectedDay={currentSelectedDay}
            />
          </div>
        </>
      )}
      <div className="ads-view">
        <Ad
          slot="7575657019"
          styles={{ marginTop: 0, width: 320, height: 60 }}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  showOnlyLiveMatches: (payload) => dispatch(showOnlyLiveMatches(payload)),
  showOnlyFavMatches: (payload) => dispatch(showOnlyFav(payload)),
  fetchFixture: (fixture) => dispatch(fetchFixture(fixture)),
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
  fetchFilteringValues: (payload) => dispatch(fetchFilteringValues(payload)),
  fetchSupportersDetailsStart: (payload) =>
    dispatch(fetchSupportersDetailsStart(payload)),
  fetchIsMobileSidebarVisible: (payload) =>
    dispatch(fetchIsMobileSidebarVisible(payload)),
  resetFixtureDetails: () => dispatch(resetFixtureDetails()),
  fetchLiveMatch: (payload) => dispatch(fetchLiveMatch(payload)),
  resetLiveDetails: () => dispatch(resetLiveDetails()),
  resetMapDetails: () => dispatch(resetMapDetails()),
  selectMobileFixture: (payload) => dispatch(selectMobileFixture(payload)),
  resetMobileFixture: () => dispatch(resetMobileFixture()),
  selectDay: (payload) => dispatch(selectDay(payload)),
  setFixture: (payload) => dispatch(setFixture(payload)),
  setLeagues: (payload) => dispatch(setLeagues(payload)),
});

const mapStateToProps = (state) => (
  console.log("Mobile State==>", state),
  {
    favMatchesIds: selectFavMatches(state),
    showOnlyFav: selectShowOnlyFav(state),
    markerSelected: selectMarkerSelected(state),
    isItFiltered: selectIsItFiltered(state),
    searchBy: selectSearchBy(state),
    leagues: selectNormalizedLeaguesList(state),
    isMobileSidebarVisible: selectIsMobileSidebarVisible(state),
    areFixturesDetailsFetched: selectAreFixturesDetailsFetched(state),
    fixtures: selectDynamicFixturesList(state),
    fixture: selectFixture(state),
    onlyLiveMatches: selectShowOnlyLive(state),
    deviceLocation: selectUserLocationCoords(state),
    map: selectInternalMap(state),
    circle: selectCircle(state),
    liveMatches: selectLiveMatches(state),
    fixturesForLive: selectFixturesForLive(state),
    isFetchingLiveMatches: selectIsFetchingLiveMatches(state),
    isFetchingFixtures: selectFixturesFetching(state),
    liveDate: selectLiveMatchesDate(state),
    liveMatch: selectLiveMatch(state),
    width: selectWidth(state),
    fixturesLeagues: selectFixturesLeagues(state),
    liveFixturesLeagues: selectLiveFixturesLeagues(state),
    dates: selectFormValuesDates,
    selectedLeagues: selectFicturesLeaguesArray(state),
    fixturesByLeagues: selectFixturesByLeagues(state),
    selectedMobileFixture: selectedMobileFixture(state),
    dates: selectFormValuesDates(state),
    currentSelectedDay: selectorSelectDay(state),
    matches: selectMatches(state),
    searchLocation: selectFormValuesSearchedLocation(state),
    sortBy: selectSortBy(state),
    marker: selectMarker(state),
    isMobile: selectIsMobile(state),
    isWishlistFrom: selectIsWishlistFrom(state),
  }
);

MobileFixturesListWishlist.propTypes = {
  fixturesLeagues: PropTypes.object,
  isMobileSidebarVisible: PropTypes.bool,
  areFixturesDetailsFetched: PropTypes.bool,
  isWishlistFrom: PropTypes.bool,
  liveFixturesLeagues: PropTypes.object,
  showOnlyLiveMatches: PropTypes.func,
  showOnlyFavMatches: PropTypes.func,
  liveMatch: PropTypes.object,
  fixture: PropTypes.object,
  markerSelected: PropTypes.object,

  fixtures: PropTypes.object,
  resetLiveDetails: PropTypes.func,
  isItFiltered: PropTypes.bool,
  deviceLocation: PropTypes.object,
  onlyLiveMatches: PropTypes.bool,
  fetchSidebarDetails: PropTypes.func,
  fetchFixture: PropTypes.func,
  fetchMapDetails: PropTypes.func,
  searchBy: PropTypes.string,
  itemRef: PropTypes.object,
  setFilterModal: PropTypes.func,
  setSortModal: PropTypes.func,
  fetchFilteringValues: PropTypes.func,
  width: PropTypes.number,
  leagues: PropTypes.object,
  map: PropTypes.object,
  circle: PropTypes.object,
  fetchSupportersDetailsStart: PropTypes.func,
  resetFixtureDetails: PropTypes.func,
  type: PropTypes.string,
  liveMatches: PropTypes.object,
  fixturesForLive: PropTypes.object,
  isFetchingLiveMatches: PropTypes.bool,
  isFetchingFixtures: PropTypes.bool,
  fetchIsMobileSidebarVisible: PropTypes.func,
  liveDate: PropTypes.string,
  fetchLiveMatch: PropTypes.func,
  favMatchesIds: PropTypes.array,
  showOnlyFav: PropTypes.bool,
  fixturesByLeagues: PropTypes.object,
  selectedLeagues: PropTypes.array,
  selectedMobileFixture: PropTypes.number,
  searchLocation: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFixturesListWishlist);
