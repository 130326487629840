import moment from "moment";
import {
  FETCH_LIVE_MATCH_DATE,
  FETCH_LIVE_MATCHES_SUCCESS,
  FETCH_LIVE_MATCHES_DETAILS_START,
  RESET_LIVE,
  FETCH_IS_MOBILE_SIDEBAR_VISIBLE,
  FETCH_DATES,
  FETCH_LIVE_MATCHES_FAILURE,
  FETCH_LIVE_MATCH,
  FETCH_LIVE_MATCH_ON_HOVER,
  FETCH_FIXTURES_FOR_LIVE_START,
  FETCH_FIXTURES_FOR_LIVE_SUCCESS,
  FETCH_FIXTURES_FOR_LIVE_FAILURE,
  OPEN_DETAILS_SECTION,
  IS_FETCHING_LIVE_DETAILS,
  FETCH_LIVE_MATCH_STATISTICS_SUCCESS,
  FETCH_LIVE_FIXTURES_LEAGUES,
  FETCH_LINE_UPS_START,
  FETCH_LINE_UPS_SUCCESS,
  FETCH_LIVE_MATCH_STATISTICS,
  RESET_LIVE_DETAILS,
  LIVE_KEEP_FETCHING,
  SHOW_ONLY_LIVE_MATCHES,
  RESET_LIVE_MODALS,
} from "./liveTypes";
import { updateLiveMatch } from "../../utils";

const INITIAL_STATE = {
  areLiveDetailsFetched: false,
  isFetchingLiveMatches: false,
  isLiveKeepFetching: false,
  date: moment(new Date()).format("YYYY-MM-DD"),
  dates: null,
  liveMatches: null,
  fixtures: {},
  liveFixturesLeagues: {},
  liveMatch: null,
  liveMatchOnHover: null,
  statistics: null,
  isMobileSidebarVisible: false,
  isFormVisible: false,
  isFetchingLiveDetails: false,
  isFetchingLineUps: true,
  isFetchingStatistics: true,
  lineUps: null,
  liveEvents: null,
  error: null,
  onlyLiveMatches: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FIXTURES_FOR_LIVE_START:
      return { ...state, isFetchingLiveMatches: true };
    case FETCH_FIXTURES_FOR_LIVE_SUCCESS:
      return {
        ...state,
        isFetchingLiveMatches:
          new Date(state.date).getDate() === new Date().getDate()
            ? true
            : false,
        fixtures: { ...state.fixtures, ...action.payload },
      };
    case FETCH_LIVE_FIXTURES_LEAGUES:
      return {
        ...state,
        liveFixturesLeagues: {
          ...state.liveFixturesLeagues,
          ...action.payload,
        },
      };
    case FETCH_LIVE_MATCHES_SUCCESS:
      return {
        ...state,
        isFetchingLiveMatches: false,
        liveMatches: action.payload,
        liveMatch: updateLiveMatch(action.payload, state.liveMatch),
        areLiveDetailsFetched:
          state.liveMatch && "events" in state.liveMatch
            ? Object.values(action.payload)
                .flat()
                .some((x) => x.fixture_id === state.liveMatch.fixture_id)
            : state.areLiveDetailsFetched,
      };
    case FETCH_LIVE_MATCHES_FAILURE:
    case FETCH_FIXTURES_FOR_LIVE_FAILURE:
      return { ...state, isFetchingLiveMatches: false, ...action.payload };
    case FETCH_DATES:
      return { ...state, ...action.payload };
    case FETCH_LIVE_MATCH_DATE:
      return { ...state, date: action.payload };
    case FETCH_LIVE_MATCHES_DETAILS_START:
      return { ...state, isFetchingLiveMatches: true };
    case OPEN_DETAILS_SECTION:
      return { ...state, areLiveDetailsFetched: action.payload };
    case FETCH_IS_MOBILE_SIDEBAR_VISIBLE:
      return { ...state, ...action.payload };
    case FETCH_LIVE_MATCH:
      return { ...state, liveMatch: action.payload };
    case FETCH_LIVE_MATCH_ON_HOVER:
      return { ...state, ...action.payload };
    case IS_FETCHING_LIVE_DETAILS:
      return { ...state, ...action.payload };
    case FETCH_LIVE_MATCH_STATISTICS:
      return { ...state, isFetchingStatistics: true };
    case FETCH_LIVE_MATCH_STATISTICS_SUCCESS:
      return { ...state, isFetchingStatistics: false, ...action.payload };
    case FETCH_LINE_UPS_START:
      return { ...state, isFetchingLineUps: true };
    case FETCH_LINE_UPS_SUCCESS:
      return { ...state, isFetchingLineUps: false, ...action.payload };
    case RESET_LIVE_DETAILS:
      return {
        ...state,
        statistics: null,
        lineUps: null,
        liveEvents: null,
        isFetchingLineUps: true,
        isFetchingStatistics: true,
      };
    case LIVE_KEEP_FETCHING:
      return { ...state, isLiveKeepFetching: action.payload };
    case SHOW_ONLY_LIVE_MATCHES:
      return { ...state, ...action.payload };
    case RESET_LIVE:
      return INITIAL_STATE;
    case RESET_LIVE_MODALS:
      return {
        ...state,
        isMobileSidebarVisible: false,
        areLiveDetailsFetched: false,
      };
    default:
      return state;
  }
};
