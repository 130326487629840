import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { FaArrowAltCircleUp } from "react-icons/fa";

import { titles } from "./data";
import "./standings.scss";
import { trimString } from "../../helpers/trimString";
import Mixpanel from "../../utils/mixPanel";
const platform = typeof window !== "undefined" ? "web" : "mobile";
const Standings = ({ filteredStandings, standings, matchDetails, fixture }) => {
  const [isClicked, SetIsClicked] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState({});
  const toggleGroup = (group) => {
    setExpandedGroups({
      ...expandedGroups,
      [group]: !expandedGroups[group],
    });
    console.log({
      ...expandedGroups,
      [group]: !expandedGroups[group],
    });
  };
  return (
    <div className="standings-container">
      <div className="details-title">STANDINGS</div>
      <div className="standings">
        {filteredStandings &&
          Object.keys(filteredStandings).map((key, idx) => {
            return (
              <>
                <div className="details-title-group">{key}</div>
                <div className="row">
                  {titles.map((x, idx) => (
                    <div
                      key={idx}
                      className={`${
                        idx === 0
                          ? "colM"
                          : idx === 1
                          ? "colXl"
                          : idx === 10
                          ? "colS"
                          : "colL"
                      }`}
                    >
                      <span
                        className={`${
                          idx === 0 || idx === 10 ? "titleInvisible" : "title"
                        }`}
                      >
                        {x}
                      </span>
                    </div>
                  ))}
                </div>
                {!expandedGroups[key] &&
                  filteredStandings[key].map(
                    ({ all, goalsDiff, points, team, rank }, idx) => {
                      let { draw, goals, lose, played, win } = all;

                      return (
                        <>
                          {!matchDetails &&
                          filteredStandings[key][idx] &&
                          filteredStandings[key][idx]?.rank === 1 ? (
                            <span className="upper-row-group">{key}</span>
                          ) : null}
                          <div key={team.id} className="row2 ">
                            <div className="colM">
                              <span className="num">{rank}</span>
                            </div>
                            <div className="colWithFlag">
                              <img
                                className="img"
                                alt="logo"
                                src={team.logo}
                                onError={(e) => (e.target.src = team.logo)}
                              />
                              <span className="teamName">
                                {trimString(team.name, 20)} {"  "}
                              </span>
                            </div>

                            {[
                              played,
                              win,
                              draw,
                              lose,
                              goals.for,
                              goals.against,
                              goalsDiff,
                              points,
                            ].map((x, idx) => (
                              <div key={idx} className="colL">
                                <span className="txt">{x}</span>
                              </div>
                            ))}

                            <div className="colS">
                              <span className="titleInvisible">{idx}</span>
                            </div>
                          </div>
                        </>
                      );
                    }
                  )}
                {expandedGroups[key] &&
                  standings[key].map(
                    ({ all, goalsDiff, points, team, rank }, idx) => {
                      let { draw, goals, lose, played, win } = all;

                      return (
                        <>
                          {!matchDetails &&
                          standings[key][idx] &&
                          standings[key][idx]?.rank === 1 ? (
                            <span className="upper-row-group">{key}</span>
                          ) : null}
                          <div
                            key={team.id}
                            className={
                              team.id === fixture?.home_team.team_id ||
                              team.id === fixture?.away_team.team_id
                                ? "row2 active-team"
                                : "row2"
                            }
                          >
                            <div className="colM">
                              <span className="num">{rank}</span>
                            </div>
                            <div className="colWithFlag">
                              <img
                                className="img"
                                alt="logo"
                                src={team.logo}
                                onError={(e) => (e.target.src = team.logo)}
                              />
                              <span className="teamName">
                                {trimString(team.name, 20)} {"  "}
                              </span>
                            </div>

                            {[
                              played,
                              win,
                              draw,
                              lose,
                              goals.for,
                              goals.against,
                              goalsDiff,
                              points,
                            ].map((x, idx) => (
                              <div key={idx} className="colL">
                                <span className="txt">{x}</span>
                              </div>
                            ))}

                            <div className="colS">
                              <span className="titleInvisible">{idx}</span>
                            </div>
                          </div>
                        </>
                      );
                    }
                  )}
                {expandedGroups[key] && (
                  <div className="see-more-btn-cont">
                    <div className="see-more-btn">
                      {/* <button onClick={() => toggleGroup(key)}>see less</button> */}
                      <FaArrowAltCircleUp
                        onClick={() => {
                          Mixpanel.track(`Viewed all standings`, {
                            platform: platform,
                            application_type:
                              platform === "web" ? "web app" : "mobile app",
                          });
                          toggleGroup(key);
                        }}
                      />
                    </div>
                  </div>
                )}
                {!expandedGroups[key] && (
                  <div className="see-more-btn-cont">
                    <div className="see-more-btn">
                      {/* <button onClick={() => toggleGroup(key)}>see more</button> */}
                      <FaArrowAltCircleDown
                        onClick={() => {
                          Mixpanel.track(`Viewed all standings`, {
                            application_type:
                              platform === "web" ? "web app" : "mobile app",
                            platform: platform,
                          });
                          toggleGroup(key);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

Standings.propTypes = {
  standings: PropTypes.object,
  matchDetails: PropTypes.bool,
};

export default Standings;
