import { takeLatest, put, all, call, select } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_ATTRACTIONS_TYPE_DATA,
  CREATE_ATTRACTIONS_RECOMENDATION,
  FETCH_ATTRACTIONS_RECOMENDATION_TYPE_DATA,
} from "./attractionTypes";

import {
  getAttractionTypes,
  getCountriesList,
  createAttractionRecomendation,
  getAttractionRecomendation
} from "./attractionServices";

//ACTION
import {
  fetchAttractionType,
  createAttractionRecomendationAction,
  fetchAttractionRecomendationTypeData,
  fetchAttractionRecomendationType,
} from "./attractionActions";

function* fetchAttractionTypes({ payload }) {
  try {
    const res = yield call(getAttractionTypes);
    const countries = yield call(getCountriesList);
    yield put(
      fetchAttractionType({ type: "attractionTypes", data: res?.data?.data })
    );
    yield put(
      fetchAttractionType({
        type: "countriesList",
        data: countries?.data?.data,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* createAttractionRecomend({ payload }) {
  try {
    const res = yield call(createAttractionRecomendation, payload);

    if (res && res.data && res.data.success) {
      yield put(createAttractionRecomendationAction(res.data.data));
    } else {
      const errorMessage = res.data ? res.data.error : "Unknown error";
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error(error);
    yield put(createAttractionRecomendationAction(error.message));
  }
}

function* fetchAttractionRecomends({ payload }) {
  try {
    const res = yield call(getAttractionRecomendation, payload);
    yield put(fetchAttractionRecomendationType(res?.data?.data));
  } catch (e) {
    console.log(e);
  }
}

function* fetchAttractionData() {
  yield takeLatest(FETCH_ATTRACTIONS_TYPE_DATA, fetchAttractionTypes);
  yield takeLatest(CREATE_ATTRACTIONS_RECOMENDATION, createAttractionRecomend);
 
}
function* fetchAttractionRecomendation() {
  yield takeLatest(
    FETCH_ATTRACTIONS_RECOMENDATION_TYPE_DATA,
    fetchAttractionRecomends
  );
}

export default function* attractionSagas() {
  yield all([call(fetchAttractionData), call(fetchAttractionRecomendation)]);
  // yield all([call(fetchAttractionRecomendation)]);
}
