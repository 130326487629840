import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import FixturesListItems from "../../components/FixturesListItem/FixturesListItems";
import {
  fetchFixture,
  fetchSharedFixture,
  resetMatchDetails,
} from "../../redux/fixtureDetails/fixtureDetailsActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import { setFixture } from "../../redux/fixtures/fixturesActions";
import Mixpanel from "../../utils/mixPanel";
import { fetchWishlistSuccess } from "../../redux/auth/authActions";

const Match = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();  
  const [isValidRoute, setIsValidRoute] = useState(true); 
  const { sharedFixture } = useSelector((state) => state.fixtureDetails);
  const { circle, internalMap: map, markerSelected, marker } = useSelector((state) => state.map);
  const platform = typeof window !== "undefined" ? "web" : "mobile";
  const history = useHistory(); 
  const dispatch = useDispatch();

  const handleItemClick = (fixture) => {
    dispatch(resetMatchDetails());
    const { lat, lng } = fixture;
    dispatch(
      setFixture({
        insideRadiusFixtures: [fixture],
        outsideRadiusFixtures: [],
        leagues: [fixture.league],
      })
    );
    dispatch(
      fetchMapDetails({
        zoom: 13,
        center: { lat, lng },
        marker: fixture,
      })
    );
    dispatch(
      fetchSidebarDetails({
        areFixturesFetched: true,
        areFixturesDetailsFetched: true,
        isWishlistFrom: true,
        isSharedFixture:true
      })
    );
    dispatch(fetchFixture(fixture));
    setIsLoading(false)
    history.push("/home"); 
  };


  useEffect(() => {
    if (sharedFixture &&  Object.keys(sharedFixture).length>0) {
      handleItemClick(sharedFixture)
    } 
  }, [sharedFixture]);

  useEffect(() => {
    if (id) {
      const timeoutId = setTimeout(() => {
        // setIsLoading(false); 
        dispatch(
          fetchSharedFixture({
            fixture_id: id, 
          })
        )
      }, 3000);
      return () => clearTimeout(timeoutId);
    } else {
      setIsValidRoute(false); 
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!isValidRoute) {
      setIsLoading(false); 
      history.push("/"); 
    }
  }, [isValidRoute, history]);

  if (!isValidRoute) {
    return <div>Redirecting...</div>;
  }

  if (isLoading) {
    return (
      <div
      style={{
        zIndex: 99999999,
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        height: "100%",
        width: "100%",
        left: 0,
        top: 0,
      }}
    >
        <div className="spinner"></div>
        <style jsx>{`
          .spinner {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            border: 9px solid #dbdcef;
            border-right-color: #474bff;
            animation: spin 1s infinite linear;
          }

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div className="wishlist">
      <div className="top-heading">
        {/* <h1>Match</h1> */}
      </div>
      {/* Your match details render logic */}
    </div>
  );
};

export default Match;
