import React from "react";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";

import "./spinner.scss";

const Spinner = ({ styles }) => {
  return (
    <div className="spinner" style={styles}>
      <Loader
        type="TailSpin"
        color="#000000"
        height={40}
        width={40}
        timeout={100000}
      />
    </div>
  );
};

Spinner.propTypes = {
  styles: PropTypes.object
};

export default Spinner;
