import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { createStructuredSelector } from "reselect";

import "./liveMatchesList.scss";
import YellowCard from "../../assets/images/large-yellow card.png";
import LiveMatchListItem from "../LiveMatchListItem";
import { connect } from "react-redux";
import {
  selectFavMatches,
  selectShowOnlyFav
} from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import {
  selectShowOnlyLive,
  selectLiveMatches,
  selectIsFetchingLiveMatches
} from "../../redux/live/liveSelectors";
import { showOnlyFav } from "../../redux/favoriteMatches/favoriteMatchesActions";
import { sortByPriority } from "../../utils";
import { selectIsItFiltered } from "../../redux/filteringValues/filteringValuesSelectors";
import Ad from "../Ad/Ad";


const renderList = (
  liveMatches,
  leagues,
  liveDate,
  favMatchesIds,
  showOnlyFav,
  onlyLiveMatches,
  setIndex,
  refs,
  isFetching
) => {
  if (liveMatches && Object.values(liveMatches).flat().length && leagues) {
    let liveMatchesList = [];

    if (new Date(liveDate).getDate() === new Date().getDate()) {
      const sorted = sortByPriority(liveMatches, leagues);
      let isAdVisible = false;
      sorted.forEach((key) => {
        const doesItExist = (_id) =>
          showOnlyFav ? favMatchesIds.includes(_id) : true;
        const isLeagueActive =
          showOnlyFav && !onlyLiveMatches
            ? liveMatches[key].some((x) => favMatchesIds.includes(x._id))
            : onlyLiveMatches && !showOnlyFav
            ? liveMatches[key].some((x) => "events" in x)
            : true;

        isLeagueActive &&
          liveMatchesList.push(
            <div className="live-matches-league-name">
              <img
                src={leagues[key]?.logo}
                alt="logo"
                className="live-matches-league-logo"
                onError={(e) => e.target.src = leagues[key]?.logo}
              />
              <span className="live-matches-league-text">
                {leagues[key]?.name}
              </span>

              <span className="live-matches-league-text">
                {`${
                  leagues[key]?.country !== "World"
                    ? "(" + leagues[key]?.country + ")"
                    : ""
                }`}
              </span>
            </div>
          );

        liveMatches[key]
          .sort((a, b) => new Date(a.event_date) - new Date(b.event_date))
          .forEach((fixture, index) => {
            if (
              !isAdVisible &&
              liveMatches[key].length > 2 &&
              index === 2 &&
              !showOnlyFav &&
              !onlyLiveMatches
            ) {
              liveMatchesList.push(
                <Ad
                  slot="3627060370"
                  styles={{
                    marginTop: 0,
                    marginLeft: 10,
                    width: 418,
                    height: 140
                  }}
                />
              );

              isAdVisible = true;
            }

            if (doesItExist(fixture._id) && !onlyLiveMatches) {
              return liveMatchesList.push(
                <LiveMatchListItem
                  refs={refs}
                  key={fixture.fixture_id}
                  index={index}
                  fixture={fixture}
                  setIndex={setIndex}
                />
              );
            }
            if (onlyLiveMatches && "events" in fixture) {
              liveMatchesList.push(
                <LiveMatchListItem
                  refs={refs}
                  key={fixture.fixture_id}
                  index={index}
                  fixture={fixture}
                  setIndex={setIndex}
                />
              );
            }
          });
      });
    } else {
      const sorted = sortByPriority(liveMatches, leagues);
      let isAdVisible = false;
      sorted.forEach((key) => {
        const doesItExist = (_id) =>
          showOnlyFav ? favMatchesIds.includes(_id) : true;
        const isLeagueActive = showOnlyFav
          ? liveMatches[key].some((x) => favMatchesIds.includes(x._id))
          : true;

        isLeagueActive &&
          liveMatchesList.push(
            <div className="live-matches-league-name">
              <img
                src={leagues[key]?.logo}
                alt="logo"
                className="live-matches-league-logo"
                onError={(e) => e.target.src = leagues[key]?.logo}
              />
              <span className="live-matches-league-text">
                {leagues[key]?.name}
              </span>

              <span className="live-matches-league-text">
                {` (${leagues[key]?.country})`}
              </span>
            </div>
          );
        liveMatches[key]
          .sort(
            (a, b) =>
              new Date(a.event_date).getTime() -
              new Date(b.event_date).getTime()
          )
          .forEach((fixture, index) => {
            if (
              !isAdVisible &&
              liveMatches[key].length > 2 &&
              index === 2 &&
              !showOnlyFav &&
              !onlyLiveMatches
            ) {
              liveMatchesList.push(
                <div style={{ marginBottom: 20 }}>
                  <Ad slot="3627060370" styles={{ width: 400, height: 120 }} />
                </div>
              );

              isAdVisible = true;
            }

            doesItExist(fixture._id) &&
              liveMatchesList.push(
                <LiveMatchListItem
                  refs={refs}
                  key={fixture.fixture_id}
                  fixture={fixture}
                  setIndex={setIndex}
                />
              );
          });
      });
    }

    return liveMatchesList;
  } else {
    return liveMatches &&
      Object.values(liveMatches).flat().length === 0 &&
      !isFetching ? (
      <div className="yellow-card-con">
        <img className="yellow-card" src={YellowCard} alt="card"  onError={(e) => e.target.src = YellowCard} />
        <span className="yellow-card-text">
          We have just got a yellow card, for not serving you the data
        </span>
      </div>
    ) : null;
  }
};

const LiveMatchesList = ({
  liveMatches,
  liveMatchesList,
  leagues,
  liveDate,
  favMatchesIds,
  isItFiltered,
  showOnlyFav,
  itemRef,
  onlyLiveMatches,
  showOnlyFavMatches,
  setIndex,
  refs,
  isFetching
}) => {
  useEffect(() => {
    if (liveMatches) {
      const cond = Object.values(liveMatches)
        .flat()
        .some((x) => favMatchesIds.includes(x._id));
      if (!cond) {
        showOnlyFavMatches(false);
      }
    }
  }, [favMatchesIds, liveMatches]);
  return (
    <div
      className={`live-matches-list ${
        new Date(liveDate).getDate() === new Date().getDate() &&
        liveMatchesList &&
        !Object.values(liveMatchesList).length
          ? "live-matches-list-not-today"
          : ""
      }
      ${
        (new Date(liveDate).getDate() !== new Date().getDate() &&
          isItFiltered) ||
        (new Date(liveDate).getDate() === new Date().getDate() &&
          liveMatchesList &&
          Object.values(liveMatchesList).length)
          ? "filtered-not-live"
          : ""
      }
      
      `}
    >
      <div ref={itemRef}></div>
      {renderList(
        liveMatches,
        leagues,
        liveDate,
        favMatchesIds,
        showOnlyFav,
        onlyLiveMatches,
        setIndex,
        refs,
        isFetching
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  favMatchesIds: selectFavMatches,
  showOnlyFav: selectShowOnlyFav,
  onlyLiveMatches: selectShowOnlyLive,
  liveMatchesList: selectLiveMatches,
  isFetching: selectIsFetchingLiveMatches,
  isItFiltered: selectIsItFiltered
});

const mapDispatchToProps = (dispatch) => ({
  showOnlyFavMatches: (payload) => dispatch(showOnlyFav(payload))
});

LiveMatchesList.propTypes = {
  liveMatches: PropTypes.object,
  isItFiltered: PropTypes.bool,
  leagues: PropTypes.object,
  liveDate: PropTypes.string,
  liveMatchesList: PropTypes.object,
  favMatchesIds: PropTypes.array,
  refs: PropTypes.object,
  showOnlyFav: PropTypes.bool,
  itemRef: PropTypes.object,
  onlyLiveMatches: PropTypes.bool,
  showOnlyFavMatches: PropTypes.func,
  setIndex: PropTypes.func,
  isFetching: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveMatchesList);
