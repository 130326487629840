import axios from "axios";
import { FAM_API } from "../constants";
export const getFixturesForLive = async (date, userLocation) => {
  try {
    const { lat, lng } = userLocation;
    const res = await axios.get(
      `${FAM_API}/fixtures-by-dates?dates=${date}&coordinates=${lat},${lng}`
    );

    const fixtures = res.data.data.fixtures.reduce((a, c) => {
      if (a[c["league_id"]]) {
        a[c["league_id"]].push(c);
      } else {
        a[c["league_id"]] = [];
        a[c["league_id"]].push(c);
      }
      return a;
    }, {});
    if (!Object.values(fixtures).flat().length) {
      throw new Error();
    } else {
      return { fixtures };
    }
  } catch (error) {
    return { error: "Could not fetch fixtures of the date." };
  }
};
