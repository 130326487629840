import React from "react";
import { Modal } from "react-responsive-modal";
import { RiCloseLine } from "react-icons/ri";
import { showSuccess } from "../ToastMessage";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import "./shareModal.scss";

const ShareModal = ({ shareModal, setShareModal, fixtureId }) => {
  const link = `${window.origin}/fixture/${fixtureId}`;
  return (
    <Modal
      styles={{
        modal: {
          padding: 25,
          borderRadius: 10,
          maxWidth: "400px",
          width: "100%",
        },
      }}
      showCloseIcon={false}
      open={shareModal}
      onClose={() => setShareModal(false)}
      center
    >
      <div className="share-modal">
        <button onClick={() => setShareModal(false)} className="modal-closeBtn" >
          <RiCloseLine />
        </button>
        <h1 className="share-heading">Share</h1>

        <div className="copy-container">
          <div className="link-input">
            <input value={link} readOnly />
          </div>
          <button
            className="copy-btn"
            onClick={() => {
              navigator.clipboard.writeText(link);
              showSuccess("copied");
            }}
          >
            Copy
          </button>
        </div>

        <div className="social-share">
          <FacebookShareButton url={link} title="Check out this match I found on Football Around Me">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton url={link} title="Check out this match I found on Football Around Me">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <WhatsappShareButton url={link} title="Check out this match I found on Football Around Me">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <LinkedinShareButton url={link} title="Check out this match I found on Football Around Me">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
