import React from "react";
import AuthScreen from "../../components/AuthScreen/authscreen";
const SignIn = () => {
  return (
    <>
      <AuthScreen title="Sign In" btnText="Login" />
    </>
  );
};

export default SignIn;
