import { createSelector } from "reselect";

const selectScreenDimensions = (state) => state.screenDimensions;

export const selectWidth = createSelector(
  selectScreenDimensions,
  (screenDimensions) => screenDimensions.width
);

export const selectHeight = createSelector(
  selectScreenDimensions,
  (screenDimensions) => screenDimensions.height
);
