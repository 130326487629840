import React from "react";
import Select from "react-select";

const CustomSelect = ({
  options,
  placeholder = "Select...",
  onChange,
  value,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#ffff",
      borderRadius: "8px",
      padding: "0 5px", // Remove extra padding to control height better
      fontSize: "16px",
      height: "50px", // Set the total height
      display: "flex",
      alignItems: "center", // Vertically center the content
      width: "100%",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "50px", // Set value container height
      display: "flex",
      alignItems: "center",
      padding: "0 8px", // Adjust padding
    }),
    input: (provided) => ({
      ...provided,
      height: "50px", // Set input height
      margin: "0",
      padding: "0", // Reset input padding
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "50px", // Set indicator (dropdown arrow) container height
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#dfe6e9" : "#fff",
      color: "#333",
      padding: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333",
    }),
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      placeholder={placeholder}
      onChange={onChange}
      isClearable
      value={value}
    />
  );
};

export default CustomSelect;
