import { createSelector } from "reselect";

const selectFormValues = (state) => state.formValues;

export const selectFormValuesRadius = createSelector(
  selectFormValues,
  (formValues) => formValues.radius
);

export const selectFormValuesAddress = createSelector(
  selectFormValues,
  (formValues) => formValues.address
);

export const selectFormValuesDates = createSelector(
  selectFormValues,
  (formValues) => { return formValues.dates; }
);

export const selectFormValuesCountryCode = createSelector(
  selectFormValues,
  (formValues) => formValues.countryCode
);

export const selectFormValuesSearchedLocation = createSelector(
  selectFormValues,
  (formValues) => formValues.searchedLocation
);

export const selectFormValuesCurrentDate = createSelector(
  selectFormValues,
  (formValues) => formValues.currentDate
);

export const selectorSelectDay = createSelector(
  selectFormValues,
  (formValues) => formValues.selectDay
);