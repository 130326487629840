import React from 'react';

//COMPONENTS
import { Boy } from './Boy';
import CustomApplyButton from "../CustomApplyButton"; 

//STYLES
import './styles.scss'

const bc = 'match-not-found';

const MatchNotFound = ({viewType , handlePressUp  ,dates ,currentSelectedDay} )=>{    
    const index = dates.findIndex((d)=>{
        if(d === currentSelectedDay)return true
        })

    const text = dates.length === 1 || index+1 === dates.length?"Go back" : "Next day";
    return (
        <div className={bc}>
           <div className={'text'}><span>Sorry, no matches found for this location and timeframe. Please try a different search.</span></div>
           <div className={'button'}>                
                <CustomApplyButton classNameContainer={'button-styles'} text={"New search"} handlePress={handlePressUp}/>
           </div>
           <div className={'image'}>
               <Boy/>
            </div> 
         </div>   
    )
}
export default MatchNotFound;