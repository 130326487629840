import React from "react";
import "./styles.scss";
import { trimString } from "../../helpers/trimString";
import { BsCheck } from "react-icons/bs";
const bc = "tag";

const SelectedLeagues = ({ tags, onClose }) => {
  const handleClose = (item) => {
    if (onClose) {
      onClose(item);
    }
  };

  return (
    <div
      className={`leagues-list-container 
      `}
    >
      <div className={`leagues-list`}>
        {tags
          .sort((a, b) => a.priority - b.priority)
          .map((league) => (
            <div
              onClick={() => handleClose(league)}
              className={`league-item 
                  "selected-league-item"
                 `}
            >
              <img
                className="league-logo"
                src={league?.logo}
                alt="logo"
                onError={(e) => (e.target.src = league?.logo)}
              />
              <span className="league-name">
                {trimString(league?.name, 25)}
              </span>
              {
                <div className="checkmark-icon">
                  <BsCheck color="white" size={18} />
                </div>
              }
              <span className="country-name">
                {league?.country === "World"
                  ? league?.region
                  : trimString(league?.country, 25)}
              </span>
            </div>
          ))}
      </div>
      <div></div>
    </div>
  );
};

export default SelectedLeagues;
