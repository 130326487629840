import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { MdAccessTime } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";

import "./liveMatchListItem.scss";
import ErrorMessage from "../ErrorMessage";
import {
  openDetailsSection,
  fetchLiveMatch,
  fetchLiveMatchOnHover,
  resetLiveDetails
} from "../../redux/live/liveActions";
import { trimString } from "../../helpers/trimString";
import {
  selectLiveMatchesDate,
  selectFixturesForLive,
  selectLiveMatch
} from "../../redux/live/liveSelectors";
import { resetMatchDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { selectFavMatches } from "../../redux/favoriteMatches/favoriteMatchesSelectors";
import {
  fetchFavMatch,
  removeFavMatch
} from "../../redux/favoriteMatches/favoriteMatchesActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { selectInternalMap } from "../../redux/map/mapSelectors";
import { animateCamera } from "../../utils";

const LiveMatchListItem = ({
  fixture,
  openDetailsSection,
  liveDate,
  fetchLiveMatch,
  resetMatchDetails,
  favMatchesIds,
  fetchFavMatch,
  removeFavMatch,
  fetchLiveMatchOnHover,
  resetLiveDetails,
  fetchMapDetails,
  liveMatch,
  refs,
  map,
  details,
  fixturesForLive,
  setIndex
}) => {
  const {
    home_team,
    // homeTeam,
    away_team,
    // awayTeam,
    score,
    statusShort,
    elapsed,
    goalsAwayTeam,
    // venue,
    goalsHomeTeam,
    _id,
    fixture_id
  } = fixture || {};
  const timeScore =
    goalsAwayTeam || goalsHomeTeam
      ? `${goalsHomeTeam} : ${goalsAwayTeam}`
      : (fixture?.score && score?.fulltime) || score?.halftime;

  const handleLiveMatchClick = (fixture) => {
    resetMatchDetails();
    resetLiveDetails();
    openDetailsSection(true);
    fetchLiveMatch(fixture);
    setIndex(1);

    const { lat, lng } = fixture;

    if (map) {
      if (fixture?.distance) {
        var bounds = new window.google.maps.LatLngBounds();

        bounds.extend({ lat, lng });

        map.fitBounds(bounds);
        const zoom = map.getZoom();
        map.setZoom(zoom - 15);
      } else {
        const markers = Object.values(fixturesForLive[liveDate]).flat();

        animateCamera(markers, map);
      }
    }
    fetchMapDetails({
      center: { lat, lng },
      marker: fixture
    });
  };
  if (fixture) {
    return (
      <Sentry.ErrorBoundary key={fixture_id} fallback={<ErrorMessage />}>
        <div
          ref={refs && refs[fixture_id]}
          onMouseEnter={() =>
            !details &&
            fixture?.distance &&
            fetchLiveMatchOnHover({ liveMatchOnHover: fixture })
          }
          onMouseLeave={() =>
            !details &&
            fixture?.distance &&
            fetchLiveMatchOnHover({ liveMatchOnHover: null })
          }
          className={`live-match-list-item ${details ? "on-live-details" : ""}`}
        >
          <div
            className={`upper-content ${
              !details &&
              liveMatch?.fixture_id === fixture_id &&
              "upper-content-focus"
            }`}
          >
            <div className="live-match-team">
              <span className="live-match-teamName">
                {trimString(home_team?.name, 16)}
              </span>
              <img
                className="live-match-teamLogo live-match-homeTeam-logo"
                src={home_team?.logo}
                alt="logo"
                onError={(e) => e.target.src = home_team?.logo}
              />
            </div>
            <div className="live-match-score">
              {statusShort === "NS" || statusShort === "TBD"
                ? moment(fixture?.event_date).format("HH:mm")
                : timeScore && timeScore.split("").join(" ")}
            </div>
            <div className="live-match-team live-match-awayTeam">
              <img
                className="live-match-teamLogo live-match-awayTeam-logo"
                src={away_team?.logo}
                alt="logo"
                onError={(e) => e.target.src = away_team?.logo}
              />
              <span className="live-match-teamName">
                {trimString(away_team?.name, 16)}
              </span>
            </div>
          </div>
          <div
            className={`lower-content ${
              !details &&
              liveMatch?.fixture_id === fixture_id &&
              "lower-content-focus"
            }`}
          >
            <div className="lower-content-left-side ">
              {"events" in fixture &&
              new Date().getDate() === new Date(liveDate).getDate() ? (
                <div
                  className={`${
                    "events" in fixture !== false ? "gap" : "no-gap"
                  } live-box`}
                >
                  <span className="live-dot"></span>
                  <span>Live</span>
                </div>
              ) : statusShort !== "NS" ? (
                <div
                  className={`${
                    "events" in fixture !== false ? "gap" : "no-gap"
                  } live-time`}
                >
                  <MdAccessTime color="#5b5b5b" size={13} />
                  <span>{moment(fixture?.event_date).format("HH:mm")}</span>
                </div>
              ) : null}

              {"events" in fixture ? (
                <div
                  className={`${
                    "events" in fixture !== false ? "gap" : "no-gap"
                  } elapsed-time`}
                >
                  <MdAccessTime color="#fff" size={13} />
                  <span>
                    {elapsed || moment(fixture?.event_date).format("HH:mm")}
                  </span>
                </div>
              ) : null}
              <div
                className={`${
                  "events" in fixture !== false ? "gap" : "no-gap"
                } live-ft`}
              >
                {statusShort}
              </div>
              <div
                className={`${
                  "events" in fixture !== false ? "gap" : "no-gap"
                } live-match-distance`}
              >
                <div className="flex-row">
                  Distance:{" "}
                  <span className="live-match-radius">
                    {fixture?.distance
                      ? Math.round(fixture?.distance) + "Km"
                      : "N/A"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`lower-content-right-side ${
                details ? "lower-content-right-side--right" : ""
              }`}
            >
              {!details && (
                <div
                  className="match-detail-btn"
                  onClick={() => handleLiveMatchClick(fixture)}
                >
                  Match Details
                </div>
              )}
              <div
                onClick={() => {
                  favMatchesIds.includes(_id)
                    ? removeFavMatch(_id)
                    : fetchFavMatch(_id);
                }}
                className={`live-match-star ${
                  favMatchesIds.includes(_id) && "highlighted"
                }`}
              >
                <AiFillStar color="white" size={19} />
              </div>
            </div>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    );
  } else {
    return "loading";
  }
};

LiveMatchListItem.propTypes = {
  fixture: PropTypes.object,
  index: PropTypes.number,
  openDetailsSection: PropTypes.func,
  liveDate: PropTypes.string,
  fetchLiveMatch: PropTypes.func,
  resetMatchDetails: PropTypes.func,
  favMatchesIds: PropTypes.array,
  fetchFavMatch: PropTypes.func,
  removeFavMatch: PropTypes.func,
  fetchLiveMatchOnHover: PropTypes.func,
  resetLiveDetails: PropTypes.func,
  fetchMapDetails: PropTypes.func,
  map: PropTypes.object,
  details: PropTypes.bool,
  fixturesForLive: PropTypes.object,
  setIndex: PropTypes.func,
  refs: PropTypes.object,
  liveMatch: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  liveDate: selectLiveMatchesDate,
  favMatchesIds: selectFavMatches,
  liveMatch: selectLiveMatch,

  map: selectInternalMap,
  fixturesForLive: selectFixturesForLive
});

const mapDispatchToProps = (dispatch) => ({
  openDetailsSection: (payload) => dispatch(openDetailsSection(payload)),
  fetchLiveMatch: (payload) => dispatch(fetchLiveMatch(payload)),
  resetMatchDetails: () => dispatch(resetMatchDetails()),
  fetchFavMatch: (payload) => dispatch(fetchFavMatch(payload)),
  removeFavMatch: (payload) => dispatch(removeFavMatch(payload)),
  fetchLiveMatchOnHover: (payload) => dispatch(fetchLiveMatchOnHover(payload)),
  resetLiveDetails: () => dispatch(resetLiveDetails()),
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveMatchListItem);
