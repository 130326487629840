import React, { useEffect, useRef, useState } from "react";
import "./slider-modal.scss";
import Slider from "react-slick";
import { RiCloseLine } from "react-icons/ri";
import { Modal } from "react-responsive-modal";
import stadiumFallBack from "../../assets/images/stadium.jpeg";

const SliderModal = ({ setSliderModal, sliderModal, stadiumImages }) => {
  const slickRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    lazyLoad: true,
    arrows: true,
    dots: false,
  };

  useEffect(() => {
    if (slickRef && slickRef.current) {
      slickRef.current.slickGoTo(0);
      setCurrentSlide(0);
    }
  }, [sliderModal, stadiumImages]);

  const imgList =
    stadiumImages &&
    stadiumImages.length > 0 &&
    stadiumImages.map((image, index) => (
      <div key={index} className="image-container">
        <img
          src={image.src}
          alt={`football ${index + 1}`}
          onError={(e) => (e.target.src = stadiumFallBack)}
        />
      </div>
    ));

  const handleKeyDown = (e) => {
    if (e.key === "ArrowLeft") {
      e.preventDefault();
      goToPrevSlide();
    } else if (e.key === "ArrowRight") {
      e.preventDefault();
      goToNextSlide();
    }
  };

  const goToPrevSlide = () => {
    if (slickRef && slickRef.current) {
      slickRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (slickRef && slickRef.current) {
      slickRef.current.slickNext();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentSlide]);

  return (
    <Modal
      styles={{ modal: { padding: 0, borderRadius: 10 } }}
      showCloseIcon={false}
      open={sliderModal}
      onClose={() => setSliderModal(false)}
      center
    >
      <div className="slider-modal-container">
        <div className="slider-modal-content">
          <RiCloseLine
            onClick={() => setSliderModal(false)}
            className="closeIcon"
            size={30}
            color="#2e2e2e"
          />
          <div className="stadium-image">
            <Slider ref={slickRef} {...settings}>
              {imgList}
            </Slider>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SliderModal;
