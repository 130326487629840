import {
  LOGIN_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  SET_STATE,
  LOGIN_REQUEST,
  LOGOUT,
  FETCH_WISHLIST_SUCCESS,
  FETCH_WISHLIST_REQUEST,
  FETCH_WISHLIST_FAILURE,
} from "./authTypes";

export const loginRequest = (data) => ({
  type: LOGIN_REQUEST,
  payload: data,
});

export const setAuthState = (payload) => ({
  type: SET_STATE,
  payload,
});

export const loginCallStart = () => ({
  type: LOGIN_START,
});

export const loginCallSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});
export const logout = (payload) => ({
  type: LOGOUT,
  payload,
});

export const loginCallFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload,
});

export const fetchWishlistRequest = (userId) => ({
  type: FETCH_WISHLIST_REQUEST,
  payload: userId,
});

export const fetchWishlistSuccess = (wishlist) => ({
  type: FETCH_WISHLIST_SUCCESS,
  payload: wishlist,
});

export const fetchWishlistFailure = (error) => ({
  type: FETCH_WISHLIST_FAILURE,
  payload: error,
});
