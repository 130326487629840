import {
  FETCH_FAV_MATCH,
  REMOVE_FAV_MATCH,
  SHOW_ONLY_FAV,
} from "./favoriteMatchesTypes";

export const fetchFavMatch = (payload) => ({
  type: FETCH_FAV_MATCH,
  payload,
});

export const removeFavMatch = (payload) => ({
  type: REMOVE_FAV_MATCH,
  payload,
});

export const showOnlyFav = (payload) => ({
  type: SHOW_ONLY_FAV,
  payload,
});
