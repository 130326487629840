import {
  FETCH_FAV_MATCH,
  REMOVE_FAV_MATCH,
  SHOW_ONLY_FAV,
} from "./favoriteMatchesTypes";

const INITIAL_STATE = {
  favoriteMatches: [],
  showOnlyFav: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FAV_MATCH:
      return {
        ...state,
        favoriteMatches: [...state.favoriteMatches, action.payload],
      };
    case REMOVE_FAV_MATCH:
      return {
        ...state,
        favoriteMatches: state.favoriteMatches.filter(
          (x) => x !== action.payload
        ),
        showOnlyFav:
          state.favoriteMatches.filter((x) => x !== action.payload).length === 0
            ? false
            : state.showOnlyFav,
      };

    case SHOW_ONLY_FAV:
      return { ...state, showOnlyFav: action.payload };
    default:
      return state;
  }
};
