export const animateCamera = (markersList, map) => {
  const markers =
    markersList && markersList.length && markersList.filter((x) => x.lat);    
  if (markers && markers.length && map) {
    var bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < markers.length; i++) {
      bounds.extend(markers[i]);      
    }
    console.log(bounds, 'markersList', markersList)
    map.fitBounds(bounds);
  }
};
