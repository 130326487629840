import {
  FETCH_ATTRACTIONS_TYPE,
  CREATE_ATTRACTIONS_RECOMENDATION_ACTION,
  FETCH_ATTRACTIONS_RECOMENDATION_TYPE
} from "./attractionTypes";

const INITIAL_STATE = {
  attractionTypes: [],
  countriesList: [],
  stadiums: [],
  attractionRecomendation: [],
  attractionRecomendationCreateRes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ATTRACTIONS_TYPE:
      return { ...state, [action.payload.type]: action.payload.data };
    case CREATE_ATTRACTIONS_RECOMENDATION_ACTION:
      return {
        ...state,
        attractionRecomendationCreateRes: action.payload,
      };
    case FETCH_ATTRACTIONS_RECOMENDATION_TYPE:
      return {
        ...state,
        attractionRecomendation: action.payload,
      };
    default:
      return state;
  }
};
