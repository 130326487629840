import { FETCH_SORTING_VALUES } from "./sortingValuesTypes";

const INITIAL_STATE = {
  order: "asc",
  sortByDate: true,
  sortByDistance: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SORTING_VALUES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
