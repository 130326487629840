import React from "react";
import AdSense from "react-adsense";
import PropTypes from "prop-types";

const Ad = ({ styles, slot }) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return <></>
  }
  
  return (
    <AdSense.Google
      client="ca-pub-9526203211305602"
      slot={slot}
      style={styles}
      format=""
      responsive="true"
      layoutKey="-gw-1+2a-9x+5c"
    />
  );
};

Ad.propTypes = {
  styles: PropTypes.object,
  slot: PropTypes.string,
};

export default Ad;
