const GoogleImages = require("google-images");
const client = new GoogleImages(
  "b451f433a1d674709",
  "AIzaSyAR8ICUweXHZ8zsKKPNA3kPq4mljCGHF-Y"
);
// const client = new GoogleImages( old keys
//     "018156954163861827791:9rem_frpzaa",
//     "AIzaSyDaN81scQ7ZxM7pc8aagTjdx-O2QbXRjPs"
//   );
export const fetchGoogleImages = async (venue) => {
  try {
    const images = await client.search(venue);
    return images;
  } catch (error) {
    console.error("Error fetching images:", error);
    return null;
  }
};
