import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-responsive-modal";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import SortModalCustomButtons from "../SortModalCustomButtons";
import CustomApplyButton from "../CustomApplyButton/CustomApplyButton";
import {
  selectSortByDistance,
  selectSortByDate,
  selectOrder,
} from "../../redux/sortingValues/sortingValuesSelectors";
import { fetchSortingValues } from "../../redux/sortingValues/sortingValuesActions";
import "./sortModal.scss";

const SortModal = ({
  sortModal,
  setSortModal,
  order,
  sortByDistance,
  sortByDate,
  fetchSortingValues,
}) => {
  const [sortingValues, setSortingValues] = useState({
    sortByDate: sortByDate,
    sortByDistance: sortByDistance,
    order: order,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    setSortingValues((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleClick = () => {
    const { sortByDate, sortByDistance } = sortingValues;
    if (!sortByDate && !sortByDistance) {
      fetchSortingValues({
        ...sortingValues,
        sortByDate: true,
        sortByDistance: true,
      });
    } else {
      fetchSortingValues(sortingValues);
    }

    setSortModal(false);
  };

  return (
    <Modal
      styles={{ modal: { borderRadius: 10 } }}
      showCloseIcon={false}
      open={sortModal}
      onClose={() => setSortModal(false)}
      center
    >
      <div className="sort-modal">
        <p className="sort-modal-title">Sort fixtures by date and distance</p>
        <div className="sort-modal-row1">
          <label className="container">
            <span className="checkbox-text">Sort By Date</span>
            <input
              name="sortByDate"
              checked={sortingValues.sortByDate}
              type="checkbox"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="sort-modal-row1">
          <label className="container">
            <span className="checkbox-text">Sort By Kilometer</span>
            <input
              name="sortByDistance"
              checked={sortingValues.sortByDistance}
              type="checkbox"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="sort-modal-buttons">
          <SortModalCustomButtons
            text="Ascending Order"
            type="asc"
            order={sortingValues.order}
            callback={() =>
              setSortingValues((prevState) => ({ ...prevState, order: "asc" }))
            }
          />
          <SortModalCustomButtons
            text="Descending Order"
            type="desc"
            order={sortingValues.order}
            callback={() =>
              setSortingValues((prevState) => ({ ...prevState, order: "desc" }))
            }
          />
        </div>

        <CustomApplyButton text="Apply" handlePress={() => handleClick()} />
      </div>
    </Modal>
  );
};

const mapStateTopProps = createStructuredSelector({
  sortByDistance: selectSortByDistance,
  sortByDate: selectSortByDate,
  order: selectOrder,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSortingValues: (payload) => dispatch(fetchSortingValues(payload)),
});

SortModal.propTypes = {
  sortModal: PropTypes.bool,
  setSortModal: PropTypes.func,
  order: PropTypes.string,
  sortByDistance: PropTypes.bool,
  sortByDate: PropTypes.bool,
  fetchSortingValues: PropTypes.func,
};

export default connect(mapStateTopProps, mapDispatchToProps)(SortModal);
