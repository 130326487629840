import {
  FETCH_LEAGUES_START,
  FETCH_LEAGUES_SUCCESS,
  FETCH_LEAGUES_FAILURE,
  FETCH_SELECTED_LEAGUE,
  FETCH_SELECTED_TEAM,
  FETCH_SELECTED_STADIUM,
} from "./leaguesTypes";

const INITIAL_STATE = {
  leagues: [],
  selectedLeagues: [],
  selectedTeams: [],
  selectedStadiums: [],
  isFetching: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LEAGUES_START:
      return { ...state, isFetching: true };
    case FETCH_LEAGUES_SUCCESS:
      return { ...state, isFetching: false, leagues: action.payload };

    case FETCH_LEAGUES_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    case FETCH_SELECTED_LEAGUE:
      return {
        ...state,
        selectedLeagues: action.payload,
      };
    case FETCH_SELECTED_TEAM:
      return {
        ...state,
        selectedTeams: action.payload,
      };
    case FETCH_SELECTED_STADIUM:
      return {
        ...state,
        selectedStadiums: action.payload,
      };
    default:
      return state;
  }
};
