export const sortFixtures = (fixtures, sortingValues) => {
  const { sortByDate, sortByDistance, order } = sortingValues;
  if (sortByDate && sortByDistance) {
    let sorted;
    if (order === "asc") {
      sorted = fixtures.slice().sort((a, b) => {
        if (
          new Date(a.event_date).getDate() == new Date(b.event_date).getDate()
        ) {
          return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
        } else {
          return new Date(a.event_date) < new Date(b.event_date) ? -1 : 1;
        }
      });
    } else {
      sorted = fixtures.slice().sort((a, b) => {
        if (
          new Date(a.event_date).getDate() == new Date(b.event_date).getDate()
        ) {
          return a.distance > b.distance ? -1 : a.distance < b.distance ? 1 : 0;
        } else {
          return new Date(a.event_date) > new Date(b.event_date) ? -1 : 1;
        }
      });
    }

    return sorted;
  } else if (sortByDate && !sortByDistance) {
    return fixtures
      .slice()
      .sort((a, b) =>
        order === "asc"
          ? new Date(a.event_date) - new Date(b.event_date)
          : new Date(b.event_date) - new Date(a.event_date)
      );
  } else if (sortByDistance && !sortByDate) {
    return fixtures
      .slice()
      .sort((a, b) =>
        order === "asc"
          ? new Date(a.distance) - new Date(b.distance)
          : new Date(b.distance) - new Date(a.distance)
      );
  }
};
