import React from "react";
import PropTypes from "prop-types";

import H2hRow from "../H2hRow";
import "./h2h.scss";

const H2h = ({ h2h, fixture: { home_team, away_team } }) => {
  return (
    <div className="h2h-container">
      <div className="h2h-title">FACE TO FACE MATCHES</div>
      {h2h &&
        h2h.map((match) => (
          <H2hRow
            key={match.fixture.id}
            fixture={match}
            home_team={home_team}
            away_team={away_team}
          />
        ))}
    </div>
  );
};

H2h.propTypes = {
  h2h: PropTypes.array,
  fixture: PropTypes.object,
};

export default H2h;
