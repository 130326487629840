import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";

import { ReactComponent as StadiumImage } from "../../assets/images/medium.svg";
import PubIcon from "../../assets/images/pubWithoutFlag.png";
import "./reviewsMap.scss";
import { animateCamera } from "../../utils";

const Pub = () => (
  <img src={PubIcon} alt="pub" className="reviews-modal-supporterIcon" onError={(e) => e.target.src = PubIcon} />
);

const Stadium = () => <StadiumImage className="reviews-modal-stadiumIcon" />;

const polyline = (supporterLocation, center, internalMap) => {
  var lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    strokeWeight: 1,
    scale: 4,
  };
  const coords = [ center];
  if(supporterLocation) coords.unshift(supporterLocation);
  const flightPath = new window.google.maps.Polyline({
    path: coords,

    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "20px",
      },
    ],
  });

  flightPath.setMap(internalMap);
};

const ReviewsMap = ({ center, supporterLocation }) => {
  const { lat, lng } = center;
  const [internalMap, setInternalMap] = useState(null);

  useEffect(() => {
    if (internalMap) {
      const markers = [center];

      if(supporterLocation) markers.unshift(supporterLocation);      
      
      animateCamera(internalMap, markers);
    }

    polyline(supporterLocation, center, internalMap);
  }, [internalMap]);

  return (
    <div className="reviews-map-container">
      <GoogleMapReact
        defaultCenter={center}
        zoom={12}
        options={{
          scrollwheel: false,
          draggable: false,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          setInternalMap(map);
        }}
      >
        <Stadium lat={lat} lng={lng} />
        {supporterLocation?<Pub lat={supporterLocation.lat} lng={supporterLocation.lng} /> : null}
      </GoogleMapReact>
    </div>
  );
};

ReviewsMap.propTypes = {
  center: PropTypes.object,
  supporterLocation: PropTypes.object,
};

export default ReviewsMap;
