import axios from "axios";
import { FAM_API } from "../constants/constants";

export const getLiveMatches = async () => {
  try {
    const res = await axios.get(`${FAM_API}/live-fixtures`);
    const fixtures = res.data.data.liveFixtures.reduce((a, c) => {
      if (a[c["fixture_id"]]) {
        a[c["fixture_id"]].push(c);
      } else {
        a[c["fixture_id"]] = [];
        a[c["fixture_id"]].push(c);
      }
      return a;
    }, {});

    return { fixtures };
  } catch (error) {
    return { error: "Could not fetch live matches." };
  }
};
