import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  FETCH_LIVE_MATCHES_START,
  FETCH_FIXTURES_FOR_LIVE_START,
  FETCH_LIVE_MATCH_STATISTICS,
  FETCH_LINE_UPS_START,
} from "./liveTypes";
import {
  fetchLiveMatchesDate,
  fetchLiveMatchesSuccess,
  fetchLiveMatchesFailure,
  fetchFixturesForLiveSuccess,
  fetchFixturesForLiveFailure,
  fetchLiveMatchesStart,
  isFetchingLiveDetails,
  fetchLiveMatchStatisticsSuccess,
  fetchLiveFixturesLeagues,
  fetchLineUpsSuccess,
} from "./liveActions";
import { getLiveMatches } from "../../helpers/getLiveMatches";
import { selectUserLocationCoords } from "../userLocation/userLocationSelectors";
import { getFixturesForLive } from "../../helpers/getFixturesForLive";
import { getStatistics } from "../../helpers/getStatistics";
import {
  selectLiveMatchesDate,
  selectFixturesForLive,
  selectLiveMatch,
  selectLiveMatches,
} from "./liveSelectors";
import { fetchFilteringValues } from "../filteringValues/filteringValuesActions";
import { selectNormalizedLeaguesList } from "../leagues/leaguesSelectors";
import { getLineUps } from "../../helpers/getLineUps";
import { getSummary } from "../../helpers/getSummary";

function* fetchLiveMatchesAsync({ payload }) {
  const liveDate = yield select(selectLiveMatchesDate);
  const fixturesForLive = yield select(selectFixturesForLive);
  const liveMatches = yield select(selectLiveMatches);
  const fixList = Object.values(fixturesForLive).length
    ? fixturesForLive[liveDate]
    : payload;
  const liveMatch = yield select(selectLiveMatch);
  const date = liveDate ? liveDate : new Date();
  if (new Date(date).getDate() === new Date().getDate()) {
    const { fixtures, error } = yield call(getLiveMatches);
    if (!error && fixtures && fixList) {
      const fix = yield Object.values(fixList)
        .flat()
        .filter((x) => fixtures[x.fixture_id])
        .map((x) => ({ ...x, ...fixtures[x.fixture_id][0] }))
        .reduce((a, c) => {
          if (a[c["league_id"]]) {
            a[c["league_id"]].push(c);
          } else {
            a[c["league_id"]] = [];
            a[c["league_id"]].push(c);
          }
          return a;
        }, {});

      if (liveMatches && fix) {
        const filteredFixturesForLive = {};

        for (let key in fixturesForLive[liveDate]) {
          // filteredFixturesForLive[key] = fixturesForLive[liveDate][key];
          filteredFixturesForLive[key] = fixturesForLive[liveDate][key].map(
            (x) => {
              if (
                "events" in x &&
                !Object.values(fix)
                  .flat()
                  .some((t) => t.fixture_id === x.fixture_id)
              ) {
                delete x["events"];
                return x;
              } else {
                return x;
              }
            }
          );
        }
        yield put(
          fetchFixturesForLiveSuccess({ [liveDate]: filteredFixturesForLive })
        );
      }
      yield put(fetchLiveMatchesSuccess(fix));
    } else {
      if (!fixturesForLive[liveDate]) {
        yield put(fetchLiveMatchesSuccess(fixturesForLive[liveDate]));
      } else {
        yield put(fetchLiveMatchesFailure({ error }));
      }
    }
  }
}

function* watchLiveMatchesAsync() {
  yield takeLatest(FETCH_LIVE_MATCHES_START, fetchLiveMatchesAsync);
}

function* fetchFixturesForLiveAsync({ payload }) {
  const completeLeaguesList = yield select(selectNormalizedLeaguesList);
  const userLocation = yield select(selectUserLocationCoords);
  yield put(fetchLiveMatchesDate(payload));

  const { fixtures, error } = yield call(
    getFixturesForLive,
    payload,
    userLocation
  );

  if (!error) {
    let selectedLeaguesIds;
    let selectedLeagues;

    if (completeLeaguesList) {
      selectedLeaguesIds = yield [
        ...new Set(
          Object.values(fixtures)
            .flat()
            .map((x) => x.league_id)
        ),
      ];
      selectedLeagues = yield selectedLeaguesIds.map(
        (x) => completeLeaguesList[x]
      );
      yield put(fetchFilteringValues({ selectedLeagues }));
    }
    if (new Date(payload).getDate() === new Date().getDate()) {
      yield put(fetchLiveMatchesStart(fixtures));
      yield put(fetchFixturesForLiveSuccess({ [payload]: fixtures }));
      yield put(fetchLiveFixturesLeagues({ [payload]: selectedLeagues }));
    } else {
      yield put(fetchFixturesForLiveSuccess({ [payload]: fixtures }));
      yield put(fetchLiveFixturesLeagues({ [payload]: selectedLeagues }));
    }
  } else {
    yield put(fetchFixturesForLiveFailure({ error }));
  }
}

function* watchFetchFixturesForLive() {
  yield takeLatest(FETCH_FIXTURES_FOR_LIVE_START, fetchFixturesForLiveAsync);
}

function* fetchLiveMatchStatisticsAsync() {
  yield put(isFetchingLiveDetails({ isFetchingLiveDetails: true }));
  const { fixture_id } = yield select(selectLiveMatch);
  const { statistics } = yield call(getStatistics, fixture_id);

  yield put(fetchLiveMatchStatisticsSuccess({ statistics }));
}

function* watchFetchStatistics() {
  yield takeLatest(FETCH_LIVE_MATCH_STATISTICS, fetchLiveMatchStatisticsAsync);
}

function* fetchLineUpsAsync() {
  const { fixture_id } = yield select(selectLiveMatch);
  const lineUps = yield call(getLineUps, fixture_id);
  let events = yield call(getSummary, fixture_id);

  yield put(fetchLineUpsSuccess({ lineUps, liveEvents: events }));
}

function* watchFetchLineUps() {
  yield takeLatest(FETCH_LINE_UPS_START, fetchLineUpsAsync);
}

export default function* liveSagas() {
  yield all([
    call(watchLiveMatchesAsync),
    call(watchFetchFixturesForLive),
    call(watchFetchStatistics),
    call(watchFetchLineUps),
  ]);
}
