export const FETCH_LIVE_MATCHES_START = "FETCH_LIVE_MATCHES_START";
export const FETCH_LIVE_MATCHES_SUCCESS = "FETCH_LIVE_MATCHES_SUCCESS";
export const FETCH_LIVE_MATCHES_FAILURE = "FETCH_LIVE_MATCHES_FAILURE";
export const FETCH_LIVE_MATCHES_DETAILS_START =
  "FETCH_LIVE_MATCHES_DETAILS_START";

export const FETCH_DATES = "FETCH_DATES";
export const FETCH_IS_MOBILE_SIDEBAR_VISIBLE = "FETCH_IS_SIDEBAR_VISIBLE";

export const FETCH_LIVE_MATCH_DATE = "FETCH_LIVE_MATCH_DATE";
export const RESET_LIVE = "RESET_LIVE";

export const FETCH_LIVE_MATCH = "FETCH_LIVE_MATCH";
export const FETCH_LIVE_MATCH_ON_HOVER = "FETCH_LIVE_MATCH_ON_HOVER";

export const FETCH_FIXTURES_FOR_LIVE_START = "FETCH_FIXTURES_FOR_LIVE_START";
export const FETCH_FIXTURES_FOR_LIVE_SUCCESS =
  "FETCH_FIXTURES_FOR_LIVE_SUCCESS";
export const FETCH_FIXTURES_FOR_LIVE_FAILURE =
  "FETCH_FIXTURES_FOR_LIVE_FAILURE";
export const OPEN_DETAILS_SECTION = "OPEN_DETAILS_SECTION";
export const FETCH_LIVE_MATCH_STATISTICS = "FETCH_LIVE_MATCH_STATISTICS";
export const FETCH_LIVE_MATCH_STATISTICS_SUCCESS =
  "FETCH_LIVE_MATCH_STATISTICS_SUCCESS";
export const IS_FETCHING_LIVE_DETAILS = "IS_FETCHING_LIVE_DETAILS";

export const FETCH_LIVE_FIXTURES_LEAGUES = "FETCH_LIVE_FIXTURES_LEAGUES";
export const FETCH_LINE_UPS_START = "FETCH_LINE_UPS_START";
export const FETCH_LINE_UPS_SUCCESS = "FETCH_LINE_UPS_SUCCESS";
export const RESET_LIVE_DETAILS = "RESET_LIVE_DETAILS";

export const LIVE_KEEP_FETCHING = "LIVE_KEEP_FETCHING";
export const SHOW_ONLY_LIVE_MATCHES = "SHOW_ONLY_LIVE_MATCHES";
export const RESET_LIVE_MODALS = "RESET_LIVE_MODALS";
