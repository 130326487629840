import { takeLatest, put, all, call, select, delay } from "redux-saga/effects";
import {
  FETCH_SUPPORTERS_DETAILS_START,
  FETCH_MATCH_DETAILS_START,
  FETCH_STANDINGS_START,
  FETCH_STADIUM_IMAGES_SUCCESS,
  FETCH_STADIUM_IMAGES_START,
  FETCH_SUMMARY_START,
  FETCH_ATTRACTION_BY_LOCATION,
  FETCH_TRAVEL_TIME,
  FETCH_SHARED_FIXTURE,
} from "./fixtureDetailsTypes";
import { getLeagueTeams } from "../../helpers/getLeagueTeams";
import { getStandings } from "../../helpers/getStandings";
import { getLastFiveMatches } from "../../helpers/getLastFiveMatches";
import { getH2h } from "../../helpers/getH2h";
import { getOdds } from "../../helpers/getOdds";
import { getSummary } from "../../helpers/getSummary";
import {
  fetchSupportersDetailsSuccess,
  fetchMatchDetailsSuccess,
  fetchStadiumImagesSuccess,
  fetchSummarySuccess,
  fetchMatchDetailsFail,
  fetchFixture,
  fetchTravelTimeSuccess,
  fetchSharedFixtureSuccess,
} from "./fixtureDetailsActions";
import { getSupporters } from "../../helpers/getSupporters";
import { selectInternalMap } from "../map/mapSelectors";

import { selectFixture } from "./fixtureDetailsSelectors";
import { selectLiveMatch } from "../live/liveSelectors";
import { animateCamera } from "../../utils";
import { getAttractionsByLocation } from "../attractions/attractionServices";
import { fetchGoogleImages } from "../../helpers/getGoogleImages";
import { addDescriptionToFixture } from "../../helpers/addDescription";
import { getFixtureDetails } from "../../helpers/getFixtureDetails";
import { getTravelTime } from "../../helpers/getTravelTime";
import { getSharedFixture } from "../../helpers/getSharedFixture";
import { selectFormValuesSearchedLocation } from "../formValues/formValuesSelectors";
import { selectUserLocationCoords } from "../userLocation/userLocationSelectors";

function* fetchMatchDetailsAsync({ payload }) {
  try {
    const fixture = yield select(selectFixture);
    const liveMatch = yield select(selectLiveMatch);
    const fix = payload === "home" ? fixture : liveMatch;

    if (!fix) {
      throw new Error("Fixture or live match data not available");
    }

    const {
      league_id,
      home_team,
      away_team,
      fixture_id,
      league,
      id,
      description,
    } = fix;
    const sagaResponse = yield call(getFixtureDetails, fixture_id);

    console.log("sagaResponse", sagaResponse);

    const {
      leagueTeams,
      standings,
      homeTeamLastFiveMatches,
      awayTeamLastFiveMatches,
      h2h,
      odds,
      description: descriptionFromApi,
    } = sagaResponse.data;

    // yield delay(500);
    // const [
    //   leagueTeams,
    //   standings,
    //   homeTeamLastFiveMatches,
    //   awayTeamLastFiveMatches,
    //   h2h,
    //   odds,
    //   fixtures,
    // ] = yield all([
    //   call(getLeagueTeams, league_id, league.season),
    //   !fix.events ? call(getStandings, league_id, league.season) : null,
    //   call(getLastFiveMatches, home_team.team_id),
    //   call(getLastFiveMatches, away_team.team_id),
    //   call(getH2h, home_team.team_id, away_team.team_id),
    //   call(getOdds, fixture_id, league_id),
    //   description ? null : call(addDescriptionToFixture, id),
    // ]);

    if (
      !leagueTeams &&
      (!fix.events || !standings) &&
      !homeTeamLastFiveMatches &&
      !awayTeamLastFiveMatches &&
      !h2h &&
      !odds
    ) {
      throw new Error("Failed to fetch any match details");
    }

    let fixDetails = {
      leagueTeams,
      standings,
      h2h,
      odds,
      lastFiveMatches: { homeTeamLastFiveMatches, awayTeamLastFiveMatches },
    };
    if (!description) {
      fixDetails = {
        ...fixDetails,
        fixture: {
          ...fix,
          description: descriptionFromApi,
        },
      };
    }
    yield put(fetchMatchDetailsSuccess(fixDetails));
  } catch (error) {
    console.error("Error in fetchMatchDetailsAsync:", error);
    yield put(fetchMatchDetailsFail({ error: error.message }));
  }
}

function* fetchAttractionAsync({ payload }) {
  const fixture = yield select(selectFixture);
  const liveMatch = yield select(selectLiveMatch);
  const fix = payload === "home" ? fixture : liveMatch;
  if (fix) {
    const { location, venue_id } = fix;
    let payload = {
      location: JSON.stringify(location),
    };
    if (venue_id) {
      payload = { ...payload, venue_id };
    }
    const attractions = yield call(getAttractionsByLocation, payload);
    yield put(
      fetchMatchDetailsSuccess({
        attractions,
      })
    );
  }

  // yield put(fetchMapDetails({ zoom: 12 }));
}

function* fetchTravelTimeAsync(payload) {
  const travelTime = yield call(getTravelTime, payload);
  yield put(
    fetchTravelTimeSuccess({
      travelTime,
    })
  );
}

function* fetchSharedFixtureAsync(payload) {
  const searchedLocation = yield select(selectFormValuesSearchedLocation);
  const deviceLocation = yield select(selectUserLocationCoords);
  const coords = searchedLocation || deviceLocation;
  const { fixture_id } = payload.payload;
  const sharedFixture = yield call(getSharedFixture, {
    coords,
    id: fixture_id,
  });
  console.log('---------------------------------sssssss',sharedFixture)

  yield put(
    fetchSharedFixtureSuccess({
      sharedFixture,
    })
  );
}

function* watchFetchMatchDetails() {
  yield takeLatest(FETCH_MATCH_DETAILS_START, fetchMatchDetailsAsync);
}
function* watchFetchAttractions() {
  yield takeLatest(FETCH_ATTRACTION_BY_LOCATION, fetchAttractionAsync);
}

function* watchFetchTravelTime() {
  yield takeLatest(FETCH_TRAVEL_TIME, fetchTravelTimeAsync);
}

function* watchFetchSharedFixture() {
  yield takeLatest(FETCH_SHARED_FIXTURE, fetchSharedFixtureAsync);
}

function* fetchSupportersDetailsAsync({ payload }) {
  const map = yield select(selectInternalMap);
  const fixture = yield select(selectFixture);

  const liveMatch = yield select(selectLiveMatch);

  const fix = payload === "home" ? fixture : liveMatch;

  if (!fix || !fix.location) {
    yield put(
      fetchSupportersDetailsSuccess({
        supporters: null,
      })
    );
  } else {
    const { location, venue_id } = fix;
    const supporters = yield call(getSupporters, venue_id);
    yield put(
      fetchSupportersDetailsSuccess({
        supporters,
      })
    );

    if (map) {
      if (supporters?.length) {
        const supportersCoords = supporters?.map(({ location }) => location);
        const markers = [...supportersCoords, { ...location.location }];
        yield call(animateCamera, markers, map);
        const zoom = map.getZoom();
        map.setZoom(zoom > 17 ? 17 : zoom - 2);
      } else {
        // const markers = [{ ...location.location }];
        // yield call(animateCamera, markers, map);
        // const zoom = map.getZoom();
        // map.setZoom(zoom - 16);
      }
    }
  }
}

function* watchFetchSupportersDetails() {
  yield takeLatest(FETCH_SUPPORTERS_DETAILS_START, fetchSupportersDetailsAsync);
}

function* fetchStandingsAsync() {
  const liveMatch = yield select(selectLiveMatch);
  const { league_id, league } = liveMatch;
  const standings = yield call(getStandings, league_id, league.season);

  yield put(
    fetchMatchDetailsSuccess({
      standings,
    })
  );
}

function* watchFetchStandings() {
  yield takeLatest(FETCH_STANDINGS_START, fetchStandingsAsync);
}

function* fetchStadiumImagesAsync() {
  try {
    const { venue, city } = yield select(selectFixture);
    const images = yield fetchGoogleImages(`${venue} Stadium ${city}`);
    yield put(fetchStadiumImagesSuccess(images));
  } catch (error) {
    yield put(fetchStadiumImagesSuccess(null));
  }
}

function* watchFetchStadiumImages() {
  yield takeLatest(FETCH_STADIUM_IMAGES_START, fetchStadiumImagesAsync);
}

function* fetchSummaryAsync() {
  const liveMatch = yield select(selectLiveMatch);
  const { fixture_id } = liveMatch;
  let events = null;

  events = yield call(getSummary, fixture_id);

  yield put(
    fetchSummarySuccess({
      events,
    })
  );
}

function* watchFetchSummary() {
  yield takeLatest(FETCH_SUMMARY_START, fetchSummaryAsync);
}

export default function* fixtureDetailsSagas() {
  yield all([
    call(watchFetchSupportersDetails),
    call(watchFetchMatchDetails),
    call(watchFetchStandings),
    call(watchFetchStadiumImages),
    call(watchFetchSummary),
    call(watchFetchAttractions),
    call(watchFetchTravelTime),
    call(watchFetchSharedFixture),
  ]);
}
