export const updateLiveMatch = (liveMatches, liveMatch) => {
  if (liveMatch) {
    if ("events" in liveMatch === false) {
      return liveMatch;
    } else {
      let updatedMatch = null;
      for (let key in liveMatches) {
        if (!updatedMatch) {
          updatedMatch = liveMatches[key].find(
            (x) => x.fixture_id === liveMatch.fixture_id
          );
        }
      }
      return updatedMatch;
    }
  } else {
    return null;
  }
};
