import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import { trimString } from "../../helpers/trimString";
import getColor from "../../utils/getBorderColor";
import "./lastFiveMatchesRow.scss";
import { connect } from "react-redux";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";

const LastFiveMatchesRow = ({
  homeTeamFixture,
  awayTeamFixture,
  staticHomeTeam,
  staticAwayTeam,
  leagueTeams,
  width,
}) => {
  const homeFixtureHomeTeamLogo =
    staticHomeTeam?.team_id === homeTeamFixture?.teams?.home.id
      ? staticHomeTeam?.logo
      : homeTeamFixture?.teams?.home.logo;
  const homeFixtureAwayTeamLogo =
    staticHomeTeam?.team_id === homeTeamFixture?.teams?.away.id
      ? staticHomeTeam?.logo
      : homeTeamFixture?.teams?.away.logo;
  const awayFixtureHomeTeamLogo =
    staticAwayTeam?.team_id === awayTeamFixture?.teams?.home.id
      ? staticAwayTeam?.logo
      : awayTeamFixture?.teams?.home.logo;
  const awayFixtureAwayTeamLogo =
    staticAwayTeam?.team_id === awayTeamFixture?.teams?.away.id
      ? staticAwayTeam?.logo
      : awayTeamFixture?.teams?.away.logo;
  return (
    <div className="lastFiveMatches-row">
      {homeTeamFixture && (
        <div className="lastFiveMatches-container">
          <div className="lastFiveMatches-teamComparison">
            <div className="lastFiveMatches-team">
              <img
                className="lastFiveMatches-teamLogo"
                alt="logo"
                src={homeFixtureHomeTeamLogo}
                onError={(e) => e.target.src = homeFixtureHomeTeamLogo}
              />
              <span className="lastFiveMatches-teamName">
                {(leagueTeams[homeTeamFixture?.teams?.home.id] &&
                  leagueTeams[homeTeamFixture?.teams?.home.id]?.code) ||
                  homeTeamFixture?.teams?.home.name}
                {"  "}
              </span>
            </div>
            <div className="lastFiveMatches-score">
              <span className="lastFiveMatches-scoreTxt">
                {homeTeamFixture?.score?.fulltime
                  ? width <= 1000
                    ? `${homeTeamFixture.score.fulltime.home} - ${homeTeamFixture.score.fulltime.away}`
                    : `${homeTeamFixture.score.fulltime.home} - ${homeTeamFixture.score.fulltime.away}`
                  : width <= 1000
                  ? "0 - 0"
                  : "0  -  0"}
              </span>
            </div>
            <div className="lastFiveMatches-team">
              <img
                className="lastFiveMatches-teamLogo"
                alt="logo"
                src={homeFixtureAwayTeamLogo}
                onError={(e) => e.target.src = homeFixtureAwayTeamLogo}
              />
              <span className="lastFiveMatches-teamName">
                {(leagueTeams[homeTeamFixture?.teams?.away.id] &&
                  leagueTeams[homeTeamFixture?.teams?.away.id]?.code) ||
                  homeTeamFixture?.teams?.away.name}
              </span>
            </div>
            <div
              className="border"
              style={{
                right: "2px",
                backgroundColor: getColor(homeTeamFixture),
              }}
            />
          </div>
          <span className="lastFiveMatches-date">
            {moment(homeTeamFixture?.fixture.date).format("dddd DD MMM YYYY")}
          </span>
        </div>
      )}
      <div className="lastFiveMatches-container">
        {awayTeamFixture && (
          <>
            <div className="lastFiveMatches-teamComparison">
              <div className="lastFiveMatches-team">
                <img
                  className="lastFiveMatches-teamLogo"
                  alt="logo"
                  src={awayFixtureHomeTeamLogo}
                  onError={(e) => e.target.src = awayFixtureHomeTeamLogo}
                />
                <span className="lastFiveMatches-teamName">
                  {(leagueTeams[awayTeamFixture?.teams?.home.id] &&
                    leagueTeams[awayTeamFixture?.teams?.home.id]?.code) ||
                    awayTeamFixture?.teams?.home.name}{" "}
                  {"  "}
                </span>
              </div>
              <div className="lastFiveMatches-score">
                <span className="lastFiveMatches-scoreTxt">
                  {`${
                    awayTeamFixture?.score?.fulltime
                      ? `${awayTeamFixture.score.fulltime.home} - ${awayTeamFixture.score.fulltime.away}`
                      : "0  -  0"
                  }`}
                </span>
              </div>
              <div className="lastFiveMatches-team">
                <img
                  className="lastFiveMatches-teamLogo"
                  alt="logo"
                  src={awayFixtureAwayTeamLogo}
                  onError={(e) => e.target.src = awayFixtureAwayTeamLogo}
                />
                <span className="lastFiveMatches-teamName">
                  {(leagueTeams[awayTeamFixture?.teams?.away.id] &&
                    leagueTeams[awayTeamFixture?.teams?.away.id]?.code) ||
                    awayTeamFixture?.teams?.away.name}{" "}
                  {"  "}
                </span>
              </div>
              <div
                className="border"
                style={{
                  left: 0,
                  backgroundColor: getColor(awayTeamFixture),
                }}
              />
            </div>
            <span className="lastFiveMatches-date">
              {moment(awayTeamFixture?.fixture.date).format("dddd DD MMM YYYY")}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

LastFiveMatchesRow.propTypes = {
  homeTeamFixture: PropTypes.object,
  awayTeamFixture: PropTypes.object,
  staticHomeTeam: PropTypes.object,
  width: PropTypes.number,
  staticAwayTeam: PropTypes.object,
  leagueTeams: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  width: selectWidth,
});

export default connect(mapStateToProps)(LastFiveMatchesRow);
