import React from "react";
import StarRatings from "react-star-ratings";
import PropTypes from "prop-types";

import "./ratings.scss";

const Ratings = ({ rating, ratingCount, size, infoClass='' }) => {
  return (
    <div className="ratings">      
      <div className="stars">
        <StarRatings
          rating={+rating}
          starDimension={size}
          starSpacing="1px"
          numberOfStars={5}
          starEmptyColor="#2e2e2e"
          starRatedColor="#FFDA1A"
        />
      </div>
      <div className= 'rate-block'>
      <div className={`rating ${infoClass}`} >{(Math.round(+rating * 100) / 100).toFixed(1)}</div>
      {ratingCount!==undefined?<div className={`rating addition-info ${infoClass}`} >{`(${ratingCount})`}</div>: null}   
      </div>
    </div>
  );
};

Ratings.propTypes = {
  rating: PropTypes.number,
  ratingCount: PropTypes.number,
  size: PropTypes.string,
};

export default Ratings;
