import { createSelector } from "reselect";
import { splitMiddle } from "../../utils";
import {
  selectFilterModalSelectedLeaguesIds,
  selectIsItFiltered,
} from "../filteringValues/filteringValuesSelectors";
import { filterLiveFixtures } from "../../utils/filterLiveFixtures";
import { filterLiveMatches } from "../../utils/filterLiveMatches";

const selectLive = (state) => state.live;

export const selectIsLiveKeepFetching = createSelector(
  selectLive,
  (live) => live.isLiveKeepFetching
);

export const selectIsLivePageActive = createSelector(
  selectLive,
  (live) => live.isLivePageActive
);

export const selectIsLivePageActiveMobile = createSelector(
  selectLive,
  (live) => live.isLivePageActiveMobile
);

export const selectAreLiveDetailsFetched = createSelector(
  selectLive,
  (live) => live.areLiveDetailsFetched
);

export const selectLiveMatchesDate = createSelector(
  selectLive,
  (live) => live.date
);

export const selectLiveMatchStatistics = createSelector(
  selectLive,
  (live) => live.statistics
);

export const selectLiveMatch = createSelector(
  selectLive,
  (live) => live.liveMatch
);

export const selectLiveMatchOnHover = createSelector(
  selectLive,
  (live) => live.liveMatchOnHover
);

export const selectIsFetchingLiveDetails = createSelector(
  selectLive,
  (live) => live.isFetchingLiveDetails
);

export const selectIsFetchingLiveMatches = createSelector(
  selectLive,
  (live) => live.isFetchingLiveMatches
);

export const selectIsMobileSidebarVisible = createSelector(
  selectLive,
  (live) => live.isMobileSidebarVisible
);

export const selectIsFormVisible = createSelector(
  selectLive,
  (live) => live.isFormVisible
);

export const selectLiveMatches = createSelector(
  [
    selectLive,
    selectFilterModalSelectedLeaguesIds,
    selectLiveMatchesDate,
    selectIsItFiltered,
  ],
  (live, selectedLeaguesIds, liveDate, isItFiltered) => {
    return isItFiltered
      ? filterLiveMatches(
          { [liveDate]: live.liveMatches },
          selectedLeaguesIds,
          liveDate
        )
      : live.liveMatches;
  }
);

export const selectFixturesForLive = createSelector(
  [
    selectLive,
    selectFilterModalSelectedLeaguesIds,
    selectLiveMatchesDate,
    selectIsItFiltered,
  ],
  (live, selectedLeaguesIds, liveDate, isItFiltered) => {
    return isItFiltered
      ? filterLiveFixtures(live.fixtures, selectedLeaguesIds, liveDate)
      : live.fixtures;
  }
);

export const selectLiveFixturesLeagues = createSelector(
  selectLive,
  (live) => live.liveFixturesLeagues
);

export const selectIsFetchingStats = createSelector(
  selectLive,
  (live) => live.isFetchingStatistics
);

export const selectLineUps = createSelector(selectLive, (live) => live.lineUps);
export const selectIsFetchingLineUps = createSelector(
  selectLive,
  (live) => live.isFetchingLineUps
);
export const selectLiveEvents = createSelector(
  selectLive,
  (live) => live.liveEvents
);

export const selectLiveDates = (areLiveDetailsFetched, width) =>
  createSelector(selectLive, (live) => {
    if (live.dates) {
      if (areLiveDetailsFetched && width > 1024) {
        return splitMiddle(live.dates);
      } else {
        const { dates } = live;
        var half_length = Math.ceil(dates.length / 2);

        var leftSide = dates.slice(0, half_length - 1);
        var rightSide = dates.slice(half_length, dates.length);
        const datesArr = [...leftSide, ...rightSide];
        datesArr.splice(4, 0, dates[4]);
        return datesArr;
      }
    }
  });

export const selectShowOnlyLive = createSelector(
  selectLive,
  (live) => live.onlyLiveMatches
);
