import {
  CHECK_UPDATE_LEAGUES,
  FETCH_LEAGUES_START,
  FETCH_LEAGUES_SUCCESS,
  FETCH_LEAGUES_FAILURE,
  FETCH_SELECTED_LEAGUE,
  FETCH_SELECTED_STADIUM,
  FETCH_SELECTED_TEAM,
} from "./leaguesTypes";

export const fetchLeaguesStart = () => ({
  type: FETCH_LEAGUES_START,
});

export const checkUpdateLeagues = () => ({
  type: CHECK_UPDATE_LEAGUES,
});

export const fetchLeaguesSuccess = (payload) => ({
  type: FETCH_LEAGUES_SUCCESS,
  payload,
});

export const fetchLeaguesFailure = (payload) => ({
  type: FETCH_LEAGUES_FAILURE,
  payload,
});

export const fetchSelectedLeague = (payload) => ({
  type: FETCH_SELECTED_LEAGUE,
  payload,
});

export const fetchSelectedStadium = (payload) => ({
  type: FETCH_SELECTED_STADIUM,
  payload,
});

export const fetchSelectedTeam = (payload) => ({
  type: FETCH_SELECTED_TEAM,
  payload,
});
