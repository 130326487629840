import Pin1 from "../../assets/images/pin1.png";
import Pin2 from "../../assets/images/pin2.png";
import Pin3 from "../../assets/images/pin3.png";
import Pin4 from "../../assets/images/pin4.png";
import Pin5 from "../../assets/images/pin5.png";

function data(height) {
  return [
    {
      pin: Pin1,
      transform: `${height / 1.7}px`,
      below: "-40px",
      duration: 1000,
      key: 0,
      horizontal: "0",
    },
    {
      pin: Pin2,
      transform: `${height / 2.2}px`,
      below: "-100px",
      duration: 2200,
      key: 1,
      horizontal: "0",
    },
    {
      pin: Pin3,
      transform: `${height / 1.8}px`,
      below: "-160px",
      duration: 3600,
      key: 2,
      horizontal: "90px",
    },
    {
      pin: Pin4,
      transform: `${height / 2.5}px`,
      below: "-220px",
      duration: 4800,
      key: 3,
      horizontal: "-40px",
    },
    {
      pin: Pin5,
      transform: `${height / 2.1}px`,
      below: "-280px",
      duration: 6000,
      key: 4,
      horizontal: "-40px",
    },
  ];
}

export default data;
