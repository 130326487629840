import { call, takeLatest, put, all, select } from "redux-saga/effects";
import { FETCH_WISHLIST_REQUEST, LOGIN_REQUEST } from "./authTypes";
import {
  loginCallSuccess,
  loginCallFailure,
  fetchWishlistSuccess,
  fetchWishlistFailure,
} from "./authActions";
import { loginCall, verifyGoogleApiCall } from "./authServices";
import { setCookie } from "../../utils/cookies";
import { getWishlist } from "../../helpers/getWishlist";
import { selectFormValuesSearchedLocation } from "../formValues/formValuesSelectors";
import { selectUserLocationCoords } from "../userLocation/userLocationSelectors";
import Mixpanel from "../../utils/mixPanel";
const platform = typeof window !== "undefined" ? "web" : "mobile";
function* loginApiAsync(action) {
  try {
    let data, error;
    if (action.payload.platform === "google") {
      const response = yield call(verifyGoogleApiCall, action.payload);
      data = response.data;
      error = response.error;
    } else {
      const response = yield call(loginCall, action.payload);
      data = response.data;
      error = response.error;
    }

    setCookie("token", data?.accessToken, 10);
    setCookie("user", JSON.stringify(data?.user), 10);
    if (data) {
      Mixpanel.track("Sign in", {
        type: "fam-credentials/Google",
        platform: platform,
        application_type: platform === "web" ? "web app" : "mobile app",
      });
      Mixpanel.identify(data?.user._id);
      Mixpanel.people.set({
        $first_name: data?.user.firstName,
        $last_name: data?.user.lastName,
        $email: data?.user.email,
      });
      yield put(loginCallSuccess(data?.user));
    } else {
      yield put(loginCallFailure(error));
    }
  } catch (error) {
    Mixpanel.track("Sign in", {
      type: `Error on login ${error}`,
      platform: platform,
      application_type: platform === "web" ? "web app" : "mobile app",
    });
    yield put(loginCallFailure(error));
  }
}
function* fetchWishlistApiAsync(action) {
  try {
    const searchedLocation = yield select(selectFormValuesSearchedLocation);
    const deviceLocation = yield select(selectUserLocationCoords);
    const coords = searchedLocation || deviceLocation;
    const data = yield call(getWishlist, action.payload, coords);
    if (data) {
      const wishlistObject = data.reduce((acc, item) => {
        acc[item.fixture.id] = item;
        return acc;
      }, {});
      yield put(
        fetchWishlistSuccess({ wishlist: wishlistObject, wishlisting: data })
      );
    } else {
      throw new Error("No wishlist data found");
    }
  } catch (error) {
    yield put(fetchWishlistFailure(error.message));
  }
}

function* watchAuthCall() {
  yield takeLatest(LOGIN_REQUEST, loginApiAsync);
  yield takeLatest(FETCH_WISHLIST_REQUEST, fetchWishlistApiAsync);
}

export function* authSagas() {
  yield all([call(watchAuthCall)]);
}
