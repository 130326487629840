import { useSpring } from "react-spring";

export const useMapContentWrapperProps = (
  width,
  areFixturesFetched,
  isMobileSidebarVisible,
  type,
  toggleDrawer
) => {
  return useSpring(
    width && width <= 1000
      ? {
          width: width,
          left:
            (areFixturesFetched && type === "home") ||
            (!isMobileSidebarVisible && type === "live")
              ? -width
              : 0,
          from: { width: width, left: 0 },
          config: {
            duration: 300,
          },
        }
      : {
          width: toggleDrawer ? 20 : 450,
          from: {
            width: toggleDrawer ? 20 : 450,
          },
          config: {
            duration: 300,
          },
        }
  );
};

export const useFormProps = (
  width,
  areFixturesDetailsFetched,
  areFixturesFetched
) => {
  const sectionWidth = width <= 768 ? 350 : 450;
  return useSpring(
    width <= 1000
      ? {
          opacity: areFixturesDetailsFetched ? 0 : 1,
          display: areFixturesDetailsFetched ? "none" : "block",
          from: { opacity: 1, display: "block" },
          config: { duration: 300 },
        }
      : width > 1324
      ? {
          opacity: areFixturesDetailsFetched || areFixturesFetched ? 0 : 1,
          left: areFixturesDetailsFetched || areFixturesFetched ? -450 : 0,
          width: 450,
          from: { opacity: 1, position: "absolute", left: 0, width: 450 },
          config: { duration: 300 },
        }
      : {
          opacity: areFixturesFetched ? 0 : 1,
          left: areFixturesFetched ? -sectionWidth : 0,
          width: sectionWidth,
          from: {
            opacity: 1,
            position: "absolute",
            left: 0,
            width: sectionWidth,
          },
          config: { duration: 300 },
        }
  );
};

export const useFixturesListProps = (
  width,
  areFixturesDetailsFetched,
  areFixturesFetched
) => {
  const sectionWidth = width <= 768 ? 350 : 450;
  const isBothTrue = areFixturesDetailsFetched && areFixturesFetched;

  return useSpring(
    width > 1324
      ? {
          opacity: isBothTrue ? 0 : areFixturesFetched ? 1 : 0,
          width: isBothTrue ? 0 : sectionWidth,
          left: isBothTrue ? -sectionWidth : 0,
          from: {
            opacity: 0,
            position: "absolute",
            left: 450,
            width: sectionWidth,
          },
          config: { duration: 300 },
        }
      : {
          opacity: isBothTrue ? 0 : areFixturesFetched ? 1 : 0,
          width: isBothTrue ? 0 : sectionWidth,
          left: isBothTrue ? -sectionWidth : 0,
          from: {
            opacity: 0,
            position: "absolute",
            left: sectionWidth,
            width: sectionWidth,
          },
          config: { duration: 300 },
        }
  );
};

export const useFixturesDetailsProps = (
  width,
  areFixturesDetailsFetched,
  areFixturesFetched
) => {
  const sectionWidth = width <= 768 ? 350 : 450;
  const isBothTrue = areFixturesDetailsFetched && areFixturesFetched;
  return useSpring(
    width <= 1000
      ? {
          opacity: areFixturesDetailsFetched && !areFixturesFetched ? 1 : 0,
          display:
            areFixturesDetailsFetched && !areFixturesFetched ? "block" : "none",
          from: {
            opacity: 0,
            display: "none",
            left: 0,
            position: "absolute",
          },
          config: { duration: 300 },
        }
      : width > 1324
      ? {
          opacity: isBothTrue ? 1 : 0,
          left: isBothTrue ? 0 : -sectionWidth,
          width: sectionWidth,
          from: {
            opacity: 0,
            position: "absolute",
            left: 450,
            width: sectionWidth,
          },
          config: { duration: 300 },
        }
      : {
          opacity: areFixturesDetailsFetched ? 1 : 0,
          left: areFixturesDetailsFetched ? 0 : sectionWidth,
          width: sectionWidth,
          from: {
            opacity: 0,
            position: "absolute",
            left: sectionWidth,
            width: sectionWidth,
          },
          config: { duration: 300 },
        }
  );
};

export const useCloseButtonProps = (
  areFixturesFetched,
  areFixturesDetailsFetched
) => {
  const isCheck = areFixturesFetched || areFixturesDetailsFetched;
  return useSpring({
    opacity: isCheck ? 1 : 0,
    display: isCheck ? "flex" : "none",
    top: 47,
    from: {
      opacity: 0,
    },
    config: { duration: 300 },
  });
};

export const useShowToggleButton = () => {
  return useSpring({
    opacity: 1,
    display: "flex",
    from: {
      opacity: 0,
    },
    config: { duration: 300 },
  });
};

export const useFilterSortProps = (
  width,
  areFixturesFetched,
  areFixturesDetailsFetched,
  error
) => {
  return useSpring(
    width > 1324
      ? {
          opacity: areFixturesFetched && !error ? 1 : 0,
          top: 55,
          display: areFixturesFetched ? "flex" : "none",
          flexDirection: "column",

          from: {
            position: "absolute",
            opacity: 0,
            top: 10,
            display: "none",
          },
          config: { duration: 300 },
        }
      : {
          opacity: areFixturesFetched && !error ? 1 : 0,
          top: areFixturesDetailsFetched ? 54 : 54,
          display: areFixturesFetched ? "flex" : "none",
          flexDirection: "column",

          from: {
            position: "absolute",
            opacity: 0,
            top: 99,
            display: "none",
          },
          config: { duration: 300 },
        }
  );
};

export const useShowFormButton = (
  width,
  areFixturesDetailsFetched,
  originalFixturesList,
  areFixturesFetched
) => {
  const isCheck = areFixturesFetched || areFixturesDetailsFetched;
  const isShowListButton = areFixturesFetched && areFixturesDetailsFetched;
  return useSpring(
    width > 1324
      ? {
          opacity: isCheck ? 1 : 0,
          display: isCheck ? "flex" : "none",
          from: {
            opacity: 0,
            top: 100,
          },
          config: { duration: 300 },
        }
      : {
          opacity: originalFixturesList ? 1 : 1,
          top: isCheck ? 54 : 10,
          display: originalFixturesList ? "flex" : "flex",
          from: {
            opacity: 0,
            top: 10,
            display: "none",
          },
          config: { duration: 300 },
        }
  );
};

export const useShowListButton = (
  width,
  areFixturesDetailsFetched,
  originalFixturesList,
  areFixturesFetched,
  isSharedFixture
) => {
  const isCheck = areFixturesFetched && areFixturesDetailsFetched && !isSharedFixture;
  return useSpring({
    opacity: isCheck ? 1 : 0,
    display: isCheck ? "flex" : "none",
    from: {
      opacity: 0,
      top: 145,
    },
    config: { duration: 300 },
  });
};

export const useLiveListProps = (areLiveDetailsFetched, width) => {
  const x = areLiveDetailsFetched ? "50%" : "100%";
  const fromWidth = "100%";
  return useSpring(
    width >= 768 && width <= 1024
      ? {
          width: fromWidth,
          left: areLiveDetailsFetched ? -450 : 0,
          from: {
            width: fromWidth,
            position: "absolute",
            left: 0,
          },
          config: {
            duration: 300,
          },
        }
      : {
          width: x,
          from: {
            width: fromWidth,
          },
          config: {
            duration: 300,
          },
        }
  );
};

export const useLiveDetailsProps = (areLiveDetailsFetched, width) => {
  const fromWidth = "100%";
  return useSpring(
    width >= 768 && width <= 1024
      ? {
          width: fromWidth,
          height: fromWidth,
          right: areLiveDetailsFetched ? 0 : -450,
          from: {
            width: fromWidth,
            height: fromWidth,

            position: "absolute",
            right: -450,
            top: 0,
          },
          config: {
            duration: 300,
          },
        }
      : {
          right: areLiveDetailsFetched ? 0 : -450,
          from: {
            width: fromWidth,
            height: fromWidth,

            position: "absolute",
            right: -450,
            top: 0,
          },
          config: {
            duration: 300,
          },
        }
  );
};
