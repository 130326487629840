import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../pages/Home";
import Live from "../pages/Live";
import Review from "../pages/Review/review";
import SignIn from "../pages/SignIn/signin";
import SignUp from "../pages/SignUp/signup";
import ForgotPassword from "../pages/ForgotPassword/forgotpassword";
import ResetPassword from "../pages/resetPassword/resetpassword";
import Contact from "../pages/Contact/contact";
import TermsCondition from "../pages/Terms&Condition/termsCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy/privacyPolicy";
import Wishlist from "../pages/Wishlist/wishlist";
import MyProfile from "../pages/MyProfile/myProfile"
import About from "../pages/About/about";
import Match from "../pages/Match/match";

const MainRoutes = () => {
  return (
    <Switch>
      {/* <Suspense fallback={<Spinner />}> */}
      <Route exact path={`/`}>
        <Home />
      </Route>
      <Route exact path={`/home`}>
        <Home />
      </Route>
      <Route path={`/search`}>
        <Home />
      </Route>
      <Route path={`/live`}>
        <Live />
      </Route>
      <Route path={`/review`}>
        <Review />
      </Route>
      <Route path={`/sign-in`}>
        <SignIn />
      </Route>
      <Route path={`/sign-up`}>
        <SignUp />
      </Route>
      <Route path={`/forgot-password`}>
        <ForgotPassword />
      </Route>
      <Route path={`/reset-password`}>
        <ResetPassword />
      </Route>
      <Route path={`/contact`}>
        <Contact />
      </Route>
      <Route path={`/terms-condition`}>
        <TermsCondition />
      </Route>
      <Route path={`/privacy-policy`}>
        <PrivacyPolicy />
      </Route>
      <Route path={`/wishlist`}>
        <Wishlist />
        </Route>
      <Route path={`/settings`}>
        <MyProfile />
      </Route>
      <Route path={`/about-us`}>
        <About />
      </Route>
      <Route path={`/fixture/:id`}>
        <Match />
      </Route>
      {/* </Suspense> */}
    </Switch>
  );
};

export default MainRoutes;
