import React, { useContext, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import PropTypes from "prop-types";
import { MdMyLocation } from "react-icons/md";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { FiFilter } from "react-icons/fi";
import mapicon from "../../assets/images/map.png";
import { selectInternalMap } from "../../redux/map/mapSelectors";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import { selectFormValuesSearchedLocation, selectorSelectDay } from "../../redux/formValues/formValuesSelectors";
import "./mapControls.scss";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import { Context } from "../../helpers/context/Context";
import { selectMatches, selectSearchBy } from "../../redux/fixtures/fixturesSelectors";
import { FaMapMarkerAlt } from "react-icons/fa";
import Mixpanel from "../../utils/mixPanel";
const platform = typeof window !== "undefined" ? "web" : "mobile";
const MapControls = ({
  map,
  fetchMapDetails,
  userLocationCoords,
  searchedLocationCoords,
  setFilterModal,
  width,
  coords,
  searchBy,
  matches,
  currentSelectedDay
}) => {
  const context = useContext(Context);
  const handleClick = (zoom) => {
    if (coords) {
      console.log("test");
      fetchMapDetails({
        center: { lat: coords.lat, lng: coords.lng },
        marker: null,
        zoom: 7,
      });
    }

    if (map) {
      const coords =
        searchedLocationCoords && searchedLocationCoords.lat
          ? searchedLocationCoords
          : userLocationCoords;

      const currentZoomLevel = map.getZoom();
      if (zoom === "in") map.setZoom(currentZoomLevel + 1);
      else if (zoom === "out") map.setZoom(currentZoomLevel - 1);
      else
        fetchMapDetails({
          center: { lat: coords.lat, lng: coords.lng },
        });
    }
  };

  const handleLeaguesMap = ()=>{
    Mixpanel.track(`Pressed on show all`, {
      platform: platform,
      application_type:
        platform === "web" ? "web app" : "mobile app",
    });
    const firstDayFixtures = [
      ...matches[currentSelectedDay]?.insideRadiusFixtures,
      ...matches[currentSelectedDay]?.outsideRadiusFixtures,
    ];
  
    const markers = firstDayFixtures
      .filter(fixture => fixture.venue)
      .map(fixture => ({
        lat: fixture.location.lat,
        lng: fixture.location.lng,
      }));
  
    // markers.push({ ...deviceLocation });
  
    const bounds = markers.reduce((bounds, marker) => {
      return bounds.extend(marker);
    }, new window.google.maps.LatLngBounds());
  
    // setTimeout(() => {
      if (map) {
        map.fitBounds(bounds);
      }
    // }, 0);
  
  }
  return (
    <div className={`controls-btn`}>
      <button onClick={handleLeaguesMap} className="show-map-location23">
        <FaMapMarkerAlt color="#5256c8" size={"13px"} />
        Show All Matches
      </button>
      <div className="focus-btn">
        <div>
          <MdMyLocation
            onClick={handleClick}
            color="#2e2e2e"
            size={width <= 1000 ? 16 : 18}
            className="map-controls-icon"
          />
        </div>
      </div>
      <div onClick={() => setFilterModal(true)} className="leagues-btn">
        <div>
          <FiFilter className="mflb-side-icon" color="#8f8f8f" size={16} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  map: selectInternalMap,
  userLocationCoords: selectUserLocationCoords,
  searchedLocationCoords: selectFormValuesSearchedLocation,
  width: selectWidth,
  searchBy: selectSearchBy,
  matches: selectMatches,
  currentSelectedDay: selectorSelectDay,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
});

MapControls.propTypes = {
  map: PropTypes.object,
  fetchMapDetails: PropTypes.func,
  userLocationCoords: PropTypes.object,
  searchedLocationCoords: PropTypes.object,
  width: PropTypes.number,
  setFilterModal: PropTypes.func,
  selectSearchBy: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapControls);
