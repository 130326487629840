import {
  FETCH_LIVE_MATCHES_START,
  FETCH_LIVE_MATCH_DATE,
  FETCH_LIVE_MATCHES_SUCCESS,
  FETCH_LIVE_MATCHES_FAILURE,
  FETCH_LIVE_MATCHES_DETAILS_START,
  RESET_LIVE,
  FETCH_IS_MOBILE_SIDEBAR_VISIBLE,
  FETCH_DATES,
  FETCH_LIVE_MATCH,
  FETCH_LIVE_MATCH_ON_HOVER,
  FETCH_FIXTURES_FOR_LIVE_START,
  FETCH_FIXTURES_FOR_LIVE_SUCCESS,
  FETCH_FIXTURES_FOR_LIVE_FAILURE,
  OPEN_DETAILS_SECTION,
  FETCH_LIVE_MATCH_STATISTICS,
  IS_FETCHING_LIVE_DETAILS,
  FETCH_LIVE_MATCH_STATISTICS_SUCCESS,
  FETCH_LIVE_FIXTURES_LEAGUES,
  FETCH_LINE_UPS_START,
  FETCH_LINE_UPS_SUCCESS,
  RESET_LIVE_DETAILS,
  LIVE_KEEP_FETCHING,
  SHOW_ONLY_LIVE_MATCHES,
  RESET_LIVE_MODALS,
} from "./liveTypes";

export const fetchLiveMatchesStart = (payload) => ({
  type: FETCH_LIVE_MATCHES_START,
  payload,
});

export const isFetchingLiveDetails = (payload) => ({
  type: IS_FETCHING_LIVE_DETAILS,
  payload,
});

export const fetchLiveMatchesSuccess = (payload) => ({
  type: FETCH_LIVE_MATCHES_SUCCESS,
  payload,
});

export const fetchLiveMatchesFailure = (payload) => ({
  type: FETCH_LIVE_MATCHES_FAILURE,
  payload,
});

export const fetchLiveMatchesDetailsStart = () => ({
  type: FETCH_LIVE_MATCHES_DETAILS_START,
});

export const fetchLiveMatchesDate = (payload) => ({
  type: FETCH_LIVE_MATCH_DATE,
  payload,
});

export const fetchLiveMatch = (payload) => ({
  type: FETCH_LIVE_MATCH,
  payload,
});

export const openDetailsSection = (payload) => ({
  type: OPEN_DETAILS_SECTION,
  payload,
});

export const fetchLiveMatchOnHover = (payload) => ({
  type: FETCH_LIVE_MATCH_ON_HOVER,
  payload,
});

export const fetchIsMobileSidebarVisible = (payload) => ({
  type: FETCH_IS_MOBILE_SIDEBAR_VISIBLE,
  payload,
});

export const resetLive = () => ({
  type: RESET_LIVE,
});

export const fetchLiveDates = (payload) => ({
  type: FETCH_DATES,
  payload,
});

export const fetchFixturesForLiveStart = (payload) => ({
  type: FETCH_FIXTURES_FOR_LIVE_START,
  payload,
});

export const fetchFixturesForLiveSuccess = (payload) => ({
  type: FETCH_FIXTURES_FOR_LIVE_SUCCESS,
  payload,
});

export const fetchFixturesForLiveFailure = (payload) => ({
  type: FETCH_FIXTURES_FOR_LIVE_FAILURE,
  payload,
});

export const fetchLiveMatchStatistics = () => ({
  type: FETCH_LIVE_MATCH_STATISTICS,
});

export const fetchLiveMatchStatisticsSuccess = (payload) => ({
  type: FETCH_LIVE_MATCH_STATISTICS_SUCCESS,
  payload,
});

export const fetchLiveFixturesLeagues = (payload) => ({
  type: FETCH_LIVE_FIXTURES_LEAGUES,
  payload,
});

export const fetchLineUpsStart = () => ({
  type: FETCH_LINE_UPS_START,
});

export const fetchLineUpsSuccess = (payload) => ({
  type: FETCH_LINE_UPS_SUCCESS,
  payload,
});

export const resetLiveDetails = () => ({
  type: RESET_LIVE_DETAILS,
});

export const liveKeepFetching = (payload) => ({
  type: LIVE_KEEP_FETCHING,
  payload,
});

export const showOnlyLiveMatches = (payload) => ({
  type: SHOW_ONLY_LIVE_MATCHES,
  payload,
});

export const resetLiveModals = () => ({
  type: RESET_LIVE_MODALS,
});
