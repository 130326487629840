export function splitMiddle(arr) {
  const index = arr.findIndex(
    (x) => new Date(x).getDate() === new Date().getDate()
  );
  if (index === -1) {
    return arr;
  } else {
    const half = Math.floor(arr.length / 2);
    const delta = index - half;

    return [...arr.slice(delta, arr.length), ...arr.slice(0, delta)];
  }
}
