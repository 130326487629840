import axios from "axios";
import { FAM_API } from "../constants";

export const getSupporters = async (id) => {
  try {
    const res = await axios.get(`${FAM_API}/supporter/${id}`);
    if (!res.data.data.supporters) {
      throw new Error();
    }
    return res.data.data.supporters;
  } catch (error) {
    return null;
  }
};
