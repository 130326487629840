import axios from "axios";
import { FOOTBALL_API, HEADERS } from "../constants";

export const getStandings = async (league_id, season) => {
  try {
    const response = await axios.get(`${FOOTBALL_API}/standings`, {
      params: {
        season: season,
        league: `${league_id}`,
      },
      headers: HEADERS,
    });
    let res = response.data.response[0].league.standings;
    if (!res.length) {
      throw new Error();
    }
    const grouped = res.flat().reduce((a, c) => {
      if (a[c["group"]]) {
        a[c["group"]].push(c);
      } else {
        a[c["group"]] = [];
        a[c["group"]].push(c);
      }
      return a;
    }, {});
    return grouped;
  } catch (error) {
    return null;
  }
};
