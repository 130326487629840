import { createContext } from "react";

export const Context = createContext({
  isMarker: null,
  isFirstTime:null,
  setIsMarker: () => {},
  setIsFirstTime: () => {},
  isAllCountries: false,
  setIsAllCountries: () => {},
  matchesToday: {},
  setMatchesToday: () => {},
  mobileDateChange: false,
  setMobileDateChange: () => {} 
});
