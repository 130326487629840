import { FETCH_FORM_VALUES, SELECT_DAY } from "./formValuesTypes";

export const fetchFormValues = (payload) => {
  console.log("fetchFormValuesfetchFormValues", payload);
  return {
    type: FETCH_FORM_VALUES,
    payload,
  };
};

export const selectDay = (payload) => {
  return {
    type: SELECT_DAY,
    payload,
  };
};
