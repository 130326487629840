const doesLeagueMatch = (inputVal, league) => {
  const { name, country } = league;
  return (
    name
      .replace(/\s/g, "")
      .toLowerCase()
      .includes(inputVal.replace(/\s/g, "").toLowerCase()) ||
    country
      .replace(/\s/g, "")
      .toLowerCase()
      .includes(inputVal.replace(/\s/g, "").toLowerCase())
  );
};

const doesNamesMatch = (inputVal, match) => {
  const { home_team, away_team } = match;

  return (
    home_team.name
      .replace(/\s/g, "")
      .toLowerCase()
      .includes(inputVal.replace(/\s/g, "").toLowerCase()) ||
    away_team.name
      .replace(/\s/g, "")
      .toLowerCase()
      .includes(inputVal.replace(/\s/g, "").toLowerCase())
  );
};

export const searchLiveMatches = (matches, inputVal) => {
  if (inputVal) {
    let searchedList = {};
    for (let key in matches) {
      const leagueCond = matches[key].some(({ league }) =>
        doesLeagueMatch(inputVal, league)
      );

      const namesCond = matches[key].some((match) =>
        doesNamesMatch(inputVal, match)
      );
      if (leagueCond || namesCond) {
        if (namesCond) {
          searchedList[key] = matches[key].filter((x) =>
            doesNamesMatch(inputVal, x)
          );
        } else {
          searchedList[key] = matches[key];
        }
      } else {
        if (key in searchedList) {
          delete searchedList[key];
        }
      }
    }

    return searchedList;
  } else {
    return matches;
  }
};
