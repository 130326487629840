export const SET_STATE = "SET_STATE";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const FETCH_SELECTED_LEAGUE = "FETCH_SELECTED_LEAGUE";
export const CHECK_UPDATE_LEAGUES = "CHECK_UPDATE_LEAGUES";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT = "LOGOUT";
export const FETCH_WISHLIST_REQUEST = "FETCH_WISHLIST_REQUEST";
export const FETCH_WISHLIST_SUCCESS = "FETCH_WISHLIST_SUCCESS";
export const FETCH_WISHLIST_FAILURE = "FETCH_WISHLIST_FAILURE";
