import axios from "axios";
import { FAM_API, HEADERS } from "../constants";

export const getTeamSearch = async (text) => {
  try {
    const res = await axios.get(`${FAM_API}/league-teams-search?text=${text}`, {
      headers: HEADERS,
    });
    const teams = res.data.data.teams;
    return teams;
  } catch (error) {
    return null;
  }
};
