import { FETCH_MAP_DETAILS, RESET_MAP_DETAILS,FETCH_MAP_DETAILS_ON_ATTRACTION } from "./mapTypes";

const INITIAL_STATE = {
  marker: null,
  zoom: 6,
  center: null,
  internalMap: null,
  markerSelected: false,
  circle: null,
  markerSelectedOnHover: null,
  matchesToday: {},
  attractionMarkerSelectedOnHover: null,
  isMobile:null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MAP_DETAILS:
      return { ...state, ...action.payload };
    case RESET_MAP_DETAILS:
      return { ...state, marker: null, markerSelected: false, center: null,isMobile:null };
    case FETCH_MAP_DETAILS_ON_ATTRACTION:
        return { ...state, ...action.payload };
    default:
      return state;
  }
};
