import React from "react";
import StarRatings from "react-star-ratings";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./reviewCard.scss";

const ReviewCard = ({ styles }) => {
  const history = useHistory();
  return (
    <div
    onClick={()=>history.push("/review")}
      style={styles}
      // href={`https://www.footballaroundme.com/single-form/?url=${window.location.href}`}
      className="form-card"
    >
      <div className="form-card-title">We need you</div>
      <div className="form-card-subTitle">Your reviews can help others</div>
      <div className="form-card-subTitle">Their reviews will help you</div>
      <StarRatings
        rating={5}
        starDimension="15px"
        starSpacing="1px"
        numberOfStars={5}
        starEmptyColor="#2e2e2e"
        starRatedColor="#FFDA1A"
      />
    </div>
  );
};

ReviewCard.propTypes = {
  styles: PropTypes.object,
};

export default ReviewCard;
