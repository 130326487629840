// import { sortByPriority } from "./sortByPriority";

export const splitFixturesByLeagues = (
  fixList,
  leagues,
  showOnlyFav,
  onlyLiveMatches,
  favMatchesIds
) => {
  let list = fixList;
  if (fixList && fixList.length) {
    if (showOnlyFav) {
      list = fixList.filter((x) => favMatchesIds.includes(x._id));
    } else if (onlyLiveMatches) {
      list = fixList.filter((x) => "events" in x);
    } else {
      list = fixList;
    }
  }

  if (list && list.length && leagues) {
    const matches = list.sort(
      (a, b) => leagues[b.league_id]?.priority - leagues[a.league_id]?.priority
    );
    const splittedList = [];
    const leagueIds = [];
    for (let element of matches) {
      if (
        !leagueIds.includes(element.league_id) &&
        "title" in element === false
      ) {
        splittedList.push(
          ...[
            {
              title: element.league && element.league.name,
              id: element.league_id
            },
            element
          ]
        );
        leagueIds.push(element.league_id);
      } else {
        splittedList.push(element);
      }
    }
    return splittedList;
  }
};
