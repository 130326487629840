import axios from "axios";
import { HEADERS, FAM_API } from "../constants";
import { getCookie } from "../utils/cookies";
import { showError } from "../components/ToastMessage";


export const getWishlist = async (userId,cords) => {
  try {
    const authToken = getCookie("token");

    if (!authToken) {
      throw new Error("No authentication token found.");
    }
    const query = new URLSearchParams({
      location: JSON.stringify(cords)
    }).toString();
    const response = await axios.get(`${FAM_API}/wishlists/user/${userId}?${query}`, {
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data = response.data.data.wishlists;
    return data;
  } catch (error) {
    return null;
  }
};

export const addToWishlist = async (fixtureId) => {
  try {
    const authToken = getCookie("token");

    if (!authToken) {
      // showError("Please sign in to add the match to your wishlist.");
      return
      // throw new Error("No authentication token found.");
    }

    const response = await axios.post(
      `${FAM_API}/wishlist`, // Assuming the endpoint for adding to wishlist
      { fixture: fixtureId },
      {
        headers: {
          ...HEADERS,
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    showError("There is some error from server.");
    console.error("Error adding to wishlist:", error);
    return null;
  }
};

export const removeFromWishlist = async (wishlistId) => {
  try {
    const authToken = getCookie("token");

    if (!authToken) {
      throw new Error("No authentication token found.");
    }

    const response = await axios.delete(
      `${FAM_API}/wishlists/${wishlistId}`, // Assuming the endpoint for removing from wishlist
      {
        headers: {
          ...HEADERS,
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error removing from wishlist:", error);
    return null;
  }
};
