import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { createStructuredSelector } from "reselect";

import { fetchLiveMatchStatistics } from "../../redux/live/liveActions";
import "./statistics.scss";
import {
  selectLiveMatch,
  selectLiveMatchStatistics,
  selectLiveMatchesDate,
  selectIsFetchingStats,
} from "../../redux/live/liveSelectors";
import Spinner from "../Spinner";

const Statistics = ({
  fetchLiveMatchStatistics,
  isFetchingStatistics,
  liveMatch,
  statistics,
  liveDate,
}) => {
  useEffect(() => {
    let interval;
    if (
      new Date(liveDate).getDate() === new Date().getDate() &&
      "events" in liveMatch
    ) {
      interval = setInterval(() => {
        fetchLiveMatchStatistics();
      }, 60000);
    }

    if (!statistics) {
      fetchLiveMatchStatistics();
    }

    return () => {
      clearInterval(interval);
    };
  }, [liveMatch]);

  const checkCondition = (home, away, type) => {
    if (type === "greater") {
      if (home.includes("%")) return home > away;
      else return +home > +away;
    } else {
      if (home.includes("%")) return home < away;
      else return +home < +away;
    }
  };

  const renderList = () => {
    const list = [];
    for (let key in statistics) {
      const currentItem = statistics[key];
      if (currentItem.home || currentItem.away) {
        list.push(
          <div key={key} className="statistics-row">
            <span
              className={`${
                checkCondition(currentItem.home, currentItem.away, "greater") &&
                "highlighted-score"
              } team-score`}
            >
              {currentItem.home}
            </span>
            <span className="statistics-title">{key}</span>
            <span
              className={`${
                checkCondition(currentItem.home, currentItem.away, "lesser") &&
                "highlighted-score"
              } team-score`}
            >
              {currentItem.away}
            </span>
          </div>
        );
      }
    }
    return list;
  };

  return (
    <div className="statistics">
      {isFetchingStatistics ? (
        <div className="statistics-spinner">
          <Spinner />
        </div>
      ) : statistics ? (
        renderList()
      ) : (
        <div className="no-statistics-message">
          This match has no statistics for now.
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (disptach) => ({
  fetchLiveMatchStatistics: () => disptach(fetchLiveMatchStatistics()),
});

const mapStateToProps = createStructuredSelector({
  isFetchingStatistics: selectIsFetchingStats,
  liveMatch: selectLiveMatch,
  statistics: selectLiveMatchStatistics,
  liveDate: selectLiveMatchesDate,
});

Statistics.propTypes = {
  fetchLiveMatchStatistics: PropTypes.func,
  isFetchingStatistics: PropTypes.bool,
  liveMatch: PropTypes.object,
  statistics: PropTypes.object,
  liveDate: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
