import React from "react";
import "./privacyPolicy.scss";
import { Link } from "react-router-dom";
import Mixpanel from "../../utils/mixPanel";

const PrivacyPolicy = () => {
  const platform = typeof window !== "undefined" ? "web" : "mobile";
  Mixpanel.track(`Viewed privacy policy`, {
    platform: platform,
    application_type: platform === "web" ? "web app" : "mobile app",
  });
  const scrollToSection = (event) => {
    event.preventDefault(); // Prevent default link behavior
    const targetId = event.currentTarget.getAttribute("data-target"); // Get target section ID
    const targetSection = document.getElementById(targetId); // Find the target section
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" }); // Scroll to the target section
    }
  };
  return (
    <div className="privacy-container">
      <div className="top-heading">
        <h1>Privacy Notice</h1>
        <p>Last Updated</p>
      </div>

      <p style={{ marginBottom: "40px" }}>
      This app is an independent product and is not affiliated with, endorsed by, or associated with Football Associations and football teams. All trademarks and copyrights are the property of their respective owners. Use of this app is at the user’s discretion, and Football Around Me assumes no responsibility for any third-party content.
       <br></br><br></br>
        This privacy notice for ("
        <span className="bold">we</span> ," "<span className="bold">us</span>
        ," or "<span className="bold">our</span>"), describes how and why we
        might collect, store, use, and/or share ("
        <span className="bold">process</span>") your information when you use
        our services ("<span className="bold">Services </span>"), such as when
        you: <span className="bold">Questions or concerns </span> ? Reading this
        privacy notice will help you understand your privacy rights and choices.
        If you do not agree with our policies and practices, please do not use
        our Services.
      </p>
      <div className="summary-sec">
        <h2>SUMMARY OF KEY POINTS </h2>
        <p>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our
          <span className="blue"> table of contents </span> below to find the
          section you are looking for.
        </p>
        <p>
          <span className="bold">What personal information do we process?</span>
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about
          <span className="blue">personal information you disclose to us.</span>
        </p>
        <p>
          <span className="bold">
            Do we process any sensitive personal information?
          </span>
          We do not process sensitive personal information.
        </p>
        <p>
          <span className="bold">
            Do we collect any information from third parties?
          </span>
          We may collect information from public databases, marketing partners,
          social media platforms, and other outside sources. Learn more about
          <span className="blue">
            information collected from other sources.
          </span>
        </p>
        <p>
          <span className="bold"> How do we process your information? </span> We
          process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Learn more about
          <span className="blue"> how we process your information. </span>
        </p>
        <p>
          <span className="bold">
            In what situations and with which parties do we share personal
            information?
          </span>
          We may share information in specific situations and with specific
          third parties. Learn more about
          <span className="blue">
            when and with whom we share your personal information.
          </span>
        </p>
        <p>
          <span className="bold">What are your rights? </span> Depending on
          where you are located geographically, the applicable privacy law may
          mean you have certain rights regarding your personal information.
          Learn more about <span className="blue"> your privacy rights. </span>
        </p>
        <p>
          <span className="bold">How do you exercise your rights? </span> The
          easiest way to exercise your rights is by submitting a
          <span className="blue"> data subject access request </span>, or by
          contacting us. We will consider and act upon any request in accordance
          with applicable data protection laws.
        </p>
        <p>
          Want to learn more about what we do with any information we collect?
          <span className="blue"> Review the privacy notice in full.</span>
        </p>
      </div>
      <div className="table-content">
        <h2>TABLE OF CONTENTS</h2>
        <ul>
          <li>
            <Link to="#" data-target="section1" onClick={scrollToSection}>
              WHAT INFORMATION DO WE COLLECT?
            </Link>
            <Link to="#" data-target="section27" onClick={scrollToSection}>
              MISCELLANEOUS
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section2" onClick={scrollToSection}>
              HOW DO WE PROCESS YOUR INFORMATION?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section3" onClick={scrollToSection}>
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section4" onClick={scrollToSection}>
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section5" onClick={scrollToSection}>
              HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section6" onClick={scrollToSection}>
              IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section7" onClick={scrollToSection}>
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section8" onClick={scrollToSection}>
              DO WE COLLECT INFORMATION FROM MINORS?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section9" onClick={scrollToSection}>
              WHAT ARE YOUR PRIVACY RIGHTS?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section10" onClick={scrollToSection}>
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section11" onClick={scrollToSection}>
              DO WE MAKE UPDATES TO THIS NOTICE?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section12" onClick={scrollToSection}>
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </Link>
          </li>
          <li>
            <Link to="#" data-target="section13" onClick={scrollToSection}>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </Link>
          </li>
        </ul>
      </div>
      <div className="headings">
        <h2 id="section1">1. WHAT INFORMATION DO WE COLLECT?</h2>
        <h3>Personal information you disclose to us</h3>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          We collect personal information that you provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <span className="bold"> Sensitive Information. </span> We do not
          process sensitive information
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h3>Information automatically collected</h3>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          Some information - such as your Internet Protocol (IP) address and/or
          browser and device characteristics - is collected automatically when
          you visit our Services.
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
      </div>
      <div className="headings">
        <h2 id="section2">2. HOW DO WE PROCESS YOUR INFORMATION? </h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent.
        </p>
        <p className="bold">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
      </div>
      <div className="headings">
        <h2 id="section3">
          {" "}
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          We may share information in specific situations described in this
          section and/or with the following third parties.
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <span className="bold">Business Transfers.</span> We may share or
            transfer your information in connection with, or during negotiations
            of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.
          </li>
          <li>
            <span className="bold">Affiliates.</span> We may share your
            information with our affiliates, in which case we will require those
            affiliates to honor this privacy notice. Affiliates include our
            parent company and any subsidiaries, joint venture partners, or
            other companies that we control or that are under common control
            with us.
          </li>
          <li>
            <span className="bold">Business Partners.</span> We may share your
            information with our business partners to offer you certain
            products, services, or promotions.
          </li>
        </ul>
      </div>
      <div className="headings">
        <h2 id="section4">
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          We may use cookies and other tracking technologies to collect and
          store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services, prevent crashes, fix bugs, save your preferences, and assist
          with basic site functions.
        </p>
        <p>
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
        </p>
        <p>
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </p>
      </div>
      <div className="headings">
        <h2 id="section5">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          If you choose to register or log in to our Services using a social
          media account, we may have access to certain information about you.
        </p>
        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or X
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider
          concerned, but will often include your name, email address, friends
          list, and profile picture, as well as other information you choose to
          make public on such a social media platform.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third- party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
      </div>
      <div className="headings">
        <h2 id="section6">
          6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          We may transfer, store, and process your information in countries
          other than your own.
        </p>
        <p>
          Our servers are located in. If you are accessing our Services from
          outside, please be aware that your information may be transferred to,
          stored, and processed by us in our facilities and by those third
          parties with whom we may share your personal information (see
          <span className="blue">
            "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </span>
          " above), in and other countries.
        </p>
        <p>
          If you are a resident in the European Economic Area (EEA), United
          Kingdom (UK), or Switzerland, then these countries may not necessarily
          have data protection laws or other similar laws as comprehensive as
          those in your country. However, we will take all necessary measures to
          protect your personal information in accordance with this privacy
          notice and applicable law.
        </p>
      </div>
      <div className="headings">
        <h2 id="section7">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements).
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </div>
      <div className="headings">
        <h2 id="section8">8. DO WE COLLECT INFORMATION FROM MINORS?</h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
          </span>
        </p>
        <p>
          We do not knowingly collect, solicit data from, or market to children
          under 18 years of age, nor do we knowingly sell such personal
          information. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent's use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at
        </p>
      </div>
      <div className="headings">
        <h2 id="section9">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          You may review, change, or terminate your account at any time,
          depending on your country, province, or state of residence.
        </p>
        <p>
          <span className="bold" style={{ textDecoration: "underline" }}>
            Withdrawing your consent:
          </span>
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section "
          <span className="blue">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
          " below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <h3>Account Information</h3>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
      </div>
      <div className="headings">
        <h2 id="section10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do- Not-Track ("DNT") feature or setting you
          can activate to signal your privacy preference not to have data about
          your online browsing activities monitored and collected. At this
          stage, no uniform technology standard for recognizing and implementing
          DNT signals has been finalized. As such, we do not currently respond
          to DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
      </div>
      <div className="headings">
        <h2 id="section11">11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
        <p>
          <span className="bold" style={{ fontStyle: "italic" }}>
            In Short:
          </span>
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date at the top of
          this privacy notice. If we make material changes to this privacy
          notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this privacy notice frequently to be informed of how we
          are protecting your information.
        </p>
      </div>
      <div className="headings">
        <h2 id="section12">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
        <p>
          If you have questions or comments about this notice, you may contact
          us by post at:
        </p>
        <p>
          <Link to="/contact" className="blue">
            contact at us
          </Link>
        </p>
      </div>
      <div className="headings">
        <h2 id="section13">
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h2>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          details about how we have processed it, correct inaccuracies, or
          delete your personal information. You may also have the right to
          withdraw your consent to our processing of your personal information.
          These rights may be limited in some circumstances by applicable law.
          To request to review, update, or delete your personal information,
          please fill out and submit a data
          <span className="blue"> subject access request </span>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
