export const titles = [
  "0",
  "Club",
  "MD",
  "W",
  "D",
  "L",
  "GF",
  "GA",
  "GD",
  "Pts",
  "0",
];
