const getColor = (team) => {
  const { fixture, score } = team;

  if (fixture?.status?.long === "Match Postponed") {
    return "grey";
  }
  if (fixture?.status?.long === "Match Finished") {
    if (+score.fulltime.home > +score.fulltime.away) {
      return "green";
    } else if (+score.fulltime.home === +score.fulltime.away) {
      return "blue";
    } else {
      return "red";
    }
  }
};
export default getColor;
