export const getLiveMatches = (fixturesForLive, liveMatches) => {
  for (let key in liveMatches) {
    const ids = liveMatches[key].map((x) => x.fixture_id);
    const fixtures = fixturesForLive[key]
      ? fixturesForLive[key].filter((x) => !ids.includes(x.fixture_id))
      : [];
    fixturesForLive[key] = [...fixtures, ...liveMatches[key]];
  }
  return fixturesForLive;
};
