import { createSelector } from "reselect";

const selectUserLocation = (state) => state.userLocation;

export const selectUserLocationCoords = createSelector(
  [selectUserLocation],
  (userLocation) => userLocation.coordinates
);

export const selectUserLocationCountryCode = createSelector(
  [selectUserLocation],
  (userLocation) => userLocation.countryCode
);

export const selectUserLocationAddress = createSelector(
  [selectUserLocation],
  (userLocation) => userLocation.address
);
