import axios from "axios";
import { HEADERS, FOOTBALL_API } from "../constants";

export const getSummary = async (fixture_id) => {
  try {
    const response = await axios.get(`${FOOTBALL_API}/events/${fixture_id}`, {
      headers: HEADERS,
    });

    const events = response.data.api.events;
    if (!events.length) {
      throw new Error();
    }

    return events;
  } catch (error) {
    return null;
  }
};
