import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import moment from "moment";
import "./mobileSearchDetails.scss";
import {
  selectFormValuesDates,
  selectFormValuesRadius,
} from "../../redux/formValues/formValuesSelectors";
import { useSelector } from "react-redux";
import { selectIsWishlistFrom } from "../../redux/sidebar/sidebarSelectors";

const MobileSearchDetails = ({ dates, radius, isWishlistFrom }) => {
  const league = useSelector((state) => state.leagues);

  const isShowRadius = () => {
    if (league?.selectedTeams.length > 0) {
      return false;
    }

    if (league?.selectedStadiums.length > 0) {
      return false;
    }

    if (league?.selectedLeagues.length > 0) {
      return false;
    }
    return true;
  };
  return (
    <div className="mobile-search-details">
      {!isWishlistFrom && (
        <>
          <span className="search-details-bg search-details-bg-black">{`${moment(
            dates[0]
          ).format("DD/MM/YYYY")} - ${moment(dates[dates.length - 1]).format(
            "DD/MM/YYYY"
          )}`}</span>
          {isShowRadius() && (
            <span className="search-details-bg">{`${radius}Km`}</span>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  dates: selectFormValuesDates,
  radius: selectFormValuesRadius,
  isWishlistFrom: selectIsWishlistFrom,
});

MobileSearchDetails.propTypes = {
  radius: PropTypes.number,
  dates: PropTypes.array,
};

export default connect(mapStateToProps)(MobileSearchDetails);
