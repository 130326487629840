import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";

import {
  selectStandings,
  selectIsFetchingFixtureDetails,
} from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import {
  selectLiveMatch,
  selectLiveMatchesDate,
} from "../../redux/live/liveSelectors";
import { fetchStandingsStart } from "../../redux/fixtureDetails/fixtureDetailsActions";
import Standings from "../Standings";
import "./standings.scss";
import Spinner from "../Spinner";

const LiveStandings = ({
  standings,
  liveMatch,
  fetchStandingsStart,
  isFetching,
  liveDate,
}) => {
  useEffect(() => {
    let interval;
    if (!standings) {
      fetchStandingsStart();
    }
    if (
      new Date(liveDate).getDate() === new Date().getDate() &&
      "events" in liveMatch
    ) {
      interval = setInterval(() => {
        fetchStandingsStart(liveMatch);
      }, 1800000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [liveMatch]);
  return (
    <div className="standings-wrapper">
      {isFetching ? (
        <div className="live-standings-spinner">
          <Spinner />
        </div>
      ) : standings ? (
        <Standings matchDetails={false} standings={standings} />
      ) : (
        <div className="no-standings">Team standings are not available.</div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  standings: selectStandings,
  liveMatch: selectLiveMatch,
  isFetching: selectIsFetchingFixtureDetails,
  liveDate: selectLiveMatchesDate,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStandingsStart: () => dispatch(fetchStandingsStart()),
});

LiveStandings.propTypes = {
  standings: PropTypes.object,
  liveMatch: PropTypes.object,
  fetchStandingsStart: PropTypes.func,
  isFetching: PropTypes.bool,
  liveDate: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveStandings);
