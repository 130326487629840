import axios from "axios";
import { HEADERS, FOOTBALL_API } from "../constants";

export const getLineUps = async (fixture_id) => {
  try {
    const res = await axios.get(`${FOOTBALL_API}/lineups/${fixture_id}`, {
      headers: HEADERS,
    });
    const lineUps = res.data.api.lineUps;
    if (lineUps.constructor === Array) {
      throw new Error();
    }

    return lineUps;
  } catch (error) {
    return null;
  }
};
