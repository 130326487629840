import axios from "axios";
import { FAM_API, HEADERS } from "../constants";

export const getStadiumSearch = async (text) => {
  try {
    const res = await axios.get(`${FAM_API}/location-search?text=${text}`, {
      headers: HEADERS,
    });
    const stadiums = res.data.data.stadiums;
    return stadiums;
  } catch (error) {
    return null;
  }
};
